import {
  CLEAR_APP_INSTALLS,
  CLEAR_APPROVED_ENROLMENTS,
  CLEAR_BENEFICIARIES,
  CLEAR_BENEFICIARY_DETAIL,
  CLEAR_CERTIFICATES_OF_ENTITLEMENT,
  CLEAR_INVITATIONS,
  CLEAR_ENROLMENT_DETAIL,
  CLEAR_ENROLMENTS,
  LOAD_APP_INSTALLS,
  LOAD_APP_INSTALLS_FAIL,
  LOAD_APP_INSTALLS_SUCCESS,
  LOAD_APPROVED_ENROLMENTS,
  LOAD_APPROVED_ENROLMENTS_FAIL,
  LOAD_APPROVED_ENROLMENTS_SUCCESS,
  LOAD_BENEFICIARIES,
  LOAD_BENEFICIARIES_FAIL,
  LOAD_BENEFICIARIES_SUCCESS,
  LOAD_BENEFICIARY_DETAIL,
  LOAD_BENEFICIARY_DETAIL_FAIL,
  LOAD_BENEFICIARY_DETAIL_SUCCESS,
  LOAD_CERTIFICATES,
  LOAD_CERTIFICATES_FAIL,
  LOAD_CERTIFICATES_SUCCESS,
  LOAD_EXISTENCE_VALIDATIONS,
  LOAD_EXISTENCE_VALIDATIONS_FAIL,
  LOAD_EXISTENCE_VALIDATIONS_SUCCESS,
  LOAD_INVITATIONS,
  LOAD_INVITATIONS_FAIL,
  LOAD_INVITATIONS_SUCCESS,
  LOAD_ENROLMENT_DETAIL,
  LOAD_ENROLMENT_DETAIL_FAIL,
  LOAD_ENROLMENT_DETAIL_SUCCESS,
  LOAD_ENROLMENTS,
  LOAD_ENROLMENTS_FAIL,
  LOAD_ENROLMENTS_SUCCESS,
  UPDATE_BENEFICIARY,
  UPDATE_BENEFICIARY_FAIL,
  UPDATE_BENEFICIARY_SUCCESS,
  UPDATE_ENROLMENT,
  UPDATE_ENROLMENT_FAIL,
  UPDATE_ENROLMENT_SUCCESS,
  VALIDATE_DCOE,
  VALIDATE_DCOE_FAIL,
  VALIDATE_DCOE_SUCCESS,
  CREATE_INSTANT_CALL_SUCCESS,
  CREATE_INSTANT_CALL,
  CREATE_INSTANT_CALL_FAIL,
  BENEFICIARIES_FILTERS_LIST,
  LOAD_CALL_EVENTS,
  LOAD_CALL_EVENTS_SUCCESS,
  LOAD_CALL_EVENTS_FAIL
} from '../constants/ActionTypes'

export const INIT_STATE = {
  beneficiariesLoading: false,
  beneficiariesCount: 0,
  beneficiariesFiltersList: {
    search: undefined,
    organization_id: undefined,
    reported_country: undefined,
    workflow_status: undefined,
    no_enrolments_requested: undefined,
    on_hold: undefined,
    allowed_to_enroll: undefined,
    can_submit_DCE: undefined,
    opt_out: undefined,
    communication_exhausted: undefined,
    invitation_redeemed: undefined,
    has_appointment: undefined,
    valid_coe_year: undefined
  },
  beneficiaries: null,
  beneficiaryDetail: null,
  beneficiaryUpdateLoading: false,
  enrolmentsLoading: false,
  enrolmentsCount: 0,
  enrolments: null,
  enrolmentDetail: null,
  enrolmentUpdateLoading: false,
  approvedEnrolmentsLoading: false,
  approvedEnrolments: null,
  existenceValidations: null,
  existenceValidationsLoading: false,
  invitations: null,
  invitationsCount: 0,
  invitationsLoading: false,
  certificatesWithPage: null,
  certificatesLoading: false,
  certificatesPage: null,
  certificateValidationLoading: false,
  moreCertificatesRemaining: false,
  appInstalls: null,
  appInstallsLoading: false,
  appInstallsCount: 0,
  instantCallErrors: null,
  instantCallLoading: false,
  instantCall: null,
  callEvents: null,
  callEventsErrors: null,
  callEventsLoading: false
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
  case LOAD_BENEFICIARIES:
  case LOAD_BENEFICIARY_DETAIL: {
    return {
      ...state,
      beneficiariesLoading: true
    }
  }
  case LOAD_ENROLMENTS:
  case LOAD_ENROLMENT_DETAIL: {
    return {
      ...state,
      enrolmentsLoading: true
    }
  }
  case LOAD_EXISTENCE_VALIDATIONS: {
    return {
      ...state,
      existenceValidationsLoading: true
    }
  }
  case LOAD_INVITATIONS: {
    return {
      ...state,
      invitationsLoading: true
    }
  }
  case LOAD_CERTIFICATES: {
    return {
      ...state,
      certificatesLoading: true,
      certificatesPage: action.payload?.request?.params?.page
    }
  }
  case LOAD_BENEFICIARIES_SUCCESS: {
    return {
      ...state,
      beneficiariesLoading: false,
      beneficiaries: action.payload?.data?.results,
      beneficiariesCount: action.payload?.data?.count
    }
  }
  case CLEAR_BENEFICIARIES: {
    return {
      ...state,
      beneficiaries: null,
      beneficiariesCount: 0
    }
  }
  case BENEFICIARIES_FILTERS_LIST: {
    return {
      ...state,
      beneficiariesFiltersList: action.payload
    }
  }
  case LOAD_BENEFICIARY_DETAIL_SUCCESS: {
    return {
      ...state,
      beneficiariesLoading: false,
      beneficiaryDetail: action.payload?.data
    }
  }
  case CLEAR_BENEFICIARY_DETAIL: {
    return {
      ...state,
      beneficiaryDetail: null
    }
  }
  case LOAD_ENROLMENTS_SUCCESS: {
    return {
      ...state,
      enrolmentsLoading: false,
      enrolments: action.payload?.data?.results,
      enrolmentsCount: action.payload?.data?.count
    }
  }
  case CLEAR_ENROLMENTS: {
    return {
      ...state,
      enrolments: null,
      enrolmentsCount: 0
    }
  }
  case LOAD_ENROLMENT_DETAIL_SUCCESS: {
    return {
      ...state,
      enrolmentsLoading: false,
      enrolmentDetail: action.payload?.data
    }
  }
  case CLEAR_ENROLMENT_DETAIL: {
    return {
      ...state,
      enrolmentDetail: null
    }
  }
  case LOAD_EXISTENCE_VALIDATIONS_SUCCESS: {
    return {
      ...state,
      existenceValidationsLoading: false,
      existenceValidations: action.payload?.data?.results
    }
  }
  case LOAD_INVITATIONS_SUCCESS: {
    return {
      ...state,
      invitationsLoading: false,
      invitations: action.payload?.data?.results,
      invitationsCount: action.payload?.data?.count
    }
  }
  case CLEAR_INVITATIONS: {
    return {
      ...state,
      invitationsLoading: false,
      invitations: null,
      invitationsCount: 0
    }
  }
  case LOAD_CERTIFICATES_SUCCESS: {
    return {
      ...state,
      certificatesLoading: false,
      certificatesWithPage: { ...state.certificatesWithPage, ...{ [state.certificatesPage]: action.payload?.data?.results } },
      moreCertificatesRemaining: Boolean(action.payload?.data?.next)
    }
  }
  case CLEAR_CERTIFICATES_OF_ENTITLEMENT: {
    return {
      ...state,
      certificatesWithPage: null,
      certificatesPage: null,
      certificatesLoading: false,
      moreCertificatesRemaining: false
    }
  }
  case LOAD_BENEFICIARIES_FAIL:
  case LOAD_BENEFICIARY_DETAIL_FAIL: {
    return {
      ...state,
      beneficiariesLoading: false
    }
  }
  case LOAD_ENROLMENTS_FAIL:
  case LOAD_ENROLMENT_DETAIL_FAIL: {
    return {
      ...state,
      enrolmentsLoading: false
    }
  }
  case LOAD_EXISTENCE_VALIDATIONS_FAIL: {
    return {
      ...state,
      existenceValidationsLoading: false
    }
  }
  case LOAD_INVITATIONS_FAIL: {
    return {
      ...state,
      invitationsLoading: false
    }
  }
  case LOAD_CERTIFICATES_FAIL: {
    return {
      ...state,
      certificatesLoading: false
    }
  }
  case LOAD_APP_INSTALLS: {
    return {
      ...state,
      appInstallsLoading: true
    }
  }
  case LOAD_APP_INSTALLS_SUCCESS: {
    return {
      ...state,
      appInstallsLoading: false,
      appInstalls: action.payload?.data?.results,
      appInstallsCount: action.payload?.data?.count
    }
  }
  case LOAD_APP_INSTALLS_FAIL: {
    return {
      ...state,
      appInstallsLoading: false
    }
  }
  case CLEAR_APP_INSTALLS: {
    return {
      ...state,
      appInstalls: null,
      appInstallsCount: 0
    }
  }
  case UPDATE_ENROLMENT: {
    return {
      ...state,
      enrolmentUpdateLoading: true
    }
  }
  case UPDATE_ENROLMENT_SUCCESS:
  case UPDATE_ENROLMENT_FAIL: {
    return {
      ...state,
      enrolmentUpdateLoading: false
    }
  }
  case UPDATE_BENEFICIARY: {
    return {
      ...state,
      beneficiaryUpdateLoading: true
    }
  }
  case UPDATE_BENEFICIARY_SUCCESS:
  case UPDATE_BENEFICIARY_FAIL: {
    return {
      ...state,
      beneficiaryUpdateLoading: false
    }
  }
  case VALIDATE_DCOE: {
    return {
      ...state,
      certificateValidationLoading: true
    }
  }
  case VALIDATE_DCOE_SUCCESS:
  case VALIDATE_DCOE_FAIL: {
    return {
      ...state,
      certificateValidationLoading: false
    }
  }
  case LOAD_APPROVED_ENROLMENTS: {
    return {
      ...state,
      approvedEnrolmentsLoading: true
    }
  }
  case LOAD_APPROVED_ENROLMENTS_SUCCESS: {
    return {
      ...state,
      approvedEnrolmentsLoading: false,
      approvedEnrolments: action.payload?.data?.results
    }
  }
  case LOAD_APPROVED_ENROLMENTS_FAIL: {
    return {
      ...state,
      approvedEnrolmentsLoading: false,
      approvedEnrolments: null
    }
  }
  case CLEAR_APPROVED_ENROLMENTS: {
    return {
      ...state,
      approvedEnrolmentsLoading: false,
      approvedEnrolments: null
    }
  }
  case CREATE_INSTANT_CALL: {
    return {
      ...state,
      instantCallLoading: true
    }
  }
  case CREATE_INSTANT_CALL_SUCCESS: {
    return {
      ...state,
      instantCallLoading: false,
      instantCall: action.payload?.data,
      instantCallErrors: null
    }
  }
  case CREATE_INSTANT_CALL_FAIL: {
    return {
      ...state,
      instantCallLoading: false,
      instantCall: null,
      instantCallErrors: action.error?.response?.data
    }
  }
  case LOAD_CALL_EVENTS: {
    return {
      ...state,
      callEventsLoading: true
    }
  }
  case LOAD_CALL_EVENTS_SUCCESS: {
    return {
      ...state,
      callEventsLoading: false,
      callEvents: action.payload?.data?.results
    }
  }
  case LOAD_CALL_EVENTS_FAIL: {
    return {
      ...state,
      callEventsLoading: false,
      callEventsErrors: action.error?.response?.data
    }
  }
  default:
    return state
  }
}
