import { Box, makeStyles } from '@material-ui/core'
import cl from 'classnames'
import { UserDevicesList } from 'components/Beneficiary/BeneficiaryDetail/ProfileContent/UserDevicesList'
import { UserInvitationsList } from 'components/Beneficiary/BeneficiaryDetail/UserInvitationsList'
import { TabPanel } from 'components/generic/TabPanel'
import { Tabs } from 'components/generic/Tabs'
import React, { useState } from 'react'
import { CertificatesOfEntitlement } from '../CoEs'
import { ProfileEnrolments } from './ProfileEnrolments'
import { CallsHistory } from './CallsHistory'

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    height: '100%',
    padding: '10px 30px 30px'
  },
  tabPanel: {
    height: 'calc(100% - 60px)',
    overflow: 'hidden auto',
    padding: '30px 0',
    '&.hasAction': {
      height: 'calc(100% - 128px)'
    }
  }
}))

const ProfileContent = ({ beneficiary }) => {
  const classes = useStyles()

  const [tabIndex, setTabIndex] = useState(0)

  const tabs = [
    { id: 'dce-tab', label: 'DCE' },
    { id: 'app-codes-tab', label: 'App Codes' },
    { id: 'devices-tab', label: 'Devices' },
    { id: 'enrolments-tab', label: 'Enrolments' },
    { id: 'calls-tab', label: 'Calls' }
  ]

  return (
    <Box className={classes.container}>
      <Tabs
        value={tabIndex}
        onChange={(_, value) => setTabIndex(value)}
        tabs={tabs}
      />
      <TabPanel index={0} value={tabIndex} className={cl(classes.tabPanel, 'hasAction')}>
        {/* DCE */}
        <CertificatesOfEntitlement {...{ beneficiary }} />
      </TabPanel>
      <TabPanel index={1} value={tabIndex} className={cl(classes.tabPanel, 'hasAction')}>
        {/* App Codes */}
        <UserInvitationsList {...{ beneficiary }} />
      </TabPanel>
      <TabPanel index={2} value={tabIndex} className={classes.tabPanel}>
        {/* Devices */}
        <UserDevicesList {...{ beneficiary }} />
      </TabPanel>
      <TabPanel index={3} value={tabIndex} className={classes.tabPanel}>
        {/* Enrolments */}
        <ProfileEnrolments {...{ beneficiary }} />
      </TabPanel>
      <TabPanel index={4} value={tabIndex} className={classes.tabPanel}>
        {/* Calls */}
        <CallsHistory {...{ beneficiary }} />
      </TabPanel>
    </Box>
  )
}

export { ProfileContent }
