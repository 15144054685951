// Auth const
export const SIGNIN_USER = 'signin_user'
export const SIGNIN_USER_SUCCESS = 'signin_user_SUCCESS'
export const SIGNIN_USER_FAIL = 'signin_user_FAIL'
export const SIGNOUT_USER = 'signout_user'
export const SIGNOUT_USER_SUCCESS = 'signout_user_success'
export const INIT_URL = 'init_url'
export const RECOVER_PASSWORD = 'recover_password'
export const RECOVER_PASSWORD_SUCCESS = 'recover_password_SUCCESS'
export const RECOVER_PASSWORD_FAIL = 'recover_password_FAIL'
export const RECOVER_BENEFICIARY_SECURITY_CODE = 'RECOVER_BENEFICIARY_SECURITY_CODE'
export const RECOVER_BENEFICIARY_SECURITY_CODE_SUCCESS = 'RECOVER_BENEFICIARY_SECURITY_CODE_SUCCESS'
export const RECOVER_BENEFICIARY_SECURITY_CODE_FAIL = 'RECOVER_BENEFICIARY_SECURITY_CODE_FAIL'
export const SET_PASSWORD = 'set_password'
export const SET_PASSWORD_SUCCESS = 'set_password_SUCCESS'
export const SET_PASSWORD_FAIL = 'set_password_FAIL'
export const TOKEN_REFRESH = 'TOKEN_REFRESH'
export const TOKEN_REFRESH_SUCCESS = 'TOKEN_REFRESH_SUCCESS'
export const TOKEN_REFRESH_FAIL = 'TOKEN_REFRESH_FAIL'

// User const
export const LOAD_MY_USER_DATA = 'LOAD_MY_USER_DATA'
export const LOAD_MY_USER_DATA_SUCCESS = 'LOAD_MY_USER_DATA_SUCCESS'
export const LOAD_MY_USER_DATA_FAIL = 'LOAD_MY_USER_DATA_FAIL'
export const LOAD_USERS_DATA = 'LOAD_USERS_DATA'
export const LOAD_USERS_DATA_SUCCESS = 'LOAD_USERS_DATA_SUCCESS'
export const LOAD_USERS_DATA_FAIL = 'LOAD_USERS_DATA_FAIL'
export const CLEAR_USERS_DATA = 'CLEAR_USERS_DATA'
export const SET_USERS_PAGINATION = 'SET_USERS_PAGINATION'
export const CLEAR_MY_USER_DATA = 'CLEAR_MY_USER_DATA'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL'
export const SET_MY_USER_DATA = 'SET_MY_USER_DATA'
export const SET_MY_USER_DATA_SUCCESS = 'SET_MY_USER_DATA_SUCCESS'
export const SET_MY_USER_DATA_FAIL = 'SET_MY_USER_DATA_FAIL'
export const LOAD_USER_DETAIL = 'LOAD_USER_DETAIL'
export const LOAD_USER_DETAIL_SUCCESS = 'LOAD_USER_DETAIL_SUCCESS'
export const CLEAR_USER_DETAIL = 'CLEAR_USER_DETAIL'
export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL'
export const SET_USER_OPT_OUT = 'SET_USER_OPT_OUT'
export const SET_USER_OPT_OUT_SUCCESS = 'SET_USER_OPT_OUT_SUCCESS'
export const SET_USER_OPT_OUT_FAIL = 'SET_USER_OPT_OUT_FAIL'
export const USERS_FILTERS_LIST = 'USERS_FILTERS_LIST'

// BeneficiariesList
export const LOAD_BENEFICIARIES = 'LOAD_BENEFICIARIES'
export const LOAD_BENEFICIARIES_SUCCESS = 'LOAD_BENEFICIARIES_SUCCESS'
export const LOAD_BENEFICIARIES_FAIL = 'LOAD_BENEFICIARIES_FAIL'
export const CLEAR_BENEFICIARIES = 'CLEAR_BENEFICIARIES'
export const BENEFICIARIES_FILTERS_LIST = 'BENEFICIARIES_FILTERS_LIST'

// Beneficiary detail
export const LOAD_BENEFICIARY_DETAIL = 'LOAD_BENEFICIARY_DETAIL'
export const LOAD_BENEFICIARY_DETAIL_SUCCESS = 'LOAD_BENEFICIARY_DETAIL_SUCCESS'
export const LOAD_BENEFICIARY_DETAIL_FAIL = 'LOAD_BENEFICIARY_DETAIL_FAIL'
export const CLEAR_BENEFICIARY_DETAIL = 'CLEAR_BENEFICIARY_DETAIL'

export const UPDATE_BENEFICIARY = 'UPDATE_BENEFICIARY'
export const UPDATE_BENEFICIARY_SUCCESS = 'UPDATE_BENEFICIARY_SUCCESS'
export const UPDATE_BENEFICIARY_FAIL = 'UPDATE_BENEFICIARY_FAIL'

// Enrolment
export const ENROLMENT_FILTERS_LIST = 'ENROLMENT_FILTERS_LIST'
export const ENROLMENT_TOGGLE_ATTENTION = 'ENROLMENT_TOGGLE_ATTENTION'
export const ENROLMENT_TOGGLE_ASSIGNED = 'ENROLMENT_TOGGLE_ASSIGNED'

// On-boardings
export const LOAD_ENROLMENTS = 'LOAD_ENROLMENTS'
export const LOAD_ENROLMENTS_SUCCESS = 'LOAD_ENROLMENTS_SUCCESS'
export const LOAD_ENROLMENTS_FAIL = 'LOAD_ENROLMENTS_FAIL'
export const CLEAR_ENROLMENTS = 'CLEAR_ENROLMENTS'
export const LOAD_APPROVED_ENROLMENTS = 'LOAD_APPROVED_ENROLMENTS'
export const LOAD_APPROVED_ENROLMENTS_SUCCESS = 'LOAD_APPROVED_ENROLMENTS_SUCCESS'
export const LOAD_APPROVED_ENROLMENTS_FAIL = 'LOAD_APPROVED_ENROLMENTS_FAIL'
export const CLEAR_APPROVED_ENROLMENTS = 'CLEAR_APPROVED_ENROLMENTS'
export const BULK_ASSIGN_ENROLMENTS = 'BULK_ASSIGN_ENROLMENTS'
export const BULK_ASSIGN_ENROLMENTS_SUCCESS = 'BULK_ASSIGN_ENROLMENTS_SUCCESS'
export const BULK_ASSIGN_ENROLMENTS_FAIL = 'BULK_ASSIGN_ENROLMENTS_FAIL'

// On-boarding detail
export const LOAD_ENROLMENT_DETAIL = 'LOAD_ENROLMENT_DETAIL'
export const LOAD_ENROLMENT_DETAIL_SUCCESS = 'LOAD_ENROLMENT_DETAIL_SUCCESS'
export const LOAD_ENROLMENT_DETAIL_FAIL = 'LOAD_ENROLMENT_DETAIL_FAIL'
export const CLEAR_ENROLMENT_DETAIL = 'CLEAR_ENROLMENT_DETAIL'

export const APPROVE_ENROLMENT = 'APPROVE_ENROLMENT'
export const APPROVE_ENROLMENT_FAIL = 'APPROVE_ENROLMENT_FAIL'
export const APPROVE_ENROLMENT_SUCCESS = 'APPROVE_ENROLMENT_SUCCESS'
export const REJECT_ENROLMENT = 'REJECT_ENROLMENT'
export const REJECT_ENROLMENT_SUCCESS = 'REJECT_ENROLMENT_SUCCESS'
export const REJECT_ENROLMENT_FAIL = 'REJECT_ENROLMENT_FAIL'

export const UPDATE_ENROLMENT = 'UPDATE_ENROLMENT'
export const UPDATE_ENROLMENT_SUCCESS = 'UPDATE_ENROLMENT_SUCCESS'
export const UPDATE_ENROLMENT_FAIL = 'UPDATE_ENROLMENT_FAIL'

export const BOOK_APPOINTMENT = 'BOOK_APPOINTMENT'

// Existence
export const LOAD_EXISTENCE_VALIDATIONS = 'LOAD_EXISTENCE_VALIDATIONS'
export const LOAD_EXISTENCE_VALIDATIONS_SUCCESS = 'LOAD_EXISTENCE_VALIDATIONS_SUCCESS'
export const LOAD_EXISTENCE_VALIDATIONS_FAIL = 'LOAD_EXISTENCE_VALIDATIONS_FAIL'

// Invitations
export const LOAD_INVITATIONS = 'LOAD_INVITATIONS'
export const LOAD_INVITATIONS_SUCCESS = 'LOAD_INVITATIONS_SUCCESS'
export const LOAD_INVITATIONS_FAIL = 'LOAD_INVITATIONS_FAIL'
export const CREATE_INVITATION = 'CREATE_INVITATION'
export const CREATE_INVITATION_SUCCESS = 'CREATE_INVITATION_SUCCESS'
export const DELETE_INVITATION = 'DELETE_INVITATION'
export const DELETE_INVITATION_SUCCESS = 'DELETE_INVITATION_SUCCESS'
export const CLEAR_INVITATIONS = 'CLEAR_INVITATIONS'

// Digital CoEs
export const LOAD_CERTIFICATES = 'LOAD_CERTIFICATES'
export const LOAD_CERTIFICATES_SUCCESS = 'LOAD_CERTIFICATES_SUCCESS'
export const LOAD_CERTIFICATES_FAIL = 'LOAD_CERTIFICATES_FAIL'
export const CLEAR_CERTIFICATES_OF_ENTITLEMENT = 'CLEAR_CERTIFICATES_OF_ENTITLEMENT'
export const VALIDATE_DCOE = 'VALIDATE_DCOE'
export const VALIDATE_DCOE_SUCCESS = 'VALIDATE_DCOE_SUCCESS'
export const VALIDATE_DCOE_FAIL = 'VALIDATE_DCOE_FAIL'

// App installs
export const LOAD_APP_INSTALLS = 'LOAD_APP_INSTALLS'
export const LOAD_APP_INSTALLS_SUCCESS = 'LOAD_APP_INSTALLS_SUCCESS'
export const LOAD_APP_INSTALLS_FAIL = 'LOAD_APP_INSTALLS_FAIL'
export const CLEAR_APP_INSTALLS = 'CLEAR_APP_INSTALLS'
export const REVOKE_APP_INSTALL = 'REVOKE_APP_INSTALL'
export const REVOKE_APP_INSTALL_SUCCESS = 'REVOKE_APP_INSTALL_SUCCESS'

// Instant call
export const CREATE_INSTANT_CALL = 'CREATE_INSTANT_CALL'
export const CREATE_INSTANT_CALL_SUCCESS = 'CREATE_INSTANT_CALL_SUCCESS'
export const CREATE_INSTANT_CALL_FAIL = 'CREATE_INSTANT_CALL_FAIL'

// Call events
export const CALL_EVENTS_REQUEST = 'CALL_EVENTS_REQUEST'
export const LOAD_CALL_EVENTS = 'LOAD_CALL_EVENTS'
export const LOAD_CALL_EVENTS_SUCCESS = 'LOAD_CALL_EVENTS_SUCCESS'
export const LOAD_CALL_EVENTS_FAIL = 'LOAD_CALL_EVENTS_FAIL'
export const CLEAR_CALL_EVENTS = 'CLEAR_CALL_EVENTS'

// Support schedule const
export const LOAD_AVAILABILITY_SLOTS = 'LOAD_AVAILABILITY_SLOTS'
export const LOAD_AVAILABILITY_SLOTS_SUCCESS = 'LOAD_AVAILABILITY_SLOTS_SUCCESS'
export const LOAD_AVAILABILITY_SLOTS_FAIL = 'LOAD_AVAILABILITY_SLOTS_FAIL'
export const CREATE_OPERATOR_AVAILABILITY_SLOTS = 'CREATE_OPERATOR_AVAILABILITY_SLOTS'
export const CREATE_OPERATOR_AVAILABILITY_SLOTS_SUCCESS = 'CREATE_OPERATOR_AVAILABILITY_SLOTS_SUCCESS'
export const CREATE_OPERATOR_AVAILABILITY_SLOTS_FAIL = 'CREATE_OPERATOR_AVAILABILITY_SLOTS_FAIL'
export const DELETE_OPERATOR_AVAILABILITY_SLOT = 'DELETE_OPERATOR_AVAILABILITY_SLOT'
export const DELETE_OPERATOR_AVAILABILITY_SLOT_SUCCESS = 'DELETE_OPERATOR_AVAILABILITY_SLOT_SUCCESS'
export const DELETE_OPERATOR_AVAILABILITY_SLOT_FAIL = 'DELETE_OPERATOR_AVAILABILITY_SLOT_FAIL'
export const CLEAR_AVAILABILITY_SLOTS = 'CLEAR_AVAILABILITY_SLOTS'
export const LOAD_USER_VIDEO_URL = 'LOAD_USER_VIDEO_URL'
export const LOAD_USER_VIDEO_URL_SUCCESS = 'LOAD_USER_VIDEO_URL_SUCCESS'
export const LOAD_USER_VIDEO_URL_FAIL = 'LOAD_USER_VIDEO_URL_FAIL'
export const CLEAR_VIDEO_CALL_URL = 'CLEAR_VIDEO_CALL_URL'

export const LOAD_VERIFICATION_APPOINTMENTS = 'LOAD_VERIFICATION_APPOINTMENTS'
export const LOAD_VERIFICATION_APPOINTMENTS_SUCCESS = 'LOAD_VERIFICATION_APPOINTMENTS_SUCCESS'
export const LOAD_VERIFICATION_APPOINTMENTS_FAIL = 'LOAD_VERIFICATION_APPOINTMENTS_FAIL'

export const DELETE_OPERATOR_APPOINTMENT = 'DELETE_OPERATOR_APPOINTMENT'
export const DELETE_OPERATOR_APPOINTMENT_SUCCESS = 'DELETE_OPERATOR_APPOINTMENT_SUCCESS'
export const DELETE_OPERATOR_APPOINTMENT_FAIL = 'DELETE_OPERATOR_APPOINTMENT_FAIL'

export const BOOK_APPOINTMENT_SUCCESS = 'BOOK_APPOINTMENT_SUCCESS'
export const BOOK_APPOINTMENT_FAIL = 'BOOK_APPOINTMENT_FAIL'

export const UPDATE_VERIFICATION_APPOINTMENT = 'UPDATE_VERIFICATION_APPOINTMENT'
export const UPDATE_VERIFICATION_APPOINTMENT_SUCCESS = 'UPDATE_VERIFICATION_APPOINTMENT_SUCCESS'
export const UPDATE_VERIFICATION_APPOINTMENT_FAIL = 'UPDATE_VERIFICATION_APPOINTMENT_FAIL'

export const CLEAR_VERIFICATION_APPOINTMENTS = 'CLEAR_VERIFICATION_APPOINTMENTS'

// Common data const
export const LOAD_ORGANIZATIONS = 'LOAD_ORGANIZATIONS'
export const LOAD_ORGANIZATIONS_SUCCESS = 'LOAD_ORGANIZATIONS_SUCCESS'
export const LOAD_ORGANIZATIONS_FAIL = 'LOAD_ORGANIZATIONS_FAIL'
export const CLEAR_ORGANIZATIONS = 'CLEAR_ORGANIZATIONS'

export const LOAD_REPORTED_COUNTRIES = 'LOAD_REPORTED_COUNTRIES'
export const LOAD_REPORTED_COUNTRIES_SUCCESS = 'LOAD_REPORTED_COUNTRIES_SUCCESS'
export const LOAD_REPORTED_COUNTRIES_FAIL = 'LOAD_REPORTED_COUNTRIES_FAIL'

// Generic
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu'
export const DRAWER_TYPE = 'drawer_type'
export const FIXED_DRAWER = 'fixed_drawer'
export const COLLAPSED_DRAWER = 'collapsible'
export const MINI_DRAWER = 'mini_drawer'
export const THEME_COLOR = 'theme_color'
export const DARK_THEME = 'dark_theme'
export const WINDOW_WIDTH = 'window-width'
export const SWITCH_LANGUAGE = 'switch-language'
export const CHANGE_DIRECTION = 'change-direction'

export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style'
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation'
export const VERTICAL_NAVIGATION = 'vertical_navigation'

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position'
export const ABOVE_THE_HEADER = 'above_the_header'
export const INSIDE_THE_HEADER = 'inside_the_header'
export const BELOW_THE_HEADER = 'below_the_header'

// Contact Module const

export const SHOW_MESSAGE = 'show_message'
export const HIDE_MESSAGE = 'hide_message'

export const FETCH_ALL_CONTACT = 'fetch_all_contact'
export const FETCH_ALL_CONTACT_SUCCESS = 'fetch_all_contact_success'
export const ADD_FAVOURITE = 'add_favourite'
export const ON_CONTACT_SELECT = 'on_contact_select'
export const ON_ADD_CONTACT = 'on_add_contact'
export const ON_CONTACT_CLOSE = 'on_contact_close'
export const ON_FILTER_OPTION_SELECT = 'on_filter_option_select'
export const ON_SAVE_CONTACT = 'on_save_contact'
export const ON_DELETE_CONTACT = 'on_delete_contact'
export const ON_DELETE_SELECTED_CONTACT = 'on_delete_selected_contact'
export const FILTER_CONTACT = 'filter_contact'
export const GET_ALL_CONTACT = 'get_all_contact'
export const GET_UNSELECTED_ALL_CONTACT = 'get_unselected_all_contact'
export const ON_ALL_CONTACT_SELECT = 'on_all_contact_select'
export const UPDATE_SEARCH_USER = 'update_search_user'
export const ON_TOGGLE_DRAWER = 'on_toggle_drawer'
export const HANDLE_REQUEST_CLOSE = 'handle_request_close'
export const HIDE_CONTACT_LOADER = 'hide_contact_loader'

// Chat Module const

export const FETCH_ALL_CHAT_USER = 'fetch_all_chat_user'
export const FETCH_ALL_CHAT_USER_CONVERSATION = 'fetch_all_chat_user_conversation'
export const FETCH_ALL_CHAT_USER_SUCCESS = 'fetch_all_chat_user_success'
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS = 'fetch_all_chat_user_conversation_success'
export const FILTER_USERS = 'filter_users'
export const ON_SELECT_USER = 'on_select_user'
export const ON_SHOW_LOADER = 'on_show_loader'
export const ON_HIDE_LOADER = 'on_hide_loader'
export const USER_INFO_STATE = 'user_info_state'
export const SUBMIT_COMMENT = 'submit_comment'
export const UPDATE_MESSAGE_VALUE = 'update_message_value'
export const UPDATE_SEARCH_CHAT_USER = 'update_search_chat_user'

/// / Mail Module const

export const ADD_LABEL = 'ADD_LABEL'
export const GET_ALL_MAIL = 'get_all_mail'
export const FETCH_ALL_MAIL = 'fetch_all_mail'
export const FETCH_ALL_MAIL_SUCCESS = 'fetch_all_mail_success'
export const GET_IMPORTANT_MAIL = 'get_important_mail'
export const GET_NAV_FILTERS = 'get_nav_filters'
export const GET_NAV_LABELS = 'get_nav_labels'
export const GET_NAV_FOLDER = 'get_nav_folder'
export const GET_READ_MAIL = 'get_read_mail'
export const GET_STARRED_MAIL = 'get_starred_mail'
export const GET_UNIMPORTANT_MAIL = 'get_unimportant_mail'
export const GET_UNREAD_MAIL = 'get_unread_mail'
export const GET_UNSELECTED_ALL_MAIL = 'get_unselected_all_mail'
export const GET_UNSTARRED_MAIL = 'get_unstarred_mail'
export const ON_ALL_MAIL_SELECT = 'on_all_mail_select'
export const ON_FOLDER_MENU_ITEM_SELECT = 'on_folder_menu_item_select'
export const ON_FOLDER_SELECT = 'on_folder_select'
export const ON_IMPORTANT_SELECT = 'on_important_select'
export const ON_LABEL_MENU_ITEM_SELECT = 'on_label_menu_item_select'
export const GET_MARK_AS_START = 'get_mark_as_start'
export const ON_LABEL_SELECT = 'on_label_select'
export const ON_MAIL_CHECKED = 'on_mail_checked'
export const ON_MAIL_SELECT = 'on_mail_select'
export const ON_MAIL_SEND = 'on_mail_send'
export const ON_OPTION_MENU_ITEM_SELECT = 'on_option_menu_item_select'
export const ON_OPTION_MENU_SELECT = 'on_option_menu_select'
export const ON_START_SELECT = 'on_start_select'
export const SEARCH_MAIL = 'search_mail'
export const ON_DELETE_MAIL = 'on_delete_mail'
export const SET_CURRENT_MAIL_NULL = 'set_current_mail_null'
export const ON_COMPOSE_MAIL = 'on_compose_mail'

/// / TO-DO Module const

export const ON_SORTEND = 'on_sortend'
export const FETCH_ALL_TODO = 'fetch_all_todo'
export const FETCH_ALL_TODO_SUCCESS = 'fetch_all_todo-success'
export const FETCH_ALL_TODO_CONVERSATION = 'fetch_all_todo_conversation'
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS = 'fetch_all_todo_conversation_success'
export const SELECT_ALL_TODO = 'select_all_todo'
export const GET_ALL_TODO = 'get_all_todo'
export const GET_UNSELECTED_ALL_TODO = 'get_unselected_all_todo'
export const GET_STARRED_TODO = 'get_starred_todo'
export const GET_UNSTARRED_TODO = 'get_unstarred_todo'
export const GET_IMPORTANT_TODO = 'get_important_todo'
export const GET_UNIMPORTANT_TODO = 'get_unimportant_todo'
export const ON_LABEL_UPDATE = 'on_label_update'
export const ON_TODO_UPDATE = 'on_todo_update'
export const ON_DELETE_TODO = 'on_delete_todo'
export const SEARCH_TODO = 'search_todo'
export const SHOW_TODOS = 'show_todos'
export const GET_TODO_CONVERSATION = 'get_todo_conversation'
export const ON_TODO_CHECKED = 'on_todo_checked'
export const ON_TODO_ADD = 'on_todo_add'
export const ON_TODO_SELECT = 'on_todo_select'
export const SET_CURRENT_TODO_NULL = 'set_current_todo_null'
export const REMOVE_LABEL = 'remove_label'
export const UPDATE_SEARCH = 'update_search'
