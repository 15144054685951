import React, { useState } from 'react'
import UserProfileInfoProvider from './UserProfileInfoProvider'
import { Box, makeStyles } from '@material-ui/core'
import { UserInfoList } from 'components/generic/UserInfoList'
import { Tabs } from 'components/generic/Tabs'

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    padding: '0 30px'
  },
  userInfoList: {
    height: 'calc(100% - 40px)',
    overflow: 'hidden auto'
  }
}))

const ProfileInfo = ({ beneficiary, enrolmentDetail }) => {
  const classes = useStyles()

  const [tabIndex, setTabIndex] = useState(0)

  const tabs = [
    { id: 'overview-tab', label: 'Overview' },
    { id: 'benefit-tab', label: 'DCE Benefit' },
    { id: 'contact-tab', label: 'Contact' }
  ]

  const tabData = UserProfileInfoProvider({ beneficiary, enrolment: enrolmentDetail })

  return (
    <Box className={classes.container}>
      <Tabs
        value={tabIndex}
        onChange={(_, value) => setTabIndex(value)}
        tabs={tabs}
      />
      <Box className={classes.userInfoList}>
        <UserInfoList userData={tabData.filter((_, index) => index === tabIndex)[0]} />
      </Box>
    </Box>
  )
}

export default ProfileInfo
