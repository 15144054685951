import { Box, Typography } from '@material-ui/core'
import getColor from 'components/Enrolment/EnrolmentSupportStatus/getEnrolmentSupportStatusColor'
import { ENROLMENT_WORKFLOW_STATUS_DISPLAY } from 'constants/common'
import React from 'react'

const StatusDot = ({ status, statusValues = ENROLMENT_WORKFLOW_STATUS_DISPLAY, colorClass, colorStyle, showLabel, labelClass, alignItems }) => {
  const color = colorStyle || getColor(status)

  return (
    <Box display='flex' alignItems={alignItems ?? 'center'}>
      {colorClass ? (
        <i className={`zmdi zmdi-circle jr-fs-md m-0 text-${colorClass}`} />
      ) : (
        <i className={`zmdi zmdi-circle jr-fs-md m-0`} style={{ color }} />
      )}
      {showLabel && (
        <Typography className={labelClass} style={{ paddingLeft: '10px' }}>
          {statusValues[status]}
        </Typography>
      )}
    </Box>
  )
}

export default StatusDot
