export default {
  palette: {
    primary: {
      // light: blue[300],
      main: '#006EB6',
      dark: '#145986',
      contrastText: '#fff'
    },
    secondary: {
      // light: deepOrange[300],
      main: '#C62222',
      // dark: deepOrange[700],
      contrastText: '#fff'
    },
    text: {
      secondary: '#7E7E7E'
    }
  },
  status: {
    // danger: 'orange'
  },
  typography: {
    fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
    button: {
      fontWeight: 400
    }
  }
}
