import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React from 'react'
import styles from './ActionDialog.module.scss'

const ActionDialog = ({ title, content, actions, open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className={styles.dialog}
      PaperProps={{ className: styles.dialogPaper }}
    >
      {title && (
        <>
          <DialogTitle
            id='alert-dialog-title'
            disableTypography
            className={styles.dialogTitle}
          >
            <Typography className={styles.title}>{title}</Typography>
            <IconButton
              className={styles.closeButton}
              onClick={() => {
                onClose()
              }}
            >
              <Close className={styles.closeIcon} />
            </IconButton>
          </DialogTitle>
        </>
      )}
      {content && (
        <DialogContent className={styles.dialogContent}>
          {content}
        </DialogContent>
      )}
      {actions && (
        <DialogActions className={styles.dialogActions}>
          {actions}
        </DialogActions>
      )}
    </Dialog>
  )
}

export { ActionDialog }
