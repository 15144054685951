import React from 'react'
import { TextField } from '@material-ui/core'
import styles from './EnrolmentInformation.module.scss'
import PropTypes from 'prop-types'

const EnrolmentInformation = ({
  phoneValue,
  notesValue,
  setPhoneValue,
  setNoteValue,
  label
}) => {
  return (
    <>
      <div className={styles.title}>{label}</div>
      {phoneValue ? (
        <TextField
          defaultValue={phoneValue}
          label=''
          variant='outlined'
          color='secondary'
          className={styles.input}
          onChange={event => setPhoneValue(event.target.value)}
        />
      ) : (
        <TextField
          multiline
          minRows={10}
          placeholder='Not Available'
          defaultValue={notesValue}
          label=''
          variant='outlined'
          color='secondary'
          className={styles.textarea}
          onChange={event => setNoteValue(event.target.value)}
        />
      )}
    </>
  )
}

EnrolmentInformation.propTypes = {
  phoneValue: PropTypes.string,
  notesValue: PropTypes.string,
  setPhoneValue: PropTypes.func,
  setNoteValue: PropTypes.func,
  label: PropTypes.string
}

export default EnrolmentInformation
