export const ENROLMENT_STATUS = {
  PENDING: 0,
  APPROVED: 1,
  REJECTED: 2,
  WITHOUT: 3
}

export const ENROLMENT_STATUS_FOR_FILTERING = {
  PENDING: 0,
  APPROVED: 1,
  REJECTED: 2
}

export const ENROLMENT_STATUS_DISPLAY = {
  [ENROLMENT_STATUS.PENDING]: 'Pending',
  [ENROLMENT_STATUS.APPROVED]: 'Approved',
  [ENROLMENT_STATUS.REJECTED]: 'Rejected',
  [ENROLMENT_STATUS.WITHOUT]: 'No enrolment requests'
}

export const ENROLMENT_WORKFLOW_STATUS = {
  REQUIRES_ATTENTION: 10,
  AGENT_ASSIGNED: 20,
  UNDER_REVIEW: 30,
  APPROVED: 40,
  REJECTED: 50
}

export const ENROLMENT_WORKFLOW_STATUS_DISPLAY = {
  [ENROLMENT_WORKFLOW_STATUS.REQUIRES_ATTENTION]: 'Requires attention',
  [ENROLMENT_WORKFLOW_STATUS.AGENT_ASSIGNED]: 'Agent assigned',
  [ENROLMENT_WORKFLOW_STATUS.UNDER_REVIEW]: 'Under review',
  [ENROLMENT_WORKFLOW_STATUS.APPROVED]: 'Approved',
  [ENROLMENT_WORKFLOW_STATUS.REJECTED]: 'Rejected'
}

export const ENROL_REJECT_FLOW_STEP = {
  FACE_PIC_STATUS: 0,
  ID_DOC_STATUS: 1,
  OTHER_REASONS: 2
}

export const ENROL_REJECT_REASONS = {
  [ENROL_REJECT_FLOW_STEP.FACE_PIC_STATUS]: 'face',
  [ENROL_REJECT_FLOW_STEP.ID_DOC_STATUS]: 'issue_id',
  [ENROL_REJECT_FLOW_STEP.OTHER_REASONS]: 'other'
}

export const ENROL_REJECT_FACE = {
  BLURRED: 0,
  MISMATCH_IDDOC: 1,
  VALID: 2
}

export const ENROL_REJECT_ID_DOC = {
  VALID: 0,
  EXPIRED: 1,
  BLURRED: 2
}

export const ENROL_REJECT_FACE_OPTIONS = [
  { value: ENROL_REJECT_FACE.BLURRED, title: 'Blurred, light issues, objects are hiding the face' },
  { value: ENROL_REJECT_FACE.MISMATCH_IDDOC, title: 'Not matching the ID document' },
  { value: ENROL_REJECT_FACE.VALID, title: 'Valid', separator: true, labelStyle: { color: '#4DA551' } }
]

export const ENROL_REJECT_ID_DOC_OPTIONS = [
  { value: ENROL_REJECT_ID_DOC.EXPIRED, title: 'The document is expired' },
  { value: ENROL_REJECT_ID_DOC.BLURRED, title: 'The information is blurred or partially hidden' },
  { value: ENROL_REJECT_ID_DOC.VALID, title: 'Valid', separator: true, labelStyle: { color: '#4DA551' } }
]

export const ENROLMENT_REJECT_REASON_OPTIONS = {
  GENERIC: 'generic',
  ID_SCAN: 'id_scan',
  PHOTO: 'photo'
}

export const ENROLMENT_LOG_ACTIONS = {
  ONBOARDING_APPROVED: 'onboarding_approved',
  ONBOARDING_ASSIGNED: 'onboarding_assigned',
  ONBOARDING_CANCELLED: 'onboarding_cancelled',
  ONBOARDING_CREATED: 'onboarding_created',
  ONBOARDING_NOTE_ADDED: 'onboarding_note_added',
  ONBOARDING_PHONE_NUMBER_CHANGED: 'onboarding_phone_number_changed',
  APPOINTMENT_CREATED: 'appointment_created',
  APPOINTMENT_RESCHEDULED: 'appointment_rescheduled'
}

export const ENROLMENT_LOG_ACTIONS_DISPLAY = {
  [ENROLMENT_LOG_ACTIONS.ONBOARDING_APPROVED]: 'Enrolment approved',
  [ENROLMENT_LOG_ACTIONS.ONBOARDING_ASSIGNED]: 'Enrolment assigned',
  [ENROLMENT_LOG_ACTIONS.ONBOARDING_CANCELLED]: 'Enrolment cancelled',
  [ENROLMENT_LOG_ACTIONS.ONBOARDING_CREATED]: 'Enrolment created',
  [ENROLMENT_LOG_ACTIONS.ONBOARDING_NOTE_ADDED]: 'Notes changed',
  [ENROLMENT_LOG_ACTIONS.ONBOARDING_PHONE_NUMBER_CHANGED]: 'Phone number changed',
  [ENROLMENT_LOG_ACTIONS.APPOINTMENT_CREATED]: 'Appointment created',
  [ENROLMENT_LOG_ACTIONS.APPOINTMENT_RESCHEDULED]: 'Reschedule requested'
}

export const CSRF_TOKEN_HEADER = 'X-CSRFToken'
export const CSRF_TOKEN_COOKIE_NAME = 'csrftoken'

export const APP_INSTALL_STATUS = {
  PENDING: 0,
  APPROVED: 1,
  REVOKED: 2
}

export const API_MAX_PAGE_SIZE = 100

export const DATE_TIME_SHORT_FORMAT = 'MM/D/YY, h:mm a'
export const DATE_TIME_FORMAT = 'MMM D YYYY, h:mm:ss a'
export const DATE_FORMAT = 'YYYY-MM-DD'
export const TIME_FORMAT = 'HH:mm'

export const FORM_SUBMISSION_STATUS = {
  LOCAL_DATA: 0,
  SUBMITTED: 1,
  ERROR: 2,
  SUCCESS: 3,
  LOCAL_ERROR: 4
}

export const EVENT_REPETITION = {
  NONE: 0,
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 3
}

export const EVENT_REPETITION_LIST = [
  { value: EVENT_REPETITION.NONE, label: 'None' },
  { value: EVENT_REPETITION.DAILY, label: 'Daily' },
  { value: EVENT_REPETITION.WEEKLY, label: 'Weekly' },
  { value: EVENT_REPETITION.MONTHLY, label: 'Monthly' }
]

export const EVENT_REPETITION_INCREMENT = {
  [EVENT_REPETITION.NONE]: null,
  [EVENT_REPETITION.DAILY]: 'days',
  [EVENT_REPETITION.WEEKLY]: 'weeks',
  [EVENT_REPETITION.MONTHLY]: 'months'
}

export const CALENDAR_START_TIME = '6:56:00'
export const CALENDAR_SLOT_DURATION = '00:15:00'
export const CALENDAR_HEIGHT = '45rem'

export const DCE_SOLUTION_START_YEAR = 2021

export const CALENDAR_VIEWS = {
  MONTH: 'dayGridMonth',
  WEEK: 'timeGridWeek',
  DAY: 'timeGridDay',
  LIST: 'listWeek'
}

export const ID_DOCUMENT_STATUS = {
  PENDING: 0,
  APPROVED: 1,
  REJECTED: 2
}

export const CALL_EVENT_TYPE = {
  REQUEST: 'req',
  ANSWERED: 'ans',
  ENDED: 'end'
}

export const CALL_STATUS = {
  PERFORMED: 'performed',
  ATTEMPTED: 'attempted',
  INTERRUPTED: 'interrupted'
}

export const CALL_STATUS_VALUES = Object.fromEntries(
  Object.values(CALL_STATUS).map(status => {
    return [status, `Call ${status}`]
  })
)
