import {
  CLEAR_AVAILABILITY_SLOTS,
  CLEAR_VERIFICATION_APPOINTMENTS,
  CLEAR_VIDEO_CALL_URL,
  CREATE_OPERATOR_AVAILABILITY_SLOTS,
  CREATE_OPERATOR_AVAILABILITY_SLOTS_FAIL,
  CREATE_OPERATOR_AVAILABILITY_SLOTS_SUCCESS,
  DELETE_OPERATOR_APPOINTMENT,
  DELETE_OPERATOR_APPOINTMENT_FAIL,
  DELETE_OPERATOR_APPOINTMENT_SUCCESS,
  DELETE_OPERATOR_AVAILABILITY_SLOT,
  DELETE_OPERATOR_AVAILABILITY_SLOT_FAIL,
  DELETE_OPERATOR_AVAILABILITY_SLOT_SUCCESS,
  LOAD_AVAILABILITY_SLOTS,
  LOAD_AVAILABILITY_SLOTS_FAIL,
  LOAD_AVAILABILITY_SLOTS_SUCCESS,
  LOAD_USER_VIDEO_URL,
  LOAD_USER_VIDEO_URL_FAIL,
  LOAD_USER_VIDEO_URL_SUCCESS,
  LOAD_VERIFICATION_APPOINTMENTS,
  LOAD_VERIFICATION_APPOINTMENTS_FAIL,
  LOAD_VERIFICATION_APPOINTMENTS_SUCCESS
} from '../constants/ActionTypes'

const INIT_AVAILABILITY_STATE = {
  onAvailabilitySlotsRequest: false,
  availabilitySlotsRequestError: null,
  availabilitySlots: null
}

const INIT_APPOINTMENTS_STATE = {
  onAppointmentsRequest: false,
  appointmentsRequestError: null,
  appointments: null
}

const VIDEO_CALL_URL_STATE = {
  onVideoCallUrlRequest: false,
  videoCallUrlRequestError: null,
  videoCallUrl: null
}

const INIT_STATE = {
  ...INIT_AVAILABILITY_STATE,
  ...INIT_APPOINTMENTS_STATE,
  ...VIDEO_CALL_URL_STATE
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
  case LOAD_AVAILABILITY_SLOTS:
  case CREATE_OPERATOR_AVAILABILITY_SLOTS:
  case DELETE_OPERATOR_AVAILABILITY_SLOT: {
    return {
      ...state,
      onAvailabilitySlotsRequest: true
    }
  }
  case LOAD_AVAILABILITY_SLOTS_SUCCESS: {
    return {
      ...state,
      onAvailabilitySlotsRequest: false,
      availabilitySlots: action.payload?.data?.availability ?? action.payload?.data,
      availabilitySlotsRequestError: null
    }
  }
  case CREATE_OPERATOR_AVAILABILITY_SLOTS_SUCCESS:
  case DELETE_OPERATOR_AVAILABILITY_SLOT_SUCCESS: {
    return {
      ...state,
      onAvailabilitySlotsRequest: false,
      availabilitySlotsRequestError: null
    }
  }
  case LOAD_AVAILABILITY_SLOTS_FAIL:
  case DELETE_OPERATOR_AVAILABILITY_SLOT_FAIL:
  case CREATE_OPERATOR_AVAILABILITY_SLOTS_FAIL: {
    return {
      ...state,
      onAvailabilitySlotsRequest: false,
      availabilitySlotsRequestError: action.payload?.data?.error
    }
  }
  case CLEAR_AVAILABILITY_SLOTS: {
    return {
      ...state,
      ...INIT_AVAILABILITY_STATE
    }
  }
  case DELETE_OPERATOR_APPOINTMENT:
  case LOAD_VERIFICATION_APPOINTMENTS: {
    return {
      ...state,
      onAppointmentsRequest: true
    }
  }
  case LOAD_VERIFICATION_APPOINTMENTS_SUCCESS: {
    return {
      ...state,
      onAppointmentsRequest: false,
      appointments: action.payload?.data,
      appointmentsRequestError: null
    }
  }
  case DELETE_OPERATOR_APPOINTMENT_FAIL:
  case LOAD_VERIFICATION_APPOINTMENTS_FAIL: {
    return {
      ...state,
      onAppointmentsRequest: false,
      appointmentsRequestError: action.payload?.data?.error
    }
  }
  case DELETE_OPERATOR_APPOINTMENT_SUCCESS: {
    return {
      ...state,
      onAppointmentsRequest: false,
      appointmentsRequestError: null
    }
  }
  case CLEAR_VERIFICATION_APPOINTMENTS: {
    return {
      ...state,
      ...INIT_APPOINTMENTS_STATE
    }
  }
  case LOAD_USER_VIDEO_URL: {
    return {
      ...state,
      onVideoCallUrlRequest: true
    }
  }
  case LOAD_USER_VIDEO_URL_SUCCESS: {
    return {
      ...state,
      onVideoCallUrlRequest: false,
      videoCallUrl: action.payload.data?.url,
      videoCallUrlRequestError: null
    }
  }
  case LOAD_USER_VIDEO_URL_FAIL: {
    return {
      ...state,
      onVideoCallUrlRequest: false,
      videoCallUrl: null,
      videoCallUrlRequestError: action.error?.data || action.error?.toJSON().message || 'Error retrieving JWT token'
    }
  }
  case CLEAR_VIDEO_CALL_URL: {
    return {
      ...state,
      ...VIDEO_CALL_URL_STATE
    }
  }
  default:
    return state
  }
}
