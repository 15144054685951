import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { COLLAPSED_DRAWER, FIXED_DRAWER } from 'constants/ActionTypes'
import { toggleCollapsedNav } from 'actions/Setting'
import PropTypes from 'prop-types'
import { AppBar, IconButton, Toolbar } from '@material-ui/core'
import UserInfo from '../../../../components/User/UserProfileWidget'

const Header = (props) => {
  const dispatch = useDispatch()
  const { drawerType } = useSelector(({ settings }) => settings)

  const onToggleCollapsedNav = (e) => {
    const value = !props.navCollapsed
    dispatch(toggleCollapsedNav(value))
  }

  const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-block d-xl-none' : drawerType.includes(COLLAPSED_DRAWER) ? 'd-block' : 'd-none'

  return (
    <AppBar className='app-main-header' position='sticky' elevation={0}>
      <Toolbar className='app-toolbar' disableGutters={false}>
        <IconButton
          className={`jr-menu-icon mr-3 ${drawerStyle}`} aria-label='Menu'
          onClick={onToggleCollapsedNav}
        >
          <span className='menu-icon' />
        </IconButton>
        <UserInfo />
      </Toolbar>
    </AppBar>
  )
}

Header.propTypes = {
  navCollapsed: PropTypes.bool
}

export default Header
