// eslint-disable-next-line no-unused-vars

const production = {
  BACKEND_ORIGIN: 'https://digicert.unicc.org/api'
}

// eslint-disable-next-line no-unused-vars
const master_k8s = {
  BACKEND_ORIGIN: '/api'
}

// eslint-disable-next-line no-unused-vars
const qa = {
  BACKEND_ORIGIN: 'https://digicert-qa.unicc.org/api'
}

// eslint-disable-next-line no-unused-vars
const preprod = {
  BACKEND_ORIGIN: 'https://digicert-preprod.unicc.org/api'
}

// eslint-disable-next-line no-unused-vars
const development = {
  BACKEND_ORIGIN: 'https://digicert-dev.unicc.org/api'
}

// eslint-disable-next-line no-unused-vars,camelcase
const local_development = {
  BACKEND_ORIGIN: 'https://digicert-dev.unicc.org/api'
}

// eslint-disable-next-line no-unused-vars,camelcase
const full_stack_local_development = {
  BACKEND_ORIGIN: 'http://localhost:8080'
}

// eslint-disable-next-line no-unused-vars
const local = {
  BACKEND_ORIGIN: 'http://localhost:8000'
}

// eslint-disable-next-line no-unused-vars
const test = {
  BACKEND_ORIGIN: ''
}

const _default = {
  BACKEND_ORIGIN: '/api'
}

// eslint-disable-next-line no-eval
const localEnv = process.env

const environment = localEnv.REACT_APP_SERVER_ENVIRONMENT || _default

// eslint-disable-next-line no-eval
const config = eval(environment)

export default {
  ...config
}
