import {
  Box,
  Button,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core'
import { NoSimOutlined, VerifiedUserOutlined } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  loadUserCertificatesOfEntitlement,
  validateDCoE
} from '../../../../actions/Beneficiaries'
import { CLEAR_CERTIFICATES_OF_ENTITLEMENT } from '../../../../constants/ActionTypes'
import {
  certificatesOfEntitlementSelector,
  loadingCertificatesSelector,
  loadingDCoEValidation,
  userHasNoCertificatesSelector
} from '../../../../reducers/selectors'
import { debounce } from '../../../../util/debounce'
import IntlMessages from '../../../../util/IntlMessages'
import { getFullName } from '../../../../util/user'
import { CertificateOfEntitlement } from './CoEItem'
import DCoEValidationModal from './dceValidationModal'

const useStyles = makeStyles(theme => ({
  noCertContainer: {
    height: '100%',
    alignContent: 'center',
    justifyContent: 'center',
    padding: '0 170px',
    color: '#7E7E7E',
    opacity: 0.7
  },
  noCertItem: {
    display: 'flex',
    justifyContent: 'center',
    '&:not(:last-child)': {
      marginBottom: '10px'
    }
  },
  noCertIcon: {
    fontSize: '80px',
    opacity: '0.3'
  },
  noCertTitle: {
    fontWeight: 700
  },
  noCertSubTitle: {
    padding: '0 20px'
  },
  buttonContainer: {
    position: 'absolute',
    bottom: 0,
    padding: '30px 0',
    width: 'calc(100% - 60px)',
    backgroundColor: 'white'
  },
  verifyButton: {
    padding: '12px 24px',
    textTransform: 'none'
  }
}))

const CertificatesOfEntitlement = ({ beneficiary }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(0)

  // DCoEs
  const certificatesOfEntitlement = useSelector(certificatesOfEntitlementSelector)
  const loadingCertificates = useSelector(loadingCertificatesSelector)
  const userHasNoCertificates = useSelector(userHasNoCertificatesSelector)
  const [currentPage, setCurrentPage] = useState(1)

  // CoE validation
  const [showDCoEModal, setShowDCoEModal] = useState(false)
  const [showDCoEWaiting, setShowDCoEWaiting] = useState(false)
  const [dCoEResult, setDCoEResult] = useState(null)
  const validationLoading = useSelector(loadingDCoEValidation)
  const fullName = getFullName(beneficiary, true)

  // #region Clear data on leave
  const clear = () => {
    dispatch({ type: CLEAR_CERTIFICATES_OF_ENTITLEMENT })
  }

  useEffect(() => clear, [])
  // #endregion

  // #region Load CoEs
  const loadDCEs = debounce(value => {
    if (beneficiary) {
      dispatch(loadUserCertificatesOfEntitlement({ userId: beneficiary.id, page: currentPage }))
    }
    setCurrentPage(currentPage + 1)
  }, 700)

  useEffect(() => {
    if (!certificatesOfEntitlement && beneficiary) {
      loadDCEs()
    }
  }, [beneficiary])
  // #endregion

  const certificateValidation = async () => {
    setShowDCoEModal(true)
    setShowDCoEWaiting(true)
    // This implementation simulates a long call to the backend. A bit hacky
    setTimeout(async () => {
      const result = await dispatch(validateDCoE({ userId: beneficiary.id }))
      setShowDCoEWaiting(false)
      const dCoEIsValid = result.type.includes('SUCCESS') && result.payload?.data?.validated
      setDCoEResult(dCoEIsValid ? 'SUCCESS' : 'FAILURE')
    }, 2000)
  }

  const handleChange = index => (_, isExpanded) => {
    setExpanded(isExpanded ? index : false)
  }

  return (
    <Box style={{ height: '100%' }}>
      {userHasNoCertificates ? (
        // No DCE issued
        <Grid container className={classes.noCertContainer}>
          <Grid item xs={12} className={classes.noCertItem}>
            <NoSimOutlined color='inherit' className={classes.noCertIcon} />
          </Grid>
          <Grid item xs={12} className={classes.noCertItem}>
            <Typography
              variant='h5'
              color='inherit'
              className={classes.noCertTitle}
            >
              No DCE issued
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.noCertItem}>
            <Typography color='inherit' className={classes.noCertSubTitle}>
              The beneficiary hasn't issued any DCE correctly yet.
            </Typography>
          </Grid>
        </Grid>
      ) : (
        beneficiary &&
        certificatesOfEntitlement &&
        certificatesOfEntitlement.length > 0 && (
          <>
            <Box>
              {certificatesOfEntitlement?.map((certificate, index) => {
                return (
                  <CertificateOfEntitlement
                    key={certificate?.id}
                    certificate={certificate}
                    expanded={expanded === index}
                    handleChange={handleChange(index)}
                  />
                )
              })}
            </Box>
            <Box className={classes.buttonContainer}>
              <Button
                variant='outlined'
                color='primary'
                endIcon={<VerifiedUserOutlined />}
                onClick={certificateValidation}
                className={classes.verifyButton}
                disabled={loadingCertificates}
                fullWidth
                disableElevation
                disableRipple
              >
                <IntlMessages id='pages.profile.coe.verify' />
              </Button>
            </Box>
          </>
        )
      )}
      {/* DCoE Validation */}
      {showDCoEModal && (
        <DCoEValidationModal
          fullName={fullName}
          loading={showDCoEWaiting || validationLoading}
          success={dCoEResult === 'SUCCESS'}
          error={dCoEResult === 'FAILURE'}
          close={() => {
            setShowDCoEModal(false)
            setShowDCoEWaiting(false)
            setDCoEResult(null)
          }}
        />
      )}
    </Box>
  )
}

export { CertificatesOfEntitlement }
