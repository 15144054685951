import { Box, makeStyles } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { CloseOutlined } from '@material-ui/icons'
import DataTableNew from 'components/generic/DataTableNew'
import StatusDot from 'components/generic/Status/StatusDot'
import moment from 'moment'
import React, { useEffect } from 'react'
import { NotificationManager } from 'react-notifications'
import { useDispatch, useSelector } from 'react-redux'
import IntlMessages from 'util/IntlMessages'
import {
  loadUserAppInstalls,
  revokeAppInstall
} from '../../../../../actions/Beneficiaries'
import {
  CLEAR_APP_INSTALLS,
  REVOKE_APP_INSTALL_SUCCESS
} from '../../../../../constants/ActionTypes'
import { APP_INSTALL_STATUS } from '../../../../../constants/common'
import {
  appInstallsCountSelector,
  appInstallsSelector,
  loadingAppInstalls
} from '../../../../../reducers/selectors'

const DATE_FORMAT = 'DD/MM/YY'

const getColor = status => {
  if (status !== null) { return status ? 'success' : 'danger' } else return null
}

const useStyles = makeStyles(theme => ({
  cell: {
    borderBottom: 0
  },
  statusLabel: {
    fontSize: '14px'
  },
  deleteIcon: {
    color: '#7E7E7E'
  }
}))

const UserDevicesList = ({ beneficiary, defaultPagination }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const appInstalls = useSelector(appInstallsSelector)
  const loading = useSelector(loadingAppInstalls)
  const count = useSelector(appInstallsCountSelector)

  defaultPagination = defaultPagination || {
    ordering: '-created',
    size: 1000
  }

  // #region Clear data on leave
  const clear = () => {
    dispatch({ type: CLEAR_APP_INSTALLS })
  }

  useEffect(() => clear, [])
  // #endregion

  // #region Load user app installs
  const fetchData = () => {
    if (!loading && beneficiary) {
      dispatch(loadUserAppInstalls({ ...defaultPagination, userId: beneficiary?.id }))
    }
  }

  useEffect(() => fetchData(), [beneficiary])
  // #endregion

  // #region Actions
  const revoke = async (event, id) => {
    event.preventDefault()
    const result = await dispatch(revokeAppInstall({ id }))
    if (result.type === REVOKE_APP_INSTALL_SUCCESS) {
      NotificationManager.success(<IntlMessages id='pages.profile.invitations.revokeSuccess' />)
      fetchData()
    } else {
      NotificationManager.error(<IntlMessages id='appModule.errorTryAgainLater' />)
    }
  }
  // #endregion

  const columnData = [
    {
      id: 'device',
      align: 'left',
      label: 'Device'
    },
    {
      id: 'created',
      align: 'left',
      label: 'Created',
      handler: (row) => moment(row.created).format(DATE_FORMAT)
    },
    {
      id: 'version',
      align: 'left',
      label: 'Version',
      handler: (row) => row?.version ?? '-'
    },
    {
      id: 'is_valid',
      align: 'left',
      label: 'Valid',
      handler: row => (
        <StatusDot
          showLabel
          status={row.status === APP_INSTALL_STATUS.APPROVED}
          statusValues={{ true: 'Yes', false: 'No' }}
          colorClass={getColor(row.status === APP_INSTALL_STATUS.APPROVED)}
          labelClass={classes.statusLabel}
        />
      )
    }
  ]

  const actions = [
    {
      id: 'delete',
      label: '',
      handler: row => (
        <IconButton
          title='Delete'
          onClick={event => revoke(event, row.id)}
        >
          <CloseOutlined fontSize='small' className={classes.deleteIcon} />
        </IconButton>
      )
    }
  ]

  return (
    <Box>
      {!loading && beneficiary && (
        <DataTableNew
          id='devicesList'
          columnData={columnData}
          tableData={appInstalls}
          actions={actions}
          initialPagination={defaultPagination}
          noSearchResultsText={<IntlMessages id='pages.profile.appInstallations.notInstalled' />}
          count={count}
          cellClass={classes.cell}
          showOnlyTable
          hideFooter
        />
      )}
    </Box>
  )
}

export { UserDevicesList }
