import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { Box } from '@material-ui/core'
import asyncComponent from '../../util/asyncComponent'

const DocumentsLayout = (props) => {
  const match = useRouteMatch()

  return (
    <Box className='container-fluid overflow-auto'>
      <Box className='container d-flex flex-column align-items-center justify-content-start mt-5'>
        <div className='logo-lg col-6 col-md-3 col-lg-2 mb-5' title='UNJSPF'>
          <img
            id='unjspf_logo'
            src={require('assets/images/unjspfLogo.png')}
            alt='UNJSPF Digital Certificate of Entitlement'
            title='UNJSPF Digital Certificate of Entitlement'
            className='img-fluid'
          />
        </div>

        <div className='d-flex align-items-center'>
          <Switch>
            <Route
              path={`${match.url}/privacy`}
              component={asyncComponent(() => import('../../components/generic/PrivacyPolicy'))}
            />
            <Route component={asyncComponent(() => import('../../components/generic/Error/NotFound'))} />
          </Switch>
        </div>
      </Box>
    </Box>
  )
}

export default DocumentsLayout
