import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, Button } from '@material-ui/core'
import styles from './Buttons.module.scss'
import cl from 'classnames'

const Buttons = ({
  onDelete,
  onSubmit,
  toggleDrawer,
  submitTitle,
  deleteTitle,
  disableSubmit
}) => {
  return (
    <Box role='presentation' className={styles.buttonsBox}>
      <Grid container className={styles.buttonsContainer}>
        <Grid item xs={5} className={styles.buttonContainer}>
          <Button
            size='large'
            color='secondary'
            onClick={() => onDelete(true)}
            className={cl(styles.button, styles.cancelBtn)}
          >
            {deleteTitle}
          </Button>
        </Grid>
        <Grid item xs={7} className={styles.buttonContainer}>
          <Button
            size='large'
            fullWidth
            variant='contained'
            color='primary'
            onClick={() => {
              onSubmit()
              toggleDrawer(false)
            }}
            disableElevation
            className={cl(styles.button, styles.saveBtn)}
            disabled={disableSubmit}
          >
            {submitTitle}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

Buttons.propTypes = {
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func,
  toggleDrawer: PropTypes.func,
  submitTitle: PropTypes.string,
  deleteTitle: PropTypes.string
}

export default Buttons
