import { makeStyles } from '@material-ui/core'
import React from 'react'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'

const useStyles = makeStyles({
  breadcrumb: {
    backgroundColor: 'unset',
    margin: 0,
    padding: 0
  }
})

const getDisplayString = (sub) => {
  const arr = sub.split('-')
  if (arr.length > 1) {
    return arr[0].charAt(0).toUpperCase() + arr[0].slice(1) + ' ' + arr[1].charAt(0).toUpperCase() + arr[1].slice(1)
  } else {
    return sub.charAt(0).toUpperCase() + sub.slice(1)
  }
}
const getUrlString = (path, sub, index) => {
  if (index === 0) {
    return '#/'
  } else {
    return '#/' + path.split(sub)[0] + sub
  }
}

const ContainerHeader = ({ title, match }) => {
  const classes = useStyles()
  const path = match.path.substr(1)
  const subPath = path.split('/')
  return (
    <div className='d-sm-flex justify-content-sm-between align-items-sm-center my-3'>
      <h1 className='title font-weight-bold my-1'>{title}</h1>

      <Breadcrumb className='mb-0' listClassName={classes.breadcrumb} tag='nav'>
        {subPath.map((sub, index) => {
          return <BreadcrumbItem
            active={subPath.length === index + 1}
            tag={subPath.length === index + 1 ? 'span' : 'a'} key={index}
            href={getUrlString(path, sub, index)}
                 >{getDisplayString(sub)}
          </BreadcrumbItem>
        }
        )}
      </Breadcrumb>
    </div>
  )
}

export default ContainerHeader
