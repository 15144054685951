export default [
  {
    title: "Yes",
    value: true
  },
  {
    title: "No",
    value: false
  },
]
