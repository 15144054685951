let timeoutId
export const debounce = (fn, milliseconds) => {
  return wrapper
  function wrapper (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      timeoutId = null
      fn(...args)
    }, milliseconds)
  }
}
