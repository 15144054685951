import { absolutePath } from 'constants/Routes'
import React, { cloneElement } from 'react'
import { NavLink } from 'react-router-dom'
import IntlMessages from 'util/IntlMessages'

const MenuItem = ({ menuItem }) => {
  const { route, icon, title, hide, openBlank, absoluteRoute } = menuItem
  const absRoute = absoluteRoute ?? absolutePath(route)

  const renderChild = () => {
    return (
      <>
        {cloneElement(icon, {
          color: 'inherit',
          className: 'mr-2',
          fontSize: 'small'
        })}
        <span className='nav-text'>
          <IntlMessages id={title} />
        </span>
      </>
    )
  }

  return hide ? (
    <></>
  ) : (
    <li className={`menu no-arrow ${openBlank ? 'open-blank' : ''}`}>
      {openBlank ? (
        <a target='_blank' href={route} rel='noreferrer'>{renderChild()}</a>
      ) : (
        <NavLink to={absRoute}>{renderChild()}</NavLink>
      )}
    </li>
  )
}

export { MenuItem }
