import { capitalizeFirst, getGenderDisplay, getParticipationStatusDisplay } from '../../../../util'
import moment from 'moment'
import { DATE_FORMAT } from '../../../../constants/common'
import React from 'react'
import { USER_FIELDS } from 'constants/User'
import {
  AccessibleOutlined,
  AccountBoxOutlined,
  AssignmentIndOutlined,
  CalendarToday,
  ChatOutlined,
  CommentOutlined,
  CreditCardOutlined,
  DateRangeOutlined,
  DescriptionOutlined,
  DesktopWindowsOutlined,
  DraftsOutlined,
  EmailOutlined,
  ExitToAppOutlined,
  LanguageOutlined,
  LocationOnOutlined,
  NotificationsOffOutlined,
  PersonAddOutlined,
  PersonOutlineOutlined,
  PhoneIphoneOutlined,
  PhonelinkRingOutlined,
  PhoneOutlined,
  SmsOutlined,
  WorkOutlineOutlined
} from '@material-ui/icons'
import StatusDot from 'components/generic/Status/StatusDot'
import NotAvailable from 'components/generic/NotAvailable'
import { getOrganizationFullTitle } from 'util/user'

const getColor = status => {
  if (status !== null) { return status ? 'success' : 'danger' } else return null
}

export default ({ beneficiary, enrolment }) => {
  const infoTabData = [
    {
      id: USER_FIELDS.UNJSPF_ID,
      title: 'pages.profile.UUID',
      icon: <AccountBoxOutlined />,
      description: beneficiary?.unjspf_uuid
    },
    {
      id: USER_FIELDS.ORGANIZATION,
      title: 'pages.profile.workedAt',
      icon: <WorkOutlineOutlined />,
      description: getOrganizationFullTitle(beneficiary?.organization)
    },
    {
      id: USER_FIELDS.DATE_OF_BIRTH,
      title: 'pages.profile.dateOfBirth',
      icon: <DateRangeOutlined />,
      description: beneficiary?.date_of_birth && moment(beneficiary?.date_of_birth).format(DATE_FORMAT)
    },
    {
      id: USER_FIELDS.DATE_JOINED,
      title: 'pages.profile.dateJoined',
      icon: <CalendarToday />,
      description: beneficiary?.date_joined && moment(beneficiary?.date_joined).format(DATE_FORMAT)
    },
    {
      id: USER_FIELDS.GENDER,
      title: 'pages.profile.gender',
      icon: <PersonOutlineOutlined />,
      description: getGenderDisplay(beneficiary?.gender)
    },
    {
      id: USER_FIELDS.INVITATION_REDEEMED,
      title: 'pages.profile.invitation',
      icon: <PhonelinkRingOutlined />,
      component: (
        <StatusDot
          showLabel
          status={beneficiary?.invitation_redeemed}
          statusValues={{ true: 'Redeemed', false: 'Not redeemed' }}
          colorClass={getColor(beneficiary?.invitation_redeemed)}
        />
      )
    }
  ]

  const DCEParticipationData = [
    {
      id: USER_FIELDS.ENABLED_ENROLL,
      title: 'pages.profile.enroll',
      icon: <PersonAddOutlined />,
      component: (
        <StatusDot
          showLabel
          status={beneficiary?.allowed_to_enroll}
          statusValues={{ true: 'Allowed', false: 'Not allowed' }}
          colorClass={getColor(beneficiary?.allowed_to_enroll)}
        />
      )
    },
    {
      id: USER_FIELDS.ENABLED_DCE,
      title: 'pages.profile.dce',
      icon: <DescriptionOutlined />,
      component: (
        <StatusDot
          showLabel
          status={beneficiary?.dce}
          statusValues={{ true: 'Enabled', false: 'Not enabled' }}
          colorClass={getColor(beneficiary?.dce)}
        />
      )
    },
    {
      id: USER_FIELDS.MSS,
      title: 'pages.profile.mss',
      icon: <DesktopWindowsOutlined />,
      component: (
        <StatusDot
          showLabel
          status={beneficiary?.mss}
          statusValues={{ true: 'Yes', false: 'No' }}
          colorClass={getColor(beneficiary?.mss)}
        />
      )
    },
    {
      id: USER_FIELDS.DECEASED,
      title: 'pages.profile.deceased',
      icon: <AccountBoxOutlined />,
      component: (
        <StatusDot
          showLabel
          status={beneficiary?.deceased}
          statusValues={{ true: 'Yes', false: 'No' }}
          colorClass={getColor(beneficiary?.deceased)}
        />
      )
    },
    {
      id: USER_FIELDS.DISABILITY,
      title: 'pages.profile.disability',
      icon: <AccessibleOutlined />,
      component: (
        <StatusDot
          showLabel
          status={beneficiary?.disability}
          statusValues={{ true: 'Yes', false: 'No' }}
          colorClass={getColor(beneficiary?.disability)}
        />
      )
    },
    {
      id: USER_FIELDS.PARTICIPATION_STATUS,
      title: 'pages.profile.participation.status',
      icon: <AssignmentIndOutlined />,
      description: getParticipationStatusDisplay(beneficiary?.participation_status) || <NotAvailable />
    },
    {
      id: USER_FIELDS.REASON,
      title: 'pages.profile.participation.reason',
      icon: <CommentOutlined />,
      description: beneficiary?.reason || <NotAvailable />
    },
    {
      id: USER_FIELDS.OPT_OUT,
      title: 'pages.profile.optedOut.check',
      icon: <ExitToAppOutlined />,
      component: (
        <StatusDot
          showLabel
          status={!!beneficiary?.opt_out}
          statusValues={{ true: 'Yes', false: 'No' }}
          colorClass={getColor(!!beneficiary?.opt_out)}
        />
      )
    },
    {
      id: USER_FIELDS.OPT_OUT_DATE,
      title: 'pages.profile.optedOut.date',
      icon: <CalendarToday />,
      description: beneficiary?.opt_out ? moment(beneficiary.opt_out).format(DATE_FORMAT) : <NotAvailable />
    },
    {
      id: USER_FIELDS.OPT_OUT_NOTE,
      title: 'pages.profile.optedOut.note',
      icon: <ChatOutlined />,
      description: beneficiary?.opt_out_note || <NotAvailable />
    },
    {
      id: USER_FIELDS.RECORD_ID,
      title: 'pages.profile.recordId',
      icon: <CreditCardOutlined />,
      description: beneficiary?.record_id || <NotAvailable />
    },
    {
      id: USER_FIELDS.HAS_MODEL,
      title: 'pages.profile.hasModel',
      icon: <PhoneIphoneOutlined />,
      component: (
        <StatusDot
          showLabel
          status={beneficiary?.profile?.has_model}
          statusValues={{ true: 'Yes', false: 'No' }}
          colorClass={getColor(beneficiary?.profile?.has_model)}
        />
      )
    }
  ]

  if (beneficiary?.guardian_uid) {
    DCEParticipationData.push({
      id: USER_FIELDS.GUARDIAN_UID,
      title: 'pages.profile.guardian.uid',
      icon: <AccountBoxOutlined />,
      description: capitalizeFirst(beneficiary?.guardian_uid)
    })
  }

  if (beneficiary?.guardian_sort_name) {
    DCEParticipationData.push({
      id: USER_FIELDS.GUARDIAN_SORT_NAME,
      title: 'pages.profile.guardian.name',
      icon: <AccountBoxOutlined />,
      description: capitalizeFirst(beneficiary?.guardian_sort_name)
    })
  }

  const contactData = [
    {
      id: USER_FIELDS.EMAIL,
      title: 'pages.profile.email',
      icon: <EmailOutlined />,
      description: beneficiary?.email
    },
    {
      id: USER_FIELDS.LEGACY_EMAIL,
      title: 'pages.profile.email.ipas',
      icon: <DraftsOutlined />,
      description: beneficiary?.legacy_email || <NotAvailable />
    },
    {
      id: USER_FIELDS.PHONE_NUMBER,
      title: 'pages.profile.phoneNumber',
      icon: <PhoneOutlined />,
      description: beneficiary?.phone_number || enrolment?.phone_number
    },
    {
      id: USER_FIELDS.LANGUAGE_CODE,
      title: 'pages.profile.languageCode',
      icon: <SmsOutlined />,
      description: beneficiary?.profile?.language.toLocaleUpperCase()
    },
    {
      id: USER_FIELDS.TIMEZONE,
      title: 'pages.profile.timezone',
      icon: <LanguageOutlined />,
      description: capitalizeFirst(beneficiary?.profile?.timezone)
    },
    {
      id: USER_FIELDS.REPORTED_COUNTRY,
      title: 'pages.profile.reportedCountry',
      icon: <LocationOnOutlined />,
      description: capitalizeFirst(beneficiary?.reported_country) || <NotAvailable />
    },
    {
      id: USER_FIELDS.COMMUNICATION_EXHAUSTED,
      title: 'pages.profile.communicationExhausted.check',
      icon: <NotificationsOffOutlined />,
      component: (
        <StatusDot
          showLabel
          status={beneficiary?.communication_exhausted}
          statusValues={{ true: 'Yes', false: 'No' }}
          colorClass={getColor(beneficiary?.communication_exhausted)}
        />
      )
    },
    {
      id: USER_FIELDS.COMMUNICATION_EXHAUSTED_DATE,
      title: 'pages.profile.communicationExhausted.date',
      icon: <CalendarToday />,
      description: beneficiary?.communication_exhausted_date ? moment(beneficiary.communication_exhausted_date).format(DATE_FORMAT) : <NotAvailable />
    },
    {
      id: USER_FIELDS.COMMUNICATION_EXHAUSTED_NOTE,
      title: 'pages.profile.communicationExhausted.note',
      icon: <CommentOutlined />,
      description: beneficiary?.communication_exhausted_note || <NotAvailable />
    }
  ]

  return [infoTabData, DCEParticipationData, contactData]
}
