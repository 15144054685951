import { ENROLMENT_WORKFLOW_STATUS } from '../../../constants/common'

const getColor = (status) => {
  let result = null
  switch (status) {
    case ENROLMENT_WORKFLOW_STATUS.REQUIRES_ATTENTION: {
      result = '#E3A630'
      break
    }
    case ENROLMENT_WORKFLOW_STATUS.AGENT_ASSIGNED: {
      result = '#BD50CF'
      break
    }
    case ENROLMENT_WORKFLOW_STATUS.UNDER_REVIEW: {
      result = '#006EB6'
      break
    }
    case ENROLMENT_WORKFLOW_STATUS.APPROVED: {
      result = '#4DA551'
      break
    }
    case ENROLMENT_WORKFLOW_STATUS.REJECTED: {
      result = '#C62222'
      break
    }
    default: {
      result = '#000'
    }
  }
  return result
}

export default getColor
