import getColor from 'components/Enrolment/EnrolmentSupportStatus/getEnrolmentSupportStatusColor'
import { ENROLMENT_WORKFLOW_STATUS_DISPLAY } from 'constants/common'
import React from 'react'

const StatusPill = ({ status, statusValues = ENROLMENT_WORKFLOW_STATUS_DISPLAY, colorClass }) => {
  return colorClass ? (
    <span className={`badge m-0 badge-${colorClass}`}>
      {statusValues[status]}
    </span>
  ) : (
    <span
      className='badge m-0 text-white'
      style={{ backgroundColor: getColor(status) }}
    >
      {statusValues[status]}
    </span>
  )
}

export default StatusPill
