import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  makeStyles,
  Grid
} from '@material-ui/core'
import { SwitchButton } from 'components/generic/SwitchButton'
import { SearchSelect } from 'components/generic/SearchSelect'
import { RadioButton } from 'components/generic/RadioButton'

const useStyles = makeStyles(theme => ({
  filterTitle: {
    marginTop: '1.5rem',
    marginBottom: '0.5rem',
    fontWeight: '590'
  },
  listContainer: {
    marginBottom: '1rem'
  },
  optionLabel: {
    display: 'flex',
    alignItems: 'center'
  },
  optionRendered: {
    marginRight: '0.5rem'
  }
}))

const Filters = ({ filters, checkedList, handleChange }) => {
  const classes = useStyles()

  const isChecked = (value, filterId) => {
    const checkedValue = checkedList[filterId]

    if (typeof checkedValue === 'object') {
      if (checkedValue.length) return checkedValue.includes(String(value))
      else if (checkedValue) return JSON.stringify(checkedValue) === JSON.stringify(value)
      else return false
    } else return checkedValue === String(value)
  }

  const renderFilter = filter => {
    const { id, title, options, valueIsObject } = filter
    if (filter.isSwitch) {
      return (
        <SwitchButton
          bigLabel
          labelTranslated={title}
          checked={isChecked(true, id)}
          onChange={event => handleChange(filter, String(event.target.checked))}
        />
      )
    } else if (filter.isRadioButton) {
      return (
        <>
          <Typography className={classes.filterTitle}>
            {title}
          </Typography>
          <RadioButton
            value={checkedList[id]}
            options={options}
            valueIsObject={valueIsObject}
            onChange={(value, selected) => handleChange(filter, value, selected)}
          />
        </>
      )
    } else if (filter.isAutocomplete) {
      return (
        <SearchSelect
          labelTranslated={title}
          value={checkedList[id] ?? []}
          options={options}
          onChange={value => handleChange(filter, value)}
          labelClass={classes.filterTitle}
        />
      )
    } else {
      // Checkbox
      return (
        <>
          <Typography className={classes.filterTitle}>
            {filter.title}
          </Typography>
          <Box className={classes.listContainer}>
            {filter.options?.length > 0 &&
              filter.options.map((option, index) => {
                const value = filter.valueIsObject ? JSON.stringify(option.value) : option.value
                return <FormGroup row key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={value}
                        checked={isChecked(option.value, filter.id)}
                        onChange={event => handleChange(filter, event.target.value, event.target.checked)}
                        color='primary'
                      />
                    }
                    label={
                      <Box className={classes.optionLabel}>
                        {option.rendered && (
                          <Box className={classes.optionRendered}>
                            {option.rendered}
                          </Box>
                        )}
                        <>{option.title}</>
                      </Box>
                    }
                  />
                </FormGroup>
              })}
          </Box>
        </>
      )
    }
  }

  return (
    <Grid container spacing={1}>
      {filters?.length > 0 &&
        filters.map(filter => (
          <Grid key={filter.id} item xs={12}>{renderFilter(filter)}</Grid>
        ))}
    </Grid>
  )
}

Filters.propTypes = {
  filters: PropTypes.array,
  handleChange: PropTypes.func,
  checkedList: PropTypes.object
}

export default Filters
