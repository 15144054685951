import React from 'react'
import Routes from '../../app/routes'
import Vertical from './Vertical'
import { NotificationContainer } from 'react-notifications'

const AppLayout = () => {
  return (
    <Vertical>
      <Routes />
      <NotificationContainer />
    </Vertical>
  )
}

export default AppLayout
