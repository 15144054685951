import React, { useEffect } from 'react'
import { createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { IntlProvider } from 'react-intl'
import moment from 'moment'
import 'assets/vendors/style'
import indigoTheme from './themes/indigoTheme'
import cyanTheme from './themes/cyanTheme'
import orangeTheme from './themes/orangeTheme'
import amberTheme from './themes/amberTheme'
import pinkTheme from './themes/pinkTheme'
import blueTheme from './themes/blueTheme'
import purpleTheme from './themes/purpleTheme'
import greenTheme from './themes/greenTheme'
import AppLocale from '../lngProvider'
import {
  AMBER,
  BLUE,
  CYAN,
  DARK_AMBER,
  DARK_BLUE,
  DARK_CYAN,
  DARK_DEEP_ORANGE,
  DARK_DEEP_PURPLE,
  DARK_GREEN,
  DARK_INDIGO,
  DARK_PINK,
  DEEP_ORANGE,
  DEEP_PURPLE,
  GREEN,
  INDIGO,
  PINK,
  UNJSPF
} from 'constants/ThemeColors'
import { setInitUrl } from '../actions/Auth'
import RTL from 'util/RTL'
import AppLayout from './AppLayout'
import { LIGHT_BLUE } from '../constants/ThemeColors'
import lightBlueTheme from './themes/lightBlueTheme'
import PropTypes from 'prop-types'
import AuthLayout from './AuthLayout'
import GenericLayout from './GenericLayout'
import {
  initURLSelector,
  isDirectionRTLSelector,
  localeSelector,
  themeColorSelector,
  userTokenSelector
} from '../reducers/selectors'
import DocumentsLayout from './DocumentsLayout'
import unjspfTheme from './themes/unjspfTheme'
import { ROUTES } from 'constants/Routes'
import Maintenance from 'components/generic/Error/Maintenance'

const RestrictedRoute = ({ component: Component, userToken, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      userToken ? <Component {...props} />
        : (<Redirect to={{ pathname: '/auth/signin', state: { from: props.location } }} />)}
  />

RestrictedRoute.propTypes = {
  component: PropTypes.func,
  userToken: PropTypes.string,
  location: PropTypes.object
}

const App = (props) => {
  const dispatch = useDispatch()
  const themeColor = useSelector(themeColorSelector)
  const locale = useSelector(localeSelector)
  const isDirectionRTL = useSelector(isDirectionRTLSelector)
  const userToken = useSelector(userTokenSelector, shallowEqual)
  const initURL = useSelector(initURLSelector, shallowEqual)
  const match = useRouteMatch()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true
    if (initURL === '') {
      dispatch(setInitUrl(history.location.pathname))
    }
  }, [dispatch, initURL, history.location.pathname, location.search])

  const getColorTheme = (themeColor, applyTheme) => {
    switch (themeColor) {
      case INDIGO: {
        applyTheme = createTheme(indigoTheme)
        break
      }
      case CYAN: {
        applyTheme = createTheme(cyanTheme)
        break
      }
      case AMBER: {
        applyTheme = createTheme(amberTheme)
        break
      }
      case DEEP_ORANGE: {
        applyTheme = createTheme(orangeTheme)
        break
      }
      case PINK: {
        applyTheme = createTheme(pinkTheme)
        break
      }
      case LIGHT_BLUE: {
        applyTheme = createTheme(lightBlueTheme)
        break
      }
      case BLUE: {
        applyTheme = createTheme(blueTheme)
        break
      }
      case DEEP_PURPLE: {
        applyTheme = createTheme(purpleTheme)
        break
      }
      case GREEN: {
        applyTheme = createTheme(greenTheme)
        break
      }
      case DARK_INDIGO: {
        applyTheme = createTheme({ ...indigoTheme, direction: 'rtl' })
        break
      }
      case DARK_CYAN: {
        applyTheme = createTheme(cyanTheme)
        break
      }
      case DARK_AMBER: {
        applyTheme = createTheme(amberTheme)
        break
      }
      case DARK_DEEP_ORANGE: {
        applyTheme = createTheme(orangeTheme)
        break
      }
      case DARK_PINK: {
        applyTheme = createTheme(pinkTheme)
        break
      }
      case DARK_BLUE: {
        applyTheme = createTheme(blueTheme)
        break
      }
      case DARK_DEEP_PURPLE: {
        applyTheme = createTheme(purpleTheme)
        break
      }
      case DARK_GREEN: {
        applyTheme = createTheme(greenTheme)
        break
      }
      case UNJSPF: {
        applyTheme = createTheme(unjspfTheme)
        break
      }
      default :
        createTheme(indigoTheme)
    }
    return applyTheme
  }

  let applyTheme = createTheme(indigoTheme)
  applyTheme = getColorTheme(themeColor, applyTheme)

  if (location.pathname === '/') {
    if (userToken === null) {
      return (<Redirect to='/auth/signin' />)
    } else if (initURL === '' || initURL === '/' || initURL === '/auth/signin') {
      return (<Redirect to='/app/main' />)
    } else {
      return (<Redirect to={initURL} />)
    }
  }
  if (isDirectionRTL) {
    applyTheme.direction = 'rtl'
    document.body.classList.add('rtl')
  } else {
    document.body.classList.remove('rtl')
    applyTheme.direction = 'ltr'
  }

  const currentAppLocale = AppLocale[locale.locale]
  return (
    <ThemeProvider theme={applyTheme}>
      <MuiPickersUtilsProvider
        libInstance={moment}
        utils={MomentUtils}
        locale={currentAppLocale.locale}
      >
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          {/* eslint-disable-next-line react/jsx-pascal-case */}
          <RTL>
            <div className='app-main'>
              <Switch>
                <RestrictedRoute
                  path={`${match.url}app`}
                  userToken={userToken}
                  component={AppLayout}
                />
                <Route
                  path={`${match.url}auth`}
                  component={AuthLayout}
                />
                <Route
                  path={`${match.url}doc`}
                  component={DocumentsLayout}
                />
                <Route
                  path={`${match.url}${ROUTES.MAINTENANCE}`}
                  component={Maintenance}
                />
                <Route component={GenericLayout} />
              </Switch>
            </div>
          </RTL>
        </IntlProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

export default App
