import React, { useEffect } from 'react'
import { Redirect, Route, Switch, useRouteMatch, useHistory } from 'react-router-dom'
import { Box } from '@material-ui/core'
import IntlMessages from 'util/IntlMessages'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { useDispatch, useSelector, useStore } from 'react-redux'
import asyncComponent from '../../util/asyncComponent'
import { absolutePath, ROUTES } from '../../constants/Routes'
import { hideMessage } from '../../actions/Auth'

const AuthLayout = () => {
  const { alertMessage, showMessage } = useSelector(({ auth }) => auth)
  const match = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()
  const store = useStore()
  const token = store.getState().auth.userToken

  useEffect(() => {
    if (token !== null) {
      history.push(absolutePath(ROUTES.MAIN))
    }
    if (showMessage) {
      if (alertMessage) {
        NotificationManager.error(<IntlMessages id={alertMessage} />)
        dispatch(hideMessage())
      }
    }
  }, [showMessage, token, history, dispatch])

  return (
    <Box
      bgcolor='primary.dark'
      className='app-login-container d-flex justify-content-center align-items-center'
    >
      <div className='app-login-main-content'>

        <div className='app-logo-content d-flex align-items-center justify-content-center'>
          <Box className='logo-lg w-75 justify-content-center align-items-center d-flex flex-column' to='/' title='UNJSPF'>
            <img
              id='unjspf_logo'
              src={require('assets/images/unjspfLogo.png')}
              alt='UNJSPF Digital Certificate of Entitlement'
              title='UNJSPF Digital Certificate of Entitlement'
              className='img-fluid mb-3'
            />
            <h3 className='text-center text-dark'><IntlMessages id='appModule.dCE' /></h3>
            <span className='text-light-grey text-center small'>{`v${process.env.REACT_APP_VERSION}`}</span>
          </Box>
        </div>

        <div className='app-login-content animated slideInUpTiny animation-duration-3'>
          <Switch>
            <Route
              path={`${match.url}/signin`}
              component={asyncComponent(() => import('../../components/Authentication/SignIn'))}
            />
            <Route
              path={`${match.url}/recoverpassword`}
              component={asyncComponent(() => import('../../components/Authentication/RecoverPassword'))}
            />
            <Route
              path={`${match.url}/setpassword/:code`}
              component={asyncComponent(() => import('../../components/Authentication/SetPassword'))}
            />
            <Route
              path={`${match.url}/success`}
              component={asyncComponent(() => import('../../components/Authentication/Success'))}
            />
            <Route
              path={`${match.url}/set-security-code/:code`}
              component={asyncComponent(() => import('../../components/Authentication/SetBeneficiarySecurityCode/index'))}
            />
            <Redirect to='/' />
          </Switch>
        </div>

      </div>
      <NotificationContainer />
    </Box>
  )
}

export default AuthLayout
