import { createSelector } from 'reselect'
import {
  getColorFromName,
  getDarkerColorFromName,
  getFullName,
  getLightColorFromName,
  getInitialsName,
  getReducedName,
  isUserBeneficiary,
  userBelongsToSupportGroup,
  userIsHelpDeskManager,
  userIsAgent,
  userIsAdmin,
  getCallsHistory,
  userIsReadOnly
} from '../util/user'
import moment from 'moment'

// User
export const getUser = (state) => state.user
export const userDataSelector = createSelector(
  getUser,
  (user) => user.data
)

export const userLoadingSelector = createSelector(
  getUser,
  (user) => user.loading
)

export const userErrors = createSelector(
  getUser,
  (user) => user.errors
)

export const userIdSelector = createSelector(
  userDataSelector,
  (userData) => userData.id
)

export const userNameSelector = createSelector(
  userDataSelector,
  (userData) => userData.username
)

export const currentUserBelongsToSupportGroupSelector = createSelector(
  userDataSelector,
  (userData) => userBelongsToSupportGroup(userData)
)

export const currentUserIsAdminSelector = createSelector(
  userDataSelector,
  (userData) => userIsAdmin(userData)
)

// Users
export const getUsers = (state) => state.users
export const usersListSelector = createSelector(
  getUsers,
  (users) => users.list
)

export const usersDetailSelector = createSelector(
  getUsers, (users) => users.detail
)

export const usersCountSelector = createSelector(
  getUsers,
  (users) => users.count
)

export const usersLoadingSelector = createSelector(
  getUsers,
  (users) => users.loading
)

export const usersFiltersListSelector = createSelector(
  getUsers,
  (users) => {
    return users.usersFiltersList
  }
)

export const userIsBeneficiarySelector = createSelector(
  usersDetailSelector,
  (userData) => isUserBeneficiary(userData)
)

export const currentUserIsHelpDeskManagerSelector = createSelector(
  userDataSelector,
  (userData) => userIsHelpDeskManager(userData)
)

export const currentUserIsAgentSelector = createSelector(
  userDataSelector,
  (userData) => userIsAgent(userData)
)

export const currentUserIsReadOnlySelector = createSelector(
  userDataSelector,
  (userData) => userIsReadOnly(userData)
)

export const getOperatorsFromUsers = createSelector(
  usersListSelector,
  (usersList) => {
    return usersList?.filter(userBelongsToSupportGroup)
  }
)

export const getAllUsers = createSelector(
    usersListSelector,
    (usersList) => {
        return usersList
    }
)

export const operatorsListForSelectSelector = createSelector(
  [getOperatorsFromUsers, currentUserIsHelpDeskManagerSelector, currentUserIsAgentSelector, userDataSelector],
  (users, userIsHelpDeskManager, userIsAgent, user) => {
    let result = [getUserInfo(user)]

    if (userIsHelpDeskManager || userIsAgent) {
      result = users?.map((user) => (
        getUserInfo(user)
      ))?.sort((prev, next) => prev.fullName.localeCompare(next.fullName))
    }
    return result
  }
)

export const getUserInfo = user => {
  return user
    ? {
        id: user.id,
        fullName: getFullName(user),
        timezone: user.profile?.timezone || 'N/D'
      }
    : null
}

// Auth
export const getAuth = (state) => state.auth

export const userTokenSelector = createSelector(
  getAuth,
  (auth) => auth?.userToken
)

export const initURLSelector = createSelector(
  getAuth,
  (auth) => auth?.initURL
)

export const userAuthSelector = createSelector(
  getAuth,
  (auth) => ({
    userName: auth.userName,
    token: auth.userToken,
    refreshToken: auth.refreshToken
  })
)

export const authSuccessMessageSelector = createSelector(
  getAuth, auth => auth?.successMessage
)

export const authAlertMessageSelector = createSelector(
  getAuth, auth => auth?.alertMessage
)

// BeneficiariesList
export const getBeneficiariesData = (state) => state.beneficiaries
export const beneficiariesSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => beneficiaries.beneficiaries
)
export const beneficiaryDetailSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => beneficiaries.beneficiaryDetail
)
export const beneficiariesPaginationSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => beneficiaries.beneficiariesPagination
)
export const beneficiariesCountSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => beneficiaries.beneficiariesCount
)
export const beneficiariesLoadingSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => {
    return beneficiaries.beneficiariesLoading
  }
)
export const beneficiariesFiltersListSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => {
    return beneficiaries.beneficiariesFiltersList
  }
)

// Enrolments
export const getEnrolmentsData = (state) => state.enrolment
export const filtersListSelector = createSelector(
  getEnrolmentsData,
  (enrolment) => enrolment.enrolmentFiltersList
)

export const toggleAssignedSelector = createSelector(
  getEnrolmentsData,
  (enrolment) => enrolment.enrolmentToggleAssigned
)

export const toggleAttentionSelector = createSelector(
  getEnrolmentsData,
  (enrolment) => enrolment.enrolmentToggleAttention
)

export const enrolmentsSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => beneficiaries.enrolments
)

export const enrolmentsCountSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => beneficiaries.enrolmentsCount || 0
)

export const enrolmentsLoadingSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => beneficiaries.enrolmentsLoading
)

export const approvedEnrolmentsSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => beneficiaries.approvedEnrolments
)

export const approvedEnrolmentsLoadingSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => beneficiaries.approvedEnrolmentsLoading
)

export const approvedEnrolmentFromListSelector = createSelector(
  approvedEnrolmentsSelector,
  (approvedEnrolments) => approvedEnrolments && approvedEnrolments[0]
)

export const enrolmentCombinedUpdateLoading = createSelector(
  (state) => state.beneficiaries,
  (beneficiaries) => beneficiaries?.beneficiaryUpdateLoading || beneficiaries?.enrolmentUpdateLoading
)

export const enrolmentDetailSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => beneficiaries.enrolmentDetail
)

// Existence validations
export const existenceValidationsSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => beneficiaries.existenceValidations
)

// Certificates of entitlement
export const certificatesOfEntitlementSelector = createSelector(
  (state) => state.beneficiaries?.certificatesWithPage,
  (certificatesWithPage) => {
    const certificates = certificatesWithPage && Object.entries(certificatesWithPage).flatMap(([page, items]) => items)
    return certificates
  }
)

export const userHasNoCertificatesSelector = createSelector(
  certificatesOfEntitlementSelector,
  (certificates) => {
    return Array.isArray(certificates) && !certificates?.length
  }
)

// App installs selector
export const appInstallsSelector = createSelector(
  (state) => state.beneficiaries.appInstalls,
  (appInstalls) => appInstalls
)

export const appInstallsCountSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => beneficiaries.appInstallsCount || 0
)

// User invites selector
export const userInvitationsSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => beneficiaries.invitations
)

export const userInvitationsCountSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => beneficiaries.invitationsCount || 0
)

export const userInvitationsLoadingSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => beneficiaries.invitationsLoading
)

export const beneficiaryProfileLoadingSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => {
    return beneficiaries.enrolmentsLoading ||
      beneficiaries.existenceValidationsLoading ||
      beneficiaries.invitationsLoading ||
      beneficiaries.certificatesLoading ||
      beneficiaries.appInstallsLoading ||
      beneficiaries.instantCallLoading
  }
)

export const loadingCertificatesSelector = createSelector(
  (state) => state.beneficiaries?.certificatesLoading,
  (loading) => loading
)

export const loadingDCoEValidation = createSelector(
  (state) => state.beneficiaries?.certificateValidationLoading,
  (loading) => loading
)

export const loadingAppInstalls = createSelector(
  (state) => state.beneficiaries?.appInstallsLoading,
  (loading) => loading
)

// Calls history selector
export const callsHistorySelector = createSelector(
  (state) => state.beneficiaries?.callEvents,
  (callEvents) => callEvents ? getCallsHistory(callEvents) : []
)

export const loadingCallEvents = createSelector(
  (state) => state.beneficiaries?.callEventsLoading,
  (loading) => loading
)

export const loadingUserEnrolmentsSelector = createSelector(
  (state) => state.beneficiaries?.enrolmentsLoading,
  (loading) => loading
)

export const moreCertificatesRemainingSelector = createSelector(
  (state) => state.beneficiaries?.moreCertificatesRemaining,
  (remaining) => remaining
)

// Support schedule: availability slots and appointments
export const getSupportSchedule = (state) => state.supportSchedule

// DR - Not used, to be removed
// export const makeOperatorAvailabilityEvent = ({ operator, availability }) => {
//   const operatorName = getReducedName(operator)
//   const operatorLightColor = getLightColorFromName(getFullName(operator))
//   const operatorColor = getColorFromName(getFullName(operator))
//   const operatorDarkerColor = getDarkerColorFromName(getFullName(operator))

//   const startTime = moment(availability.start_date)
//   const start = startTime.toISOString()
//   const endTime = moment(availability.end_date)
//   const end = endTime.toISOString()
//   const reserved = Boolean(availability?.reserved)
//   const title = reserved ? `${operatorName} booked` : `${operatorName} available`
//   const backgroundColor = reserved ? operatorColor : operatorLightColor
//   const textColor = reserved ? '#fff' : '#575757'
//   const result = {
//     title,
//     ...{ start, end },
//     backgroundColor,
//     borderColor: operatorDarkerColor,
//     textColor,
//     extendedProps: { ...availability, operator, reserved }
//   }
//   return result
// }

// export const availabilitySlotsSelector = createSelector(
//   getSupportSchedule,
//   (supportSchedule) => {
//     const result = supportSchedule?.availabilitySlots?.flatMap((operatorInfo) => {
//       const operator = operatorInfo.operator

//       const midCalculation = operatorInfo.availability.map((info) => {
//         return makeOperatorAvailabilityEvent({
//           operator,
//           availability: info
//         })
//       })
//       return midCalculation
//     })
//     return result
//   }
// )

// export const makeOperatorAppointmentEvent = ({ id, operator, slot, beneficiary }) => {
//   const operatorName = getFullName(operator)
//   const beneficiaryName = getFullName(beneficiary)
//   const start = slot.start_datetime
//   const end = slot.end_datetime
//   const operatorColor = getColorFromName(getFullName(operator))
//   const operatorDarkerColor = getDarkerColorFromName(getFullName(operator))

//   const result = {
//     title: `${beneficiaryName} with ${operatorName}`,
//     backgroundColor: operatorColor,
//     borderColor: operatorDarkerColor,
//     textColor: '#fff',
//     ...{ start, end, id }
//   }
//   return result
// }

export const makeOperatorEvent = ({ id, operator, slot, onboarding }) => {
  const title = getInitialsName(operator)
  const operatorColor = onboarding ? getColorFromName(getFullName(operator)) : getLightColorFromName(getFullName(operator))
  const operatorDarkerColor = getDarkerColorFromName(getFullName(operator))

    return {
        id,
        title,
        backgroundColor: operatorColor,
        borderColor: operatorDarkerColor,
        textColor: onboarding ? '#fff' : '#565656',
        start: slot.start_datetime,
        end: slot.end_datetime
    }
}

export const makeOperatorAvailabilityEvent = ({ operator, availability }) => {
    const operatorName = getReducedName(operator)
    const operatorLightColor = getLightColorFromName(getFullName(operator))
    const operatorColor = getColorFromName(getFullName(operator))
    const operatorDarkerColor = getDarkerColorFromName(getFullName(operator))

    const startTime = moment(availability.start_date)
    const start = startTime.toISOString()
    const endTime = moment(availability.end_date)
    const end = endTime.toISOString()
    const reserved = Boolean(availability?.reserved)
    const title = reserved ? `${operatorName} booked` : `${operatorName} available`
    const backgroundColor = reserved ? operatorColor : operatorLightColor
    const textColor = reserved ? '#fff' : '#575757'
    const result = {
        title,
        ...{ start, end },
        backgroundColor,
        borderColor: operatorDarkerColor,
        textColor,
        extendedProps: { ...availability, operator, reserved }
    }
    return result
}

export const availabilitySlotsSelector = createSelector(
  getSupportSchedule,
  (supportSchedule) => {
    const result = supportSchedule?.availabilitySlots?.flatMap((operatorInfo) => {
      const operator = operatorInfo.operator

      const midCalculation = operatorInfo.availability.map((info) => {
        return makeOperatorAvailabilityEvent({
          operator,
          availability: info
        })
      })
      return midCalculation
    })
    return result
  }
)

export const availabilitySlotsSelectorV2 = createSelector(
    getSupportSchedule,
    (supportSchedule) => {
        return supportSchedule?.availabilitySlots
    }
)

export const makeOperatorAppointmentEvent = ({ id, operator, slot, beneficiary, onboarding }) => {
  const operatorName = getFullName(operator)
  const beneficiaryName = getFullName(beneficiary)
  const start = slot.start_datetime
  const end = slot.end_datetime
  const operatorColor = getColorFromName(getFullName(operator))
  const operatorDarkerColor = getDarkerColorFromName(getFullName(operator))

  const result = {
    title: `${beneficiaryName} with ${operatorName}`,
    backgroundColor: operatorColor,
    borderColor: operatorDarkerColor,
    textColor: onboarding ? '#fff' : '#565656',
    start: slot.start_datetime,
    end: slot.end_datetime
  }
}

export const appointmentsSelector = createSelector(
  getSupportSchedule,
  (supportSchedule) => {
    return supportSchedule?.appointments
  }
)

// export const calendarAppointmentsSelector = createSelector(
//   appointmentsSelector,
//   (appointments) => {
//     const result = appointments?.map((appointmentInfo) => {
//       const operator = appointmentInfo?.operator

//       const calendarAppointment = makeOperatorAppointmentEvent({
//         id: appointmentInfo?.id,
//         operator,
//         slot: appointmentInfo?.slot,
//         onboarding: appointmentInfo?.onboarding
//       })
//       return { ...calendarAppointment, ...{ extendedProps: appointmentInfo } }
//     })
//     return result
//   }
// )

export const calendarEventsSelector = createSelector(
  appointmentsSelector,
  (appointments) => {
    const result = appointments?.map((appointmentInfo) => {
      const calendarAppointment = makeOperatorEvent(appointmentInfo)
      return { ...calendarAppointment, ...{ extendedProps: appointmentInfo } }
    })
    return result
  }
)

export const availabilityRequestInProgress = createSelector(
  getSupportSchedule,
  (supportSchedule) => supportSchedule.onAvailabilitySlotsRequest
)

// export const loadingAvailablityAndOperatorsData = createSelector(
//   [getSupportSchedule, getUsers],
//   (supportSchedule, users) => supportSchedule.onAvailabilitySlotsRequest || users.loading
// )

export const loadingAppointmentsAndOperatorsData = createSelector(
  [getSupportSchedule, getUsers],
  (supportSchedule, users) => supportSchedule.onAppointmentsRequest || users.loading
)

export const appointmentsRequestInProgress = createSelector(
  getSupportSchedule,
  (supportSchedule) => supportSchedule.onAppointmentsRequest
)

export const videoCallTokenRequestInProgress = createSelector(
  getSupportSchedule,
  (supportSchedule) => supportSchedule.onVideoCallUrlRequest
)

export const VideoCallUrlSelector = createSelector(
  getSupportSchedule,
  (supportSchedule) => supportSchedule.videoCallUrl
)

// Common
export const getCommonData = (state) => state.common

export const organizationsSelector = createSelector(
  getCommonData,
  (common) => common.organizations
)

export const loadingOrganizationsSelector = createSelector(
  getCommonData,
  (common) => common.loadingOrganizations
)

export const reportedCountriesSelector = createSelector(
  getCommonData,
  (common) => common.reportedCountries
)

export const loadingReportedCountriesSelector = createSelector(
  getCommonData,
  (common) => common.loadingReportedCountries
)

// Settings
export const drawerTypeSelector = createSelector(
  (state) => state.settings?.drawerType,
  (type) => type
)

export const widthSelector = createSelector(
  (state) => state.settings?.width,
  (width) => width
)

export const navCollapsedSelector = createSelector(
  (state) => state.settings?.navCollapsed,
  (navCollapsed) => navCollapsed
)

export const navigationStyleSelector = createSelector(
  (state) => state.settings?.navigationStyle,
  (navigationStyle) => navigationStyle
)
export const themeColorSelector = createSelector(
  (state) => state.settings?.themeColor,
  (themeColor) => themeColor
)
export const localeSelector = createSelector(
  (state) => state.settings?.locale,
  (locale) => locale
)
export const isDirectionRTLSelector = createSelector(
  (state) => state.settings?.isDirectionRTL,
  (isDirectionRTL) => isDirectionRTL
)
