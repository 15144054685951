import { makeStyles, Tab, Tabs as TabsMui } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(theme => ({
  tab: {
    minWidth: 'fit-content',
    flex: 1
  }
}))

const Tabs = ({ tabs, ...props }) => {
  const classes = useStyles()

  return (
    <TabsMui
      variant='scrollable'
      indicatorColor='primary'
      {...props}
    >
      {tabs.map(tab => {
        const { id, label } = tab
        const tabProps = { id, label, className: classes.tab }
        return <Tab key={id} {...tabProps} />
      })}
    </TabsMui>
  )
}

export { Tabs }
