import React from 'react'
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom'
import asyncComponent from '../../util/asyncComponent'
import { withLoadUserInfo } from '../../util/loadUserInfo'
import { ROUTES } from '../../constants/Routes'
import Beneficiary from './Beneficiary'

const Routes = () => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Redirect
        exact
        from={`${match.path}/${ROUTES.MAIN}`}
        to={`${match.url}/${ROUTES.BENEFICIARY}`}
      />
      <Route path={`${match.url}/${ROUTES.BENEFICIARY}`}>
        <Beneficiary />
      </Route>
      <Route
        path={`${match.url}/${ROUTES.ENROLMENT}`}
        component={asyncComponent(() => import('./Enrolment'))}
      />
      <Route
        path={`${match.path}/${ROUTES.MY_PROFILE}`}
        component={asyncComponent(() => import('./MyProfile'))}
      />
      <Route
        path={`${match.path}/${ROUTES.SCHEDULE}`}
        component={asyncComponent(() => import('./Schedule'))}
      />
      <Route
        path={`${match.path}/${ROUTES.MANAGER_USERS}`}
        component={asyncComponent(() => import('./User'))}
      />
      <Route component={asyncComponent(() => import('../../components/generic/Error/NotFound'))} />
    </Switch>
  )
}

export default withLoadUserInfo(Routes)
