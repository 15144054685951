import React, { cloneElement, useState } from 'react'
import { Box, CircularProgress, IconButton, InputAdornment, InputBase, makeStyles, MenuItem, Select, Typography } from '@material-ui/core'
import IntlMessages from 'util/IntlMessages'
import { Close } from '@material-ui/icons'
import cl from 'classnames'

const useStyles = makeStyles(theme => ({
  horizontalBox: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #7e7e7e',
    borderRadius: '4px'
  },
  labelBeforeBox: {
    padding: '6px 12px 7px'
  },
  labelBefore: {
    fontSize: '14px',
    fontWeight: '590'
  },
  label: {
    paddingBottom: '9px'
  },
  selectRoot: {
    border: '1px solid rgba(126, 126, 126, 0.5)',
    borderRadius: '8px',
    padding: '15px 10px',
    fontSize: '18px',
    cursor: 'pointer',
    '&.dense': {
      fontSize: '16px',
      padding: '10px 12px'
    }
  },
  smallSelect: {
    fontSize: '0.875rem',
    padding: '2px 12px',
    border: 0,
    borderLeft: '1px solid #7E7E7E',
    borderRadius: 0
  },
  placeholder: {
    color: '#7E7E7E'
  },
  select: {
    padding: '7px 0',
    '&:focus': {
      background: 'none'
    }
  },
  inputAdornment: {
    padding: '12px'
  },
  hideIcon: {
    display: 'none'
  },
  menuItem: {
    padding: '12px 16px'
  },
  clearButton: {
    padding: '6px'
  },
  clearIcon: {
    fontSize: '1.25rem'
  }
}))

const Dropdown = ({
  id,
  label,
  placeholder,
  value,
  options,
  customOption,
  onChange,
  disabled,
  loading,
  valueProp,
  labelProp,
  labelBefore,
  small,
  maxListHeight,
  hbClassName,
  hideArrow,
  leftIcon,
  clearButton,
  dense,
  ...props
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const valueProperty = valueProp ?? 'value'
  const labelProperty = labelProp ?? 'label'

  const onOpen = event => setAnchorEl(event.currentTarget.parentElement)

  const onClose = () => setAnchorEl(null)

  const handleChange = value => {
    let valueParsed
    try {
      valueParsed = JSON.parse(value)
    } catch (e) {
      valueParsed = value
    }
    onChange(valueParsed)
  }

  const renderValue = value => {
    if (!value) return placeholder
    const option = options.find(option => getOptionValue(option) === value)
    return option ? option[labelProperty] : null
  }

  const clearValue = event => {
    event.stopPropagation()
    onChange(null)
  }

  const getOptionValue = option => {
    return typeof option[valueProperty] === 'object'
      ? JSON.stringify(option[valueProperty])
      : option[valueProperty]?.toString()
  }

  return (
    <Box className={labelBefore && cl(classes.horizontalBox, hbClassName)}>
      {!!label && (
        <Box className={labelBefore ? classes.labelBeforeBox : classes.label}>
          <Typography className={labelBefore ? classes.labelBefore : null}>
            <IntlMessages id={label} />
          </Typography>
        </Box>
      )}
      <Select
        id={id}
        variant='outlined'
        value={!!value && typeof value === 'object' ? JSON.stringify(value) : value?.toString() ?? ''}
        onOpen={onOpen}
        onClose={onClose}
        onChange={event => handleChange(event.target.value)}
        input={
          <InputBase
            classes={{
              root: cl(
                classes.selectRoot,
                small && classes.smallSelect,
                dense && 'dense',
                !value?.toString() && classes.placeholder)
            }}
            startAdornment={
              !!leftIcon && (
                <InputAdornment
                  position='start'
                  className={classes.inputAdornment}
                >
                  {loading ? (
                    <CircularProgress size={25} />
                  ) : (
                    cloneElement(leftIcon, {
                      color: disabled ? 'inherit' : 'primary'
                    })
                  )}
                </InputAdornment>
            )
            }
            endAdornment={!!clearButton && !!value && (
              <IconButton
                aria-label='clear'
                onClick={clearValue}
                className={classes.clearButton}
                disabled={disabled || loading}
                disableRipple
              >
                <Close className={classes.clearIcon} />
              </IconButton>
            )}
          />
        }
        MenuProps={{
          style: { justifyContent: 'flex-end', maxHeight: maxListHeight },
          anchorEl: anchorEl,
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          transformOrigin: { vertical: labelBefore ? -1 : 0, horizontal: 0 },
          getContentAnchorEl: null
        }}
        classes={{
          select: classes.select,
          icon: hideArrow ? classes.hideIcon : ''
        }}
        renderValue={renderValue}
        displayEmpty
        fullWidth
        disabled={disabled || loading}
        {...props}
      >
        {options.map(option => {
          const optionValue = getOptionValue(option)

          return (
            <MenuItem
              key={optionValue}
              value={optionValue}
              classes={{ root: classes.menuItem }}
            >
              {customOption ? (
                customOption(option)
              ) : (
                <span>{option[labelProperty]}</span>
              )}
            </MenuItem>
          )
        })}
      </Select>
    </Box>
  )
}

export { Dropdown }
