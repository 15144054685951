import { FormControlLabel, Switch } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import IntlMessages from 'util/IntlMessages'

const SwitchButton = withStyles(theme => ({
  root: {
    width: 42,
    height: 22,
    padding: 0,
    margin: theme.spacing(1)
  },
  switchBase: {
    padding: '1px',
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      padding: '1px 0 0 5px',
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none'
      }
    },
    '&$focusVisible $thumb': {
      color: theme.palette.primary.main,
      border: '6px solid #fff'
    }
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: '#7E7E7E',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {},
  focusVisible: {},
  label: {
    fontSize: '0.875rem'
  },
  bigLabel: {
    fontSize: '1rem'
  }
}))(({ classes, label, labelTranslated, bigLabel, ...props }) => {
  const translatedLabel = label ? <IntlMessages id={label} /> : labelTranslated

  return (
    <FormControlLabel
      control={
        <Switch
          color='primary'
          focusVisibleClassName={classes.focusVisible}
          disableRipple
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked
          }}
          {...props}
        />
      }
      label={translatedLabel}
      classes={{ label: bigLabel ? classes.bigLabel : classes.label }}
    />
  )
})

export { SwitchButton }
