import { Button, Grid, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { Tune } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toggleAssigned, toggleAttention } from '../../../actions/Enrolment'
import { SwitchButton } from '../SwitchButton'
import { ChipsPanel } from '../ChipsPanel'
import { ENROLMENT_WORKFLOW_STATUS, ENROLMENT_WORKFLOW_STATUS_DISPLAY } from 'constants/common'
import { SearchBox } from '../SearchBox'

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: '0.75rem'
  },
  filtersContainer: {
    display: 'flex',
    justifyContent: 'end'
  },
  filtersButton: {
    textTransform: 'none',
    minWidth: 'unset'
  }
}))

const FiltersHeader = ({
  onToggleDrawer,
  toggleFirstSwitch,
  toggleSecondSwitch,
  filters,
  selectedFilters,
  checkedList,
  setFiltersList,
  firstSwitchChecked,
  secondSwitchChecked,
  onSearch,
  searchString
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [filtersForChips, setFiltersForChips] = useState()

  useEffect(() => {
    const filtersForChips = []

    selectedFilters && Object.keys(selectedFilters).forEach(key => {
      const filterValue = selectedFilters[key]

      if (filterValue) {
        if (typeof filterValue === 'object' && filterValue.length) {
          filterValue.forEach(value =>
            filtersForChips.push({
              key: `${key}_${value}`,
              value,
              label: getChipLabel(key, value),
              isArrayValue: true
            })
          )
        } else filtersForChips.push({ key, value: filterValue, label: getChipLabel(key, filterValue) })
      }
    })
    setFiltersForChips(filtersForChips)
  }, [selectedFilters])

  const getChipLabel = (key, value) => {
    const options = filters?.find(filter => filter.id === key)?.options
    const option = options?.find(op => JSON.stringify(op.value) === JSON.stringify(value))

    switch (key) {
      case 'assign_me':
        return value === true ? 'Assigned to me' : 'Not assigned to me'
      case 'on_hold':
        return value === 'true' ? 'On hold' : 'Not on hold'
      case 'has_model':
        return value === 'true' ? 'Has biometric model' : 'Has not biometric model'
      case 'has_appointment':
        return value === 'true' ? 'Has appointment' : 'Has not appointment'
      case 'allowed_to_enroll':
        return value === 'true' ? 'Allowed to enroll' : 'Not allowed to enroll'
      case 'can_submit_DCE':
        return value === 'true' ? 'Enabled for DCE' : 'Not enabled for DCE'
      case 'opt_out':
        return value === 'true' ? 'Opted out' : 'Not opted out'
      case 'communication_exhausted':
        return value === 'true' ? 'Comm. exhausted' : 'Comm. not exhausted'
      case 'invitation_redeemed':
        return value === 'true' ? 'Invitation redeemed' : 'Invitation not redeemed'
      case 'no_enrolments_requested':
        return value === 'true' ? 'No enrolments requested' : 'Has enrolments requested'
      case 'workflow_status':
        return ENROLMENT_WORKFLOW_STATUS_DISPLAY[value]
      default:
        return option ? option.label ?? option.value : value
    }
  }

  const onRemoveTag = ({ key, value, isArrayValue }) => {
    const updatedList = { ...checkedList }

    if (isArrayValue) key = key.replace(`_${value}`, '')

    if (isArrayValue) {
      const filterValue = updatedList[key]
      const oldValueIdx = filterValue.indexOf(value)

      if (oldValueIdx !== -1) { filterValue.splice(oldValueIdx, 1) }

      if (!filterValue.length) updatedList[key] = undefined
    } else {
      updatedList[key] = undefined
    }

    dispatch(setFiltersList({ ...updatedList, page: 1 }))

    const { workflow_status, assign_me } = updatedList
    dispatch(
      toggleAttention(
        workflow_status &&
          workflow_status.includes(
            ENROLMENT_WORKFLOW_STATUS.REQUIRES_ATTENTION.toString()
          )
          ? ENROLMENT_WORKFLOW_STATUS.REQUIRES_ATTENTION
          : undefined
      )
    )
    dispatch(toggleAssigned(assign_me))
  }

  return (
    <Grid container spacing={3} justifyContent='space-between' className={classes.container}>
      {/* Quick filters */}
      <Grid item>
        {toggleFirstSwitch && (
          <SwitchButton
            labelTranslated='Requires attention'
            checked={firstSwitchChecked}
            onChange={event => toggleFirstSwitch(event.target.checked)}
          />
        )}
        {toggleSecondSwitch && (
          <SwitchButton
            labelTranslated='Assigned to me'
            checked={secondSwitchChecked}
            onChange={event => toggleSecondSwitch(event.target.checked)}
          />
        )}
        {onSearch && <SearchBox value={searchString} search={onSearch} delay={500} />}
      </Grid>
      {/* Filters */}
      <Grid item xs={12} md={6} className={classes.filtersContainer}>
        {filtersForChips && (
          <ChipsPanel
            options={filtersForChips}
            idProperty='key'
            deleteOption={onRemoveTag}
            idsToBeIgnored={['size', 'ordering', 'page']}
          />
        )}
        <Button
          variant='outlined'
          color='primary'
          className={classes.filtersButton}
          endIcon={<Tune />}
          onClick={() => onToggleDrawer(true)}
        >
          Filters
        </Button>
      </Grid>
    </Grid>
  )
}

FiltersHeader.propTypes = {
  onToggleDrawer: PropTypes.func,
  toggleFirstSwitch: PropTypes.func,
  toggleSecondSwitch: PropTypes.func,
  selectedFilters: PropTypes.object,
  checkedList: PropTypes.object,
  onSearch: PropTypes.func
}

export default FiltersHeader
