import { Chip, makeStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  delete: {
    color: '#fff',
    width: '18px'
  }
}))

const ChipButton = ({ option, label, onDelete, ...props }) => {
  const classes = useStyles()

  return (
    <Chip
      // avatar={<Avatar alt={optionLabel} src={option.picture} />}
      label={label}
      color='primary'
      deleteIcon={<Close />}
      onDelete={() => onDelete(option)}
      classes={{ deleteIconColorPrimary: classes.delete }}
      {...props}
    />
  )
}

export { ChipButton }
