import React from 'react'
import DialogContentText from '@material-ui/core/DialogContentText'
import IntlMessages from 'util/IntlMessages'
import { ActionDialog } from 'components/generic/ActionDialog'
import { Button } from '@material-ui/core'

export default function RecoverSecurityCodeDialog ({
  beneficiary,
  recoverSecurityCode,
  open,
  handleClose
}) {
  const handleSubmit = async () => {
    recoverSecurityCode()
    handleClose()
  }

  return (
    <ActionDialog
      open={open}
      onClose={handleClose}
      title={<IntlMessages id='appModule.recoverSecurityCode' />}
      content={
        <DialogContentText>
          <IntlMessages
            id='recoverBeneficiarySecurityCode.dialog'
            values={{ email: beneficiary?.email }}
          />
        </DialogContentText>
      }
      actions={
        <Button
          id='submit'
          variant='contained'
          color='primary'
          className='float-right'
          onClick={handleSubmit}
        >
          Submit
        </Button>
      }
    />
  )
}
