import React, { useState } from 'react'
import StatusPill from './StatusPill'
import StatusDot from './StatusDot'

const Status = ({ status, statusValues, getColor }) => {

  const [hover, setHover] = useState(false)

  const toggleHover = (value) => {
    setHover(value)
  }

  return <div
    className="d-flex flex-row justify-content-left"
    onMouseEnter={() => toggleHover(true)}
    onMouseLeave={() => toggleHover(false)}
  >
    {
      hover ? <StatusPill status={status} statusValues={statusValues} colorClass={getColor(status)}/> :
        <StatusDot colorClass={getColor(status)}/>
    }
  </div>
}

export default Status
