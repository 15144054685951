import React, { useState } from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { Box, CircularProgress, IconButton, makeStyles, Typography } from '@material-ui/core'
import { CalendarToday, Close } from '@material-ui/icons'
import IntlMessages from 'util/IntlMessages'
import cl from 'classnames'

const DATE_FORMAT = 'MMMM Do, YYYY'

const useStyles = makeStyles(theme => ({
  label: {
    paddingBottom: '8px'
  },
  pickerRoot: {
    border: '1px solid rgba(126, 126, 126, 0.5)',
    borderRadius: '8px',
    padding: '15px 10px',
    cursor: 'pointer',
    '&.dense': {
      padding: '10px 0'
    }
  },
  keyboardButton: {
    color: theme.palette.primary.main
  },
  inputBase: {
    cursor: 'pointer'
  },
  inputAdornment: {
    marginRight: '8px',
    '&.dense': {
      marginRight: '5px'
    }
  },
  input: {
    fontSize: '18px',
    padding: '7px 0',
    '&:not(.Mui-disabled)': {
      cursor: 'pointer'
    },
    '&::placeholder': {
      fontSize: '18px'
    },
    '&.dense': {
      fontSize: '16px'
    }
  },
  clearButton: {
    padding: '6px'
  },
  clearIcon: {
    fontSize: '1.25rem'
  }
}))

const DatePicker = ({
  id,
  label,
  value,
  placeholder,
  onChange,
  disabled,
  loading,
  clearButton,
  onOpen,
  dense,
  ...props
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const clearDate = event => {
    event.stopPropagation()
    onChange(null)
  }

  const onClick = () => {
    if (!disabled && !loading) {
      setOpen(true)
      onOpen && onOpen()
    }
  }

  return (
    <Box>
      {!!label && (
        <Typography className={classes.label}>
          <IntlMessages id={label} />
        </Typography>
      )}
      <KeyboardDatePicker
        id={id}
        disableToolbar
        variant='inline'
        open={open}
        format={DATE_FORMAT}
        value={value}
        placeholder={placeholder ?? 'Select'}
        KeyboardButtonProps={{ className: classes.keyboardButton }}
        keyboardIcon={
          loading ? (
            <CircularProgress size={25} />
          ) : (
            <CalendarToday />
        )
        }
        InputProps={{
          disableUnderline: true,
          readOnly: true,
          className: classes.inputBase,
          classes: { input: cl(classes.input, dense && 'dense') },
          endAdornment: !!clearButton && !!value && (
            <IconButton
              aria-label='clear'
              onClick={clearDate}
              className={classes.clearButton}
              disabled={disabled || loading}
              disableRipple
            >
              <Close className={classes.clearIcon} />
            </IconButton>
          )
        }}
        InputAdornmentProps={{
          position: 'start',
          disablePointerEvents: true,
          className: cl(classes.inputAdornment, dense && 'dense')
        }}
        classes={{ root: cl(classes.pickerRoot, dense && 'dense') }}
        onChange={onChange}
        onClick={onClick}
        onClose={() => setOpen(false)}
        disabled={disabled || loading}
        {...props}
      />
    </Box>
  )
}

export { DatePicker }
