import React, { useEffect, useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import Filters from './components/Filters'
import Buttons from './components/Buttons'
import styles from './FilterSidebar.module.scss'
import PropTypes from 'prop-types'
import EnrolmentInformation from './components/EnrolmentInformation'
import FiltersContainer from './components/FiltersContainer'
import EnrolmentAppointment from './components/EnrolmentAppointment'

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))

const FiltersSidebar = ({
  opened,
  toggleDrawer,
  filters,
  enrolment,
  clearFilters,
  initialList,
  filtersTitle,
  deleteTitle,
  submitTitle,
  activateFilters,
  activateInformations,
  activateAppointment,
  enrolmentNotes,
  enrolmentPhone,
  enrolmentLabel,
  onDelete,
  onSubmit
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const classes = useStyles()
  const [checkedList, setCheckedList] = useState()
  const [newPhoneValue, setNewPhoneValue] = useState()
  const [newNoteValue, setNewNoteValue] = useState()
  const [appointmentValue, setAppointmentValue] = useState()
  const [submitValues, setSubmitValues] = useState()
  const [disableSubmit, setDisableSubmit] = useState()

  const handleChange = (filter, value, selected = true) => {
    const { id, isCheckboxArray, valueIsObject, mutuallyExclusive } = filter
    const updatedList = { ...checkedList }

    if (isCheckboxArray) {
      let filterValue = updatedList[id] ? [...updatedList[id]] : []
      if (selected) {
        filterValue.push(value)

        if (mutuallyExclusive?.length) {
          mutuallyExclusive.forEach(fieldId => { updatedList[fieldId] = undefined })
        }
      } else {
        const oldValueIdx = filterValue.indexOf(value)
        if (oldValueIdx !== -1) { filterValue.length > 1 ? filterValue.splice(oldValueIdx, 1) : filterValue = undefined }
      }
      updatedList[id] = filterValue
    } else {
      if (valueIsObject) {
        updatedList[id] = selected ? JSON.parse(value) : undefined
      } else {
        updatedList[id] = selected ? value : undefined
      }

      if (value && mutuallyExclusive?.length) {
        mutuallyExclusive.forEach(fieldId => { updatedList[fieldId] = undefined })
      }
    }

    setCheckedList(updatedList)
  }

  const onSetPhoneValue = phoneValue => {
    setNewPhoneValue(phoneValue)
  }

  const onSetNoteValue = noteValue => {
    setNewNoteValue(noteValue)
  }

  const onSetAppointment = appointment => {
    setAppointmentValue(appointment)
  }

  const getDisableSubmit = appointmentValue => {
    if (activateAppointment && appointmentValue) {
      const { enrolment, selectedDate, selectedSlot, selectedAgent } = appointmentValue
      const { assigned_agent } = enrolment

      // Disable submit if
      return (
        (assigned_agent && !selectedAgent) ||
        (selectedDate && (!selectedSlot || !selectedAgent))
      )
    }
    return false
  }

  useEffect(() => {
    if (!opened) {
      setCheckedList({ ...initialList })
    }
    setIsOpen(opened)
  }, [opened])

  useEffect(() => {
    clearFilters && setCheckedList({})
  }, [clearFilters])

  useEffect(() => {
    setCheckedList({ ...initialList })
  }, [initialList])

  useEffect(() => {
    activateFilters && setSubmitValues(checkedList)
  }, [checkedList])

  useEffect(() => {
    activateInformations && setSubmitValues(newPhoneValue)
  }, [newPhoneValue])

  useEffect(() => {
    activateInformations && setSubmitValues(newNoteValue)
  }, [newNoteValue])

  useEffect(() => {
    if (activateAppointment) {
      setSubmitValues(appointmentValue)
      setDisableSubmit(getDisableSubmit(appointmentValue))
    }
  }, [appointmentValue])

  const list = useMemo(
    () => (
      <Box className={styles.drawerBox} role='presentation'>
        <FiltersContainer
          children={
            <Filters
              filters={filters}
              clearFilters={clearFilters}
              handleChange={handleChange}
              checkedList={checkedList}
            />
          }
          title={filtersTitle}
          toggleDrawer={toggleDrawer}
        />
      </Box>
    ),
    [filters, clearFilters, checkedList]
  )

  const enrolmentInformation = useMemo(
    () => (
      <Box className={styles.drawerBox} role='presentation'>
        <FiltersContainer
          children={
            <EnrolmentInformation
              phoneValue={enrolmentPhone}
              notesValue={enrolmentNotes}
              label={enrolmentLabel}
              setNoteValue={value => onSetNoteValue(value)}
              setPhoneValue={value => onSetPhoneValue(value)}
            />
          }
          title={filtersTitle}
          toggleDrawer={toggleDrawer}
        />
      </Box>
    ),
    [enrolmentPhone, enrolmentNotes]
  )

  const enrolmentAppointment = useMemo(
    () => (
      <Box className={styles.drawerBox} role='presentation'>
        <FiltersContainer
          children={
            <EnrolmentAppointment
              enrolment={enrolment}
              setAppointment={value => onSetAppointment(value)}
            />
          }
          title={filtersTitle}
          toggleDrawer={toggleDrawer}
        />
      </Box>
    ),
    [enrolment]
  )

  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={() => toggleDrawer(false)}
      className={styles.drawer}
      classes={{
        paper: classes.paper
      }}
    >
      {activateFilters && list}
      {activateInformations && enrolmentInformation}
      {activateAppointment && enrolmentAppointment}
      <Buttons
        onDelete={value => onDelete(value)}
        onSubmit={() => onSubmit(submitValues)}
        deleteTitle={deleteTitle}
        submitTitle={submitTitle}
        toggleDrawer={() => toggleDrawer(false)}
        disableSubmit={disableSubmit}
      />
    </Drawer>
  )
}

FiltersSidebar.propTypes = {
  filtersTitle: PropTypes.string,
  deleteTitle: PropTypes.string,
  submitTitle: PropTypes.string,
  opened: PropTypes.bool,
  clearFilters: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  filters: PropTypes.array,
  enrolment: PropTypes.object,
  initialList: PropTypes.object,
  activateFilters: PropTypes.bool,
  activateInformations: PropTypes.bool,
  activateAppointment: PropTypes.bool,
  enrolmentNotes: PropTypes.string,
  enrolmentPhone: PropTypes.string,
  enrolmentLabel: PropTypes.string,
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func
}

export default FiltersSidebar
