import { ENROLMENT_STATUS } from '../../../constants/common'

const getColor = (status) => {
  let result = null
  switch (status) {
  case ENROLMENT_STATUS.PENDING: {
    result = 'primary'
    break
  }
  case ENROLMENT_STATUS.APPROVED: {
    result = 'success'
    break
  }
  case ENROLMENT_STATUS.REJECTED: {
    result = 'danger'
    break
  }
  case ENROLMENT_STATUS.WITHOUT: {
    result = 'light'
    break
  }
  default: {
    result = 'primary'
  }
  }
  return result
}

export default getColor
