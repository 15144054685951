import React, { useEffect, useState } from 'react'
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography
} from '@material-ui/core'
import IntlMessages from 'util/IntlMessages'
import { Check, Close } from '@material-ui/icons'
import { ChipsPanel } from 'components/generic/ChipsPanel'
import { SearchBox } from 'components/generic/SearchBox'
import { LinkButton } from 'components/generic/LinkButton'
import { NoResults } from 'components/generic/NoResults'

const useStyles = makeStyles(theme => ({
  subtitle: {
    fontSize: '1rem',
    marginBottom: '10px'
  },
  selectAll: {
    marginTop: '20px',
    textAlign: 'right'
  },
  agentInfo: {
    marginBottom: '10px'
  },
  selectedMultiple: {
    backgroundColor: '#F2F4F7',
    borderRadius: '4px'
  },
  selectedSingle: {
    '&.Mui-selected': {
      backgroundColor: 'unset',
      border: '1px solid #7E7E7E80',
      borderRadius: '8px',
      '&:hover': {
        backgroundColor: 'unset',
        cursor: 'default'
      }
    }
  },
  secondaryActionSingle: {
    right: '10px'
  },
  buttonMultiple: {
    backgroundColor: '#F2F4F7'
  },
  buttonSelectedMultiple: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  buttonSelectedSingle: {
    padding: '6px'
  },
  clearIcon: {
    fontSize: '1.25rem'
  }
}))

export const SelectionMode = {
  None: 'None',
  Single: 'Single',
  Multiple: 'Multiple'
}

const AgentsList = ({
  agents,
  agentsChanged,
  selectionMode = SelectionMode.Multiple,
  selectedAgent,
  showSelectAll,
  showSelectedAsChips,
  selectionRequired
}) => {
  // #region Hooks
  const classes = useStyles()
  const [agentsList, setAgentsList] = useState([])
  const [searchString, setSearchString] = useState('')
  const [agentsSelected, setAgentsSelected] = useState(selectedAgent)

  const isSingleSelection = selectionMode === SelectionMode.Single
  const isMultipleSelection = selectionMode === SelectionMode.Multiple

  useEffect(() => {
    if (agents) filterAgents()
  }, [agents])
  // #endregion

  // #region Search and filter methods
  const onSearch = value => {
    setSearchString(value)
    filterAgents(value)
  }

  const filterAgents = (value = searchString) => {
    const filteredAgents = value
      ? agents.filter(agent =>
          agent.fullName?.toLowerCase().includes(value.toLowerCase())
        )
      : [...agents]

    setAgentsList(filteredAgents)
  }
  // #endregion

  // #region Selection methods
  const onSelectAll = event => {
    event.preventDefault()
    selectUnselectAll(true)
  }

  const toggleSelected = agent => {
    if (selectionMode !== SelectionMode.None) {
      // If single selection, unselect first
      if (isSingleSelection) {
        agents.filter(a => a.selected).map(agent => {
          agent.selected = false
          return agent
        })
      }
      agent.selected = !agent.selected

      emitSelectedAgents()
    }
  }

  const selectUnselectAll = select => {
    agents.map(agent => {
      agent.selected = select
      return agent
    })

    setAgentsList(agents)
    emitSelectedAgents()
  }

  const emitSelectedAgents = () => {
    setAgentsSelected(
      isMultipleSelection
        ? agents?.filter(a => a.selected)
        : agents?.find(a => a.selected)
    )
    agentsChanged(
      isMultipleSelection
        ? [...agents]
        : agents.find(a => a.selected)
    )
  }
  // #endregion

  // #region Render methods
  const renderListItem = agent => {
    const isAgentSelected = (isSingleSelection && !!agentsSelected) || agent.selected
    return (
      <ListItem
        key={agent.id}
        button
        selected={isAgentSelected}
        className={classes.agentInfo}
        classes={{ selected: classes[`selected${selectionMode}`] }}
        onClick={() => !isAgentSelected && toggleSelected(agent)}
        disableRipple
      >
        {/* <ListItemAvatar>
          <Avatar src={agent.picture} />
          <Avatar alt='avatar' className='user-avatar' />
        </ListItemAvatar> */}
        <ListItemText
          id={agent.id}
          primary={agent.fullName}
          secondary={agent.timezone}
        />
        {isMultipleSelection || !!agentsSelected ? (
          <ListItemSecondaryAction
            className={isAgentSelected ? classes[`secondaryAction${selectionMode}`] : ''}
          >
            <IconButton
              aria-labelledby={agent.id}
              classes={{
                root:
                  isAgentSelected
                    ? classes[`buttonSelected${selectionMode}`]
                    : classes[`button${selectionMode}`]
              }}
              onClick={() =>
              isMultipleSelection || !!agentsSelected
                ? toggleSelected(agent)
                : selectUnselectAll(false)}
            >
              {isMultipleSelection ? <Check /> : <Close className={classes.clearIcon} />}
            </IconButton>
          </ListItemSecondaryAction>) : null}
      </ListItem>
    )
  }
  // #endregion

  return (
    <Box>
      {isSingleSelection && agentsSelected ? (
        <List className={classes.agentsList}>
          {renderListItem(agentsSelected)}
        </List>
      ) : (
        <Box>
          {/* Required message */}
          {selectionMode !== SelectionMode.None &&
            selectionRequired &&
            (!agentsSelected || !agentsSelected.length) && (
              <Typography
                variant='body2'
                color='textSecondary'
                className={classes.subtitle}
              >
                {isMultipleSelection && (
                  <IntlMessages id='pages.schedule.agentsList.selectionMultiple.required.message' />
                )}
                {isSingleSelection && (
                  <IntlMessages id='pages.schedule.agentsList.selectionSingle.required.message' />
                )}
              </Typography>
            )}
          {/* Search box */}
          <SearchBox value={searchString} search={onSearch} />
          {/* Agents selected */}
          {isMultipleSelection && showSelectedAsChips && (
            <ChipsPanel
              options={agentsList.filter(agent => agent.selected)}
              labelProperty='fullName'
              deleteOption={agent => toggleSelected(agent)}
            />
          )}
          {/* Agents list */}
          {agentsList.length ? (
            <>
              <Box className={classes.selectAll}>
                {isMultipleSelection && showSelectAll && (
                  <LinkButton
                    label='pages.schedule.filters.selectAll'
                    click={onSelectAll}
                  />
                )}
              </Box>
              <List className={classes.agentsList}>
                {agentsList.map(agent => {
                  return renderListItem(agent)
                })}
              </List>
            </>
          ) : (<NoResults />)}
        </Box>
      )}
    </Box>
  )
}

export { AgentsList }
