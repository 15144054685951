import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { Box } from '@material-ui/core'
import asyncComponent from '../../util/asyncComponent'

const GenericLayout = (props) => {
  const match = useRouteMatch()

  return (
    <Box className='app-login-container d-flex justify-content-center align-items-center'>
      <div className='d-flex align-items-center justify-content-center'>
        <div className='logo-lg w-75' title='UNJSPF'>
          <img
            id='unjspf_logo'
            src={require('assets/images/unjspfLogo.png')}
            alt='UNJSPF Digital Certificate of Entitlement'
            title='UNJSPF Digital Certificate of Entitlement'
            className='img-fluid mb-3'
          />

          <div className='d-flex align-items-center'>
            <Switch>
              <Route path={`${match.url}forbidden`} component={asyncComponent(() => import('../../components/generic/Error/Forbidden'))} />
              <Route component={asyncComponent(() => import('../../components/generic/Error/NotFound'))} />
            </Switch>
          </div>

        </div>
      </div>
    </Box>
  )
}

export default GenericLayout
