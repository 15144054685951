import React from 'react'
import { Box, Tabs } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ChipButton } from '../ChipButton'
import cl from 'classnames'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    listStyle: 'none',
    overflow: 'auto',
    marginRight: '5px'
  },
  tabs: {
    minHeight: 'unset'
  },
  scroller: {
    marginBottom: '0'
  },
  scrollButtons: {
    color: theme.palette.primary.main
  },
  item: {
    padding: theme.spacing(0.25)
  }
}))

const ChipsPanel = ({
  options,
  idProperty,
  labelProperty,
  deleteOption,
  getTagProps,
  containerClass,
  idsToBeIgnored = []
}) => {
  const classes = useStyles()

  return (
    <Box className={cl(classes.container, containerClass)}>
      <Tabs
        value={false}
        variant='scrollable'
        className={classes.tabs}
        classes={{
          scroller: classes.scroller,
          scrollButtons: classes.scrollButtons
        }}
      >
        {options
          .filter(
            option => !idsToBeIgnored.includes(option[idProperty ?? 'id'])
          )
          .map((option, index) => {
            const optionId = option[idProperty ?? 'id']
            const optionLabel = option[labelProperty ?? 'label']
            const tagProps = getTagProps ? getTagProps({ index }) : {}

            return (
              <li key={optionId} className={classes.item}>
                <ChipButton
                  option={option}
                  label={optionLabel}
                  onDelete={deleteOption}
                  {...tagProps}
                />
              </li>
            )
          })}
      </Tabs>
    </Box>
  )
}

export { ChipsPanel }
