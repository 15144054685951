import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { Add, AssignmentLateOutlined, DescriptionOutlined, NoSimOutlined, Remove } from '@material-ui/icons'
import moment from 'moment'
import React, { cloneElement } from 'react'
import cl from 'classnames'
import StatusDot from 'components/generic/Status/StatusDot'

const LONG_DATE_TIME_FORMAT = 'MMMM Do, YYYY - hh:mm a'
const SHORT_DATE_TIME_FORMAT = 'DD/MM/YY hh:mm a'

const useStyles = makeStyles(theme => ({
  accordion: {
    borderRadius: '10px',
    border: '1px solid #7E7E7E80',
    boxShadow: 'none',
    marginBottom: '16px',
    '&::before': {
      backgroundColor: 'unset'
    }
  },
  accordionSummary: {
    padding: '0 20px',
    '&.expanded': {
      borderBottom: '1px solid #7E7E7E80'
    }
  },
  accordionSummaryContent: {
    margin: '16px 0',
    '&.Mui-expanded': {
      margin: '16px 0'
    }
  },
  iconContainer: {
    height: '60px',
    aspectRatio: '1/1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.success.main,
    color: 'white',
    borderRadius: '100px',
    '&.expired': {
      backgroundColor: '#7E7E7E'
    },
    '&.hasErrors': {
      backgroundColor: theme.palette.error.main
    }
  },
  icon: {
    width: '50%',
    height: 'unset',
    aspectRatio: '1/1'
  },
  summaryItem: {
    marginLeft: '12px'
  },
  summaryTitle: {
    color: '#202020',
    fontWeight: 700,
    marginBottom: '5px'
  },
  accordionDetails: {
    padding: '20px 30px'
  },
  detailContainer: {
    '&:not(:last-child)': {
      marginBottom: '10px'
    }
  },
  detailContLarge: {
    marginBottom: '30px'
  },
  detailTitle: {
    color: '#7E7E7E'
  }
}))

const CertificateOfEntitlement = ({ certificate, expanded, handleChange }) => {
  const classes = useStyles()

  const {
    country: countryCode,
    liveness_confirmation: proof,
    expiration_date: expirationDate,
    created: creationDate
  } = certificate

  const {
    country,
    biometric_validation_passed: biometricsPassed
  } = proof

  const certificateHasExpired = moment().isSameOrAfter(moment(expirationDate))
  const proofDateTime = creationDate && moment(creationDate).format(LONG_DATE_TIME_FORMAT)
  const expirationDateTime = expirationDate && moment(expirationDate).format(SHORT_DATE_TIME_FORMAT)
  const proofHasErrors = !proof.task_status
  const icon = proofHasErrors ? <NoSimOutlined /> : certificateHasExpired ? <AssignmentLateOutlined /> : <DescriptionOutlined />
  const title = proofHasErrors ? 'DCE processing error' : certificateHasExpired ? 'Expired DCE' : 'Valid DCE'
  const issuedDate = `Issued on ${proofDateTime}`

  const details = [
    { field: 'countryCode', title: 'Country code', value: countryCode },
    { field: 'expirationDate', title: 'Expires', value: expirationDateTime, largeMargin: true },
    {
      field: 'biometricTest',
      title: 'Biometric test',
      render: <StatusDot
        showLabel
        status={biometricsPassed}
        statusValues={{ true: 'Correct', false: 'Error' }}
        colorClass={biometricsPassed ? 'success' : 'danger'}
              />
    },
    { field: 'country', title: 'Country', value: country.name }
  ]

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className={classes.accordion}
      square
    >
      <AccordionSummary
        expandIcon={expanded ? <Remove /> : <Add />}
        className={cl(classes.accordionSummary, expanded && 'expanded')}
        classes={{ content: classes.accordionSummaryContent }}
      >
        <Grid container>
          <Grid item className={cl(classes.iconContainer, certificateHasExpired && 'expired', proofHasErrors && 'hasErrors')}>
            {cloneElement(icon, { color: 'inherit', className: classes.icon })}
          </Grid>
          <Grid item xs className={classes.summaryItem}>
            <Typography variant='h5' className={classes.summaryTitle}>
              {title}
            </Typography>
            <Typography variant='body2'>{issuedDate}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Grid container>
          {details && details.map(detail => {
            return (
              <Grid
                key={detail.field}
                container
                item
                xs={12}
                className={
                  detail.largeMargin
                    ? classes.detailContLarge
                    : classes.detailContainer
                }
              >
                <Grid item xs={4}>
                  <Typography className={classes.detailTitle}>
                    {detail.title}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  {detail.render ?? <Typography>{detail.value}</Typography>}
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export { CertificateOfEntitlement }
