import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'
import IntlMessages from 'util/IntlMessages'
import booleanOptions from '../BooleanField/booleanOptions'

const RadioButton = ({
  label,
  labelTranslated,
  value,
  options = [...booleanOptions],
  onChange,
  valueProp,
  labelProp,
  valueIsObject,
  ...props
}) => {
  const valueProperty = valueProp ?? 'value'
  const labelProperty = labelProp ?? 'title'

  const [selectedValue, setSelectedValue] = useState([])

  useEffect(() => {
    setSelectedValue((valueIsObject ? JSON.stringify(value) : String(value)) ?? null)
  }, [value])

  const translatedLabel = label ? <IntlMessages id={label} /> : labelTranslated

  const handleClick = (event) => {
    const valueClicked = event.target.value
    const selected = valueClicked !== selectedValue
    const value = selected ? valueClicked : null

    setSelectedValue(value)
    onChange(value, selected)
  }

  return (
    <FormControl component='fieldset'>
      <FormLabel component='legend'>{translatedLabel}</FormLabel>
      <RadioGroup
        value={selectedValue}
        {...props}
      >
        {options.map(option => {
          const { separator, labelStyle } = option
          const optionValue = valueIsObject ? JSON.stringify(option[valueProperty]) : String(option[valueProperty])

          return (
            <Fragment key={optionValue}>
              {separator ? <Divider className='my-3' /> : <></>}
              <FormControlLabel
                value={optionValue}
                control={<Radio color='primary' onClick={handleClick} />}
                label={<span style={labelStyle}>{option[labelProperty]}</span>}
              />
            </Fragment>
          )
        })}
      </RadioGroup>
    </FormControl>
  )
}

export { RadioButton }
