import PropTypes from 'prop-types'

export const ENROLMENT_PROP_TYPE = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  recent_picture: PropTypes.string,
  date_of_birth: PropTypes.string,
  phone_number: PropTypes.string,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
})

export const ORGANIZATION_PROP_TYPE = PropTypes.shape()

export const RETIREE_PROP_TYPE = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  username: PropTypes.string,
  email: PropTypes.string,
  date_joined: PropTypes.string,
  unjspf_uuid: PropTypes.string,
  retirement_date: PropTypes.string,
  organization_id: PropTypes.number,
  organization: ORGANIZATION_PROP_TYPE
})

export const USER_INFO_PROP_TYPE = PropTypes.shape({
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  username: PropTypes.string,
  groups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  email: PropTypes.string
})

export const VIEW_REF_PROP_TYPE = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.number,
  PropTypes.shape({ current: PropTypes.any })
])

export const STYLE_PROP_TYPE = PropTypes.oneOfType([PropTypes.object, PropTypes.array])

export const ICON_PROP_TYPE = PropTypes.oneOfType([PropTypes.string, PropTypes.object])

export const CHILDREN_PROP_TYPE = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node
])

export const LOCATION_PROP_TYPE = PropTypes.shape({
  pathname: PropTypes.string,
  search: PropTypes.string
})

export const HISTORY_PROP_TYPE = PropTypes.shape({
  location: LOCATION_PROP_TYPE
})

export const MATCH_PROP_TYPE = PropTypes.shape({
  url: PropTypes.string
})
