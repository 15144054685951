import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SidenavContent from './SidenavContent'
import { COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION } from 'constants/ActionTypes'
import { toggleCollapsedNav } from 'actions/Setting'
import { drawerTypeSelector, navCollapsedSelector, navigationStyleSelector } from '../../reducers/selectors'
import { Drawer } from '@material-ui/core'
import Footer from 'components/generic/template/Footer'

const SideNav = () => {
  const dispatch = useDispatch()
  const drawerType = useSelector(drawerTypeSelector)
  const navCollapsed = useSelector(navCollapsedSelector)
  const navigationStyle = useSelector(navigationStyleSelector)
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth)
    })
  }, [dispatch])

  const onToggleCollapsedNav = (e) => {
    const val = !navCollapsed
    dispatch(toggleCollapsedNav(val))
  }

  let drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-xl-flex' : drawerType.includes(COLLAPSED_DRAWER) ? '' : 'd-flex'
  let type = 'permanent'
  if (drawerType.includes(COLLAPSED_DRAWER) || (drawerType.includes(FIXED_DRAWER) && width < 1200)) {
    type = 'temporary'
  }

  if (navigationStyle === HORIZONTAL_NAVIGATION) {
    drawerStyle = ''
    type = 'temporary'
  }
  return (
    <div className={`app-sidebar d-none ${drawerStyle}`}>
      <Drawer
        className='app-sidebar-content'
        variant={type}
        open={type.includes('temporary') ? navCollapsed : true}
        onClose={onToggleCollapsedNav}
        classes={{ paper: 'side-nav' }}
      >
        <img
          id='unjspf_logo'
          src={require('assets/images/unjspfLogo.svg')}
          alt='UNJSPF Digital Certificate of Entitlement'
          title='UNJSPF Digital Certificate of Entitlement'
          className='logo'
        />
        <SidenavContent />
        <Footer />
      </Drawer>
    </div>
  )
}

export default SideNav
