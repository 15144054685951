import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'
import styles from './FiltersContainer.module.scss'
import { Close } from '@material-ui/icons'

const FiltersContainer = ({ title, toggleDrawer, children }) => {
    return <>
      <Box className={styles.filtersContainer}>
        <Typography variant='h5' className={styles.filterTitle}>
          {title}
          <Close className={styles.closeContainer} onClick={() => toggleDrawer(false)} />
        </Typography>
        <div className={styles.container}>{children}</div>
      </Box>
    </>
}

FiltersContainer.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    toggleDrawer: PropTypes.func
}

export default FiltersContainer
