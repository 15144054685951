import { makeStyles } from '@material-ui/core'
import EnrolmentsList from 'components/Enrolment/EnrolmentsList'
import EnrolmentSupportStatus from 'components/Enrolment/EnrolmentSupportStatus'
import NotAvailable from 'components/generic/NotAvailable'
import moment from 'moment'
import React from 'react'
import { getAgent } from 'util/enrolment'

const DATE_TIME_FORMAT = 'DD/MM/YY HH:mm'

const useStyles = makeStyles({
  cell: {
    borderBottom: 0
  }
})

const ProfileEnrolments = ({ beneficiary }) => {
  const classes = useStyles()

  const columnData = [
    {
      id: 'created',
      align: 'left',
      label: 'Requested',
      handler: row => moment(row.created).format(DATE_TIME_FORMAT)
    },
    {
      id: 'unjspf_uid',
      label: 'UID',
      align: 'left',
      handler: row => row?.user?.unjspf_uuid
    },
    {
      id: 'status',
      align: 'left',
      label: 'Status',
      handler: row => <EnrolmentSupportStatus status={row?.workflow_status_unjspf} />
    },
    {
      id: 'appointment_datetime',
      align: 'left',
      label: 'Appointment',
      sortable: true,
      handler: row => {
        const apptStartDateTime = row?.appointment?.slot?.start_datetime
        const appointmentInfo = apptStartDateTime ? moment(apptStartDateTime).format(DATE_TIME_FORMAT) : null
        return appointmentInfo || <NotAvailable />
      }
    },
    {
      id: 'assigned_agent',
      align: 'left',
      label: 'Agent',
      sortable: true,
      handler: row => {
        return getAgent(row) || <NotAvailable />
      }
    }
  ]

  const enrolmentsListProps = {
    beneficiary,
    defaultPagination: { ordering: '-created', size: 1000 },
    tableProps: { columnData, cellClass: classes.cell, hideFooter: true },
    showOnlyTable: true
  }

  return beneficiary && (
    <EnrolmentsList {...enrolmentsListProps} />
  )
}

export { ProfileEnrolments }
