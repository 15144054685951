import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import IntlMessages from 'util/IntlMessages'

const useStyles = makeStyles({
  noResults: {
    marginTop: '40px',
    textAlign: 'center'
  }
})

const NoResults = () => {
  const classes = useStyles()

  return (
    <Box className={classes.noResults}>
      <Typography variant='overline'>
        <IntlMessages id='noResults.message' />
      </Typography>
    </Box>
  )
}

export { NoResults }
