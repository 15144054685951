import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  createUserInvitation,
  deleteUserInvitation,
  loadUserInvitations
} from '../../../../actions/Beneficiaries'
import IntlMessages from 'util/IntlMessages'
import {
  CLEAR_INVITATIONS,
  CREATE_INVITATION_SUCCESS,
  DELETE_INVITATION_SUCCESS
} from '../../../../constants/ActionTypes'
import { NotificationManager } from 'react-notifications'
import moment from 'moment'
import IconButton from '@material-ui/core/IconButton'
import { AddOutlined, CloseOutlined } from '@material-ui/icons'
import {
  userInvitationsCountSelector,
  userInvitationsLoadingSelector,
  userInvitationsSelector
} from '../../../../reducers/selectors'
import { Box, Button, makeStyles } from '@material-ui/core'
import StatusDot from 'components/generic/Status/StatusDot'
import DataTableNew from 'components/generic/DataTableNew'

const DATE_FORMAT = 'DD/MM/YY'
const DATE_TIME_FORMAT = 'DD/MM/YY HH:mm'

const getColor = status => {
  if (status !== null) { return status ? 'success' : 'danger' } else return null
}

const useStyles = makeStyles(theme => ({
  cell: {
    borderBottom: 0
  },
  statusLabel: {
    fontSize: '14px'
  },
  deleteIcon: {
    color: '#7E7E7E'
  },
  buttonContainer: {
    position: 'absolute',
    bottom: 0,
    padding: '30px 0',
    width: 'calc(100% - 60px)',
    backgroundColor: 'white'
  },
  createButton: {
    padding: '12px 24px',
    textTransform: 'none'
  }
}))

const UserInvitationsList = ({ beneficiary, defaultPagination }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const invitations = useSelector(userInvitationsSelector)
  const count = useSelector(userInvitationsCountSelector)
  const loading = useSelector(userInvitationsLoadingSelector)

  defaultPagination = defaultPagination || {
    ordering: '-created',
    size: 1000
  }

  // #region Clear data on leave
  const clear = () => {
    dispatch({ type: CLEAR_INVITATIONS })
  }

  useEffect(() => clear, [])
  // #endregion

  // #region Load user invitations
  const fetchData = () => {
    if (!loading && beneficiary) {
      dispatch(loadUserInvitations({ ...defaultPagination, userId: beneficiary?.id }))
    }
  }

  useEffect(() => fetchData(), [beneficiary])
  // #endregion

  // #region Actions
  const createInvitation = async (event) => {
    event.preventDefault()
    const userId = beneficiary?.id
    const result = await dispatch(createUserInvitation({ userId }))
    if (result.type === CREATE_INVITATION_SUCCESS) {
      fetchData()
      return NotificationManager.success(<IntlMessages id='pages.profile.invitations.createSuccess' />)
    }

    if (result.error?.response?.status === 400) {
      return NotificationManager.error(<IntlMessages id='pages.profile.invitations.createError' />)
    }
    return NotificationManager.error(<IntlMessages id='appModule.errorTryAgainLater' />)
  }

  const deleteInvitation = async (event, id) => {
    event.preventDefault()
    const result = await dispatch(deleteUserInvitation({ id }))
    if (result.type === DELETE_INVITATION_SUCCESS) {
      fetchData()
      NotificationManager.success(<IntlMessages id='pages.profile.invitations.deleteSuccess' />)
    } else {
      NotificationManager.error(<IntlMessages id='appModule.errorTryAgainLater' />)
    }
  }
  // #endregion

  const columnData = [
    {
      id: 'code',
      align: 'left',
      label: 'Code'
    },
    {
      id: 'redeemed',
      align: 'left',
      label: 'Redeemed',
      handler: row => (
        <StatusDot
          showLabel
          status={row.redeemed}
          statusValues={{ true: 'Yes', false: 'No' }}
          colorClass={getColor(row.redeemed)}
          labelClass={classes.statusLabel}
        />
      )
    },
    {
      id: 'created',
      align: 'left',
      label: 'Created',
      handler: row => moment(row.created).format(DATE_FORMAT)
    },
    {
      id: 'modified',
      align: 'left',
      label: 'Last updated',
      handler: row => moment(row.modified).format(DATE_TIME_FORMAT)
    },
    {
      id: 'valid_until',
      align: 'left',
      label: 'Valid until',
      handler: (row) => row.redeemed ? '-' : moment(row.valid_until).format(DATE_TIME_FORMAT)
    },
    {
      id: 'is_valid',
      align: 'left',
      label: 'Valid',
      handler: row => (
        <StatusDot
          showLabel
          status={row.is_valid && !row.redeemed}
          statusValues={{ true: 'Yes', false: 'No' }}
          colorClass={getColor(row.is_valid && !row.redeemed)}
          labelClass={classes.statusLabel}
        />
      )
    }
  ]

  const actions = [
    {
      id: 'delete',
      label: '',
      handler: row => (
        <IconButton
          title='Delete'
          onClick={event => deleteInvitation(event, row.id)}
        >
          <CloseOutlined fontSize='small' className={classes.deleteIcon} />
        </IconButton>
      )
    }
  ]

  return (
    <Box>
      {!loading && beneficiary && (
        <DataTableNew
          id='invitationsList'
          columnData={columnData}
          tableData={invitations}
          actions={actions}
          initialPagination={defaultPagination}
          noSearchResultsText={<IntlMessages id='pages.profile.invitations.noInvitations' />}
          count={count}
          cellClass={classes.cell}
          showOnlyTable
          hideFooter
        />
      )}
      <Box className={classes.buttonContainer}>
        <Button
          variant='outlined'
          color='primary'
          endIcon={<AddOutlined />}
          onClick={createInvitation}
          className={classes.createButton}
          disabled={!beneficiary?.email || beneficiary?.email === ''}
          fullWidth
          disableElevation
          disableRipple
        >
          <IntlMessages id='pages.profile.invitations.create' />
        </Button>
      </Box>
    </Box>
  )
}

export { UserInvitationsList }
