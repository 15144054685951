import { axiosGetParamsArraySerializer } from '../util'
import {
  CREATE_USER,
  LOAD_MY_USER_DATA,
  LOAD_USER_DETAIL,
  LOAD_USERS_DATA,
  RESET_PASSWORD,
  SET_MY_USER_DATA,
  SET_USER_OPT_OUT,
  UPDATE_USER,
  USERS_FILTERS_LIST
} from '../constants/ActionTypes'

export const loadMyUserData = () => ({
  type: LOAD_MY_USER_DATA,
  payload: {
    request: {
      url: 'unjspfadmin/v2/manager/user/me',
      method: 'GET'
    }
  }
})

export const loadUserData = ({ id }) => ({
  type: LOAD_USER_DETAIL,
  payload: {
    request: {
      url: `unjspfadmin/manager/user/${id}`,
      method: 'GET'
    }
  }
})

export const setMyUserData = ({ data }) => {
  return {
    type: SET_MY_USER_DATA,
    payload: {
      request: {
        url: 'unjspfadmin/v2/manager/user/me',
        method: 'PATCH',
        data: {
          ...data
        }
      }
    }
  }
}

export const setMyTimeZone = (timezone) => {
  const data = { profile: { timezone } }
  return {
    type: SET_MY_USER_DATA,
    payload: {
      request: {
        url: 'unjspfadmin/v2/manager/user/me',
        method: 'PATCH',
        data: {
          ...data
        }
      }
    }
  }
}

export const createUser = ({ data }) => ({
  type: CREATE_USER,
  payload: {
    request: {
      url: 'unjspfadmin/manager/user',
      method: 'POST',
      data: {
        ...data
      }
    }
  }
})

export const updateUser = ({ id, data }) => ({
  type: UPDATE_USER,
  payload: {
    request: {
      url: `unjspfadmin/manager/user/${id}`,
      method: 'PATCH',
      data: {
        ...data
      }
    }
  }
})

export const setOptOutForUser = ({ id, data }) => ({
  type: SET_USER_OPT_OUT,
  payload: {
    request: {
      url: `unjspfadmin/manager/user_opt_out/${id}`,
      method: 'PATCH',
      data: {
        ...data
      }
    }
  }
})

export const resetPassword = ({ newPassword }) => ({
  type: RESET_PASSWORD,
  payload: {
    request: {
      url: 'auth/reset_password',
      method: 'POST',
      data: {
        new_password: newPassword
      }
    }
  }
})

// eslint-disable-next-line camelcase
export const loadUsersData = ({ page, size, count, ordering, search, organization_id, groups__name__in }) => ({
  type: LOAD_USERS_DATA,
  payload: {
    request: {
      url: 'unjspfadmin/manager/user',
      method: 'GET',
      params: {
        page,
        size,
        count,
        ordering,
        search,
        organization_id,
        groups__name__in
      },
      paramsSerializer: axiosGetParamsArraySerializer
    }
  }
})

export const setFiltersList = (filtersList) => {
  return {
    type: USERS_FILTERS_LIST,
    payload: filtersList
  }
}
