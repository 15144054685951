import { Box } from '@material-ui/core'
import { HelpOutlineOutlined, LockOutlined } from '@material-ui/icons'
import { MenuItem } from 'components/generic/MenuItem'
import { FAQ_URL } from 'constants/Routes'
import React from 'react'

const FOOTER_ITEMS = [
  { id: 'faq', route: FAQ_URL, icon: <HelpOutlineOutlined />, title: 'appModule.FAQ', openBlank: true },
  { id: 'privacyPolicy', absoluteRoute: '/doc/privacy', icon: <LockOutlined />, title: 'appModule.privacy' }
]

const Footer = () => {
  return (
    <footer className='app-footer'>
      <ul className='nav-menu'>
        {FOOTER_ITEMS.map(menuItem => <MenuItem key={menuItem.id} menuItem={menuItem} />)}
      </ul>
      <Box className='separator' />
      <div className='mb-2'>{`Copyright UNJSPF ${(new Date()).getFullYear()}`}</div>
      <div>{`v${process.env.REACT_APP_VERSION}`}</div>
    </footer>
  )
}

export default Footer
