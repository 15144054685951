import React, { useState } from 'react'
import { KeyboardTimePicker } from '@material-ui/pickers'
import { Box, makeStyles, Typography } from '@material-ui/core'
import { Schedule } from '@material-ui/icons'
import moment from 'moment'
import { debounce } from 'util/debounce'
import IntlMessages from 'util/IntlMessages'

const ALLOWED_MINUTES = [0, 15, 30, 45]

const useStyles = makeStyles(theme => ({
  pickerRoot: {
    border: '1px solid rgba(126, 126, 126, 0.5)',
    borderRadius: '8px',
    padding: '10px 0',
    cursor: 'pointer'
  },
  keyboardButton: {
    color: theme.palette.primary.main
  },
  InputAdornment: {
    marginRight: '5px'
  },
  inputBase: {
    padding: '7px 0'
  }
}))

const TimePicker = ({ id, label, value, onChange }) => {
  const classes = useStyles()

  const onTimeChange = debounce(time => {
    const minutes = moment(time).minutes()
    let closestMinute = 0

    if (minutes >= 0) {
      closestMinute = ALLOWED_MINUTES.reduce((prev, curr) => {
        return Math.abs(curr - minutes) < Math.abs(prev - minutes)
          ? curr
          : prev
      })

      time.minutes(closestMinute)
    }

    onChange(time)
  }, 250)

  return (
    <Box>
      {!!label && (
        <Typography className={classes.label}>
          <IntlMessages id={label} />
        </Typography>
      )}
      <KeyboardTimePicker
        id={id}
        variant='inline'
        margin='dense'
        value={value}
        placeholder='Select'
        minutesStep={15}
        KeyboardButtonProps={{ className: classes.keyboardButton }}
        keyboardIcon={<Schedule />}
        InputProps={{
          disableUnderline: true,
          classes: { input: classes.inputBase }
        }}
        InputAdornmentProps={{
          position: 'start',
          className: classes.InputAdornment
        }}
        classes={{ root: classes.pickerRoot }}
        onChange={onTimeChange}
      />
    </Box>
  )
}

export { TimePicker }
