import React from 'react'
import { create } from 'jss'
import rtl from 'jss-rtl'
import { jssPreset, StylesProvider } from '@material-ui/styles'
import { CHILDREN_PROP_TYPE } from '../propTypes'

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] })

function RTL (props) {
  return (
    <StylesProvider jss={jss}>
      {props.children}
    </StylesProvider>
  )
}

RTL.propTypes = {
  children: CHILDREN_PROP_TYPE
}

export default RTL
