import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  beneficiariesCountSelector,
  beneficiariesFiltersListSelector,
  beneficiariesLoadingSelector,
  beneficiariesSelector,
  loadingOrganizationsSelector,
  loadingReportedCountriesSelector,
  organizationsSelector,
  reportedCountriesSelector
} from '../../../reducers/selectors'
import { loadBeneficiaries, setFiltersList } from '../../../actions/Beneficiaries'
import IntlMessages from '../../../util/IntlMessages'
import { useHistory } from 'react-router-dom'
import { absolutePath, ROUTES } from '../../../constants/Routes'
import NotAvailable from '../../generic/NotAvailable'
import { ROLES } from '../../../constants/User'
import Widget from '../../generic/Widget'
import { CLEAR_BENEFICIARIES } from '../../../constants/ActionTypes'
import { loadOrganizations, loadReportedCountries } from '../../../actions/Common'
import Status from '../../generic/Status'
import {
  ENROLMENT_STATUS,
  ENROLMENT_STATUS_DISPLAY,
  ENROLMENT_WORKFLOW_STATUS,
  ENROLMENT_WORKFLOW_STATUS_DISPLAY
} from '../../../constants/common'
import getColor from '../../Enrolment/EnrolmentStatus/getEnrolmentStatusColor'
import { getFullName } from 'util/user'
import moment from 'moment'
import solutionYears from '../../../util/solutionYears'
import FiltersHeader from 'components/generic/FiltersHeader'
import DataTableNew from 'components/generic/DataTableNew'
import FiltersSidebar from 'components/generic/FilterSidebar'
import { INIT_STATE } from 'reducers/Beneficiaries'
import EnrolmentSupportStatusDot from 'components/Enrolment/EnrolmentSupportStatus/EnrolmentSupportStatusDot'
import EnrolmentSupportStatus from 'components/Enrolment/EnrolmentSupportStatus'

const BeneficiariesList = ({ defaultPagination }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const beneficiaries = useSelector(beneficiariesSelector)
  const beneficiariesFiltersList = useSelector(beneficiariesFiltersListSelector)
  const organizations = useSelector(organizationsSelector)
  const reportedCountries = useSelector(reportedCountriesSelector)
  const loadingReportedCountries = useSelector(loadingReportedCountriesSelector)
  const count = useSelector(beneficiariesCountSelector)
  const loading = useSelector(beneficiariesLoadingSelector)
  const loadingOrganizations = useSelector(loadingOrganizationsSelector)
  const [toggleDrawer, setToggleDrawer] = useState(false)
  const [clearFilters, setClearFilters] = useState(false)

  defaultPagination = defaultPagination || {
    ordering: '-created',
    page: 1,
    size: 10
  }

  const getBeneficiaryDetailPath = (row) => {
    return `${ROUTES.BENEFICIARY}/${row.id}`
  }

  const openBeneficiary = (row) => {
    const beneficiaryPath = getBeneficiaryDetailPath(row)
    history.push(absolutePath(beneficiaryPath))
  }

  // #region Clear data on leave
  const clear = () => {
    dispatch({ type: CLEAR_BENEFICIARIES })
  }

  useEffect(() => clear, [])
  // #endregion

  const useMountEffect = (fun) => useEffect(fun, [])

  const loadCommonData = () => {
    if (!organizations && !loadingOrganizations) {
      dispatch(loadOrganizations())
    }
    if (!reportedCountries && !loadingReportedCountries) {
      dispatch(loadReportedCountries())
    }
  }

  useMountEffect(loadCommonData)

  // #region Load beneficiaries
  const fetchData = () => {
    if (beneficiariesFiltersList) {
      dispatch(
        loadBeneficiaries({
          ...defaultPagination,
          ...beneficiariesFiltersList,
          groups__name: ROLES.BENEFICIARY
        })
      )
    }
  }

  useEffect(() => fetchData(), [beneficiariesFiltersList])
  // #endregion

  const columnData = [
    {
      id: 'name',
      align: 'left',
      label: 'Beneficiary',
      sortable: true,
      sort_field: 'first_name',
      handler: (row) => {
        const fullName = getFullName(row)
        return (
          <div className='user-profile d-flex flex-row align-items-center'>
            <div className='user-detail'>
              <h5 className='user-name'>{fullName}</h5>
              <p className='user-description'>{row.username} </p>
            </div>
          </div>
        )
      }
    },
    {
      id: 'requested',
      align: 'left',
      label: 'Latest enrolment',
      sortable: true,
      handler: (row) => row.requested ? moment(row.requested).format('MMM D YYYY, h:mm:ss a') : (
        <NotAvailable />)
    },
    {
      id: 'unjspf_uuid',
      align: 'left',
      label: 'UNJSPF ID',
      sortable: true,
      handler: (row) => {
        const UID = row.unjspf_uuid || (<NotAvailable />)
        return (
          <div className='user-profile d-flex flex-row align-items-center'>
            <div className='user-detail'>
              <p className='user-name'>{UID}</p>
            </div>
          </div>
        )
      }
    },
    {
      id: 'on_hold',
      align: 'left',
      label: 'On hold',
      sortable: false,
      handler: (row) => {
        const onHold = Boolean(row.on_hold)
        return (
          <div>
            <Status
              status={onHold} statusValues={{ true: 'Yes', false: 'No' }}
              getColor={(status) => status ? 'danger' : 'light'}
            />
          </div>
        )
      }
    },
    {
      id: 'can_enrol',
      align: 'left',
      label: 'Allowed to enrol',
      sortable: false,
      handler: (row) => {
        const allowedToEnrol = Boolean(row.allowed_to_enroll)
        return (
          <div className='can-enrol'>
            <Status
              status={allowedToEnrol} statusValues={{ true: 'Yes', false: 'No' }}
              getColor={(status) => status ? 'success' : 'danger'}
            />
          </div>
        )
      }
    },
    {
      id: 'can_submit_DCE',
      align: 'left',
      label: 'Enabled for DCE',
      sortable: false,
      handler: (row) => {
        const canSubmitDCE = Boolean(row.dce)
        return (
          <div className='can-submit-dce'>
            <Status
              status={canSubmitDCE} statusValues={{ true: 'Yes', false: 'No' }}
              getColor={(status) => status ? 'success' : 'danger'}
            />
          </div>
        )
      }
    },
    {
      id: 'opted_out',
      align: 'left',
      label: 'Opted out',
      sortable: false,
      handler: (row) => {
        const optedOut = Boolean(row.opt_out)
        return (
          <div className='opted-out'>
            <Status
              status={optedOut} statusValues={{ true: 'Yes', false: 'No' }}
              getColor={(status) => status ? 'danger' : 'light'}
            />
          </div>
        )
      }
    },
    {
      id: 'comm_exhausted',
      align: 'left',
      label: 'Comm. exhausted',
      sortable: false,
      handler: (row) => {
        const optedOut = Boolean(row.communication_exhausted)
        return (
          <div className='communication-exhausted'>
            <Status
              status={optedOut} statusValues={{ true: 'Yes', false: 'No' }}
              getColor={(status) => status ? 'danger' : 'light'}
            />
          </div>
        )
      }
    },
    {
      id: 'invitation_redeemed',
      align: 'left',
      label: 'Invitation redeemed',
      sortable: false,
      handler: (row) => {
        return (
          <div className='enrolment-status'>
            <Status
              status={row.invitation_redeemed} statusValues={{ true: 'Yes', false: 'No' }}
              getColor={(status) => status ? 'success' : 'danger'}
            />
          </div>
        )
      }
    },
    {
      id: 'on_boarding',
      align: 'left',
      label: 'Enrolment status',
      sortable: false,
      handler: (row) => {
        return (
          <div className='enrolment-status'>
            {row.workflow_status ? (
              <EnrolmentSupportStatus
                status={row.workflow_status}
              />
            ) : (
              <Status
                status={ENROLMENT_STATUS.WITHOUT}
                statusValues={ENROLMENT_STATUS_DISPLAY}
                getColor={getColor}
              />
            )}
          </div>
        )
      }
    },
    {
      id: 'valid_coes',
      align: 'left',
      label: 'Valid DCE',
      sortable: false,
      handler: (row) => {
        const coeValid = row.valid_coe
        return (
          <div className='enrolment-status'>
            <Status
              status={coeValid} statusValues={{ true: 'Yes', false: 'No' }}
              getColor={(status) => status ? 'success' : 'danger'}
            />
          </div>
        )
      }
    }
  ]

  const yearDCEFilteringOptions = solutionYears().map((year) => [
    {
      label: 'Issued',
      valid: true,
      year
    },
    {
      label: 'Did not issue',
      valid: false,
      year
    }
  ])
    .flat()
    .map((item) => ({
      title: `${item.label.toString()} on ${item.year}`,
      label: `${item.label.toString()} on ${item.year}`,
      value: {
        valid: item.valid,
        year: item.year
      },
      groupBy: item.year
    }))

  const filters = [
    {
      id: 'organization_id',
      title: 'Organization',
      isAutocomplete: true,
      options: organizations?.map((organization) => {
        return {
          title: `${organization.acronym} - ${organization.name}`,
          value: organization.id,
          label: organization.acronym
        }
      })
    },
    {
      id: 'reported_country',
      title: 'Reported Country',
      isAutocomplete: true,
      options: reportedCountries?.map((country) => {
        const countryName = country.toLowerCase().replace(/(\b[a-z](?!\s))/g, x => x.toUpperCase())
        return {
          title: countryName,
          value: country,
          label: countryName
        }
      })
    },
    {
      id: 'workflow_status',
      title: 'Enrolment status',
      isCheckboxArray: true,
      options: Object.values(ENROLMENT_WORKFLOW_STATUS).map(
        status => ({
          title: ENROLMENT_WORKFLOW_STATUS_DISPLAY[status],
          rendered: <EnrolmentSupportStatusDot status={status} />,
          value: status
        })
      ),
      mutuallyExclusive: ['no_enrolments_requested']
    },
    {
      id: 'no_enrolments_requested',
      title: 'No enrolments requested',
      isSwitch: true,
      mutuallyExclusive: ['workflow_status']
    },
    {
      id: 'on_hold',
      title: 'On hold',
      isRadioButton: true
    },
    {
      id: 'has_model',
      title: 'Biometric model',
      isRadioButton: true
    },
    {
      id: 'allowed_to_enroll',
      title: 'Allowed to enrol',
      isRadioButton: true
    },
    {
      id: 'can_submit_DCE',
      title: 'Enabled for DCE',
      isRadioButton: true
    },
    {
      id: 'opt_out',
      title: 'Opted out',
      isRadioButton: true
    },
    {
      id: 'communication_exhausted',
      title: 'Comm. exhausted',
      isRadioButton: true
    },
    {
      id: 'invitation_redeemed',
      title: 'Invitation redeemed',
      isRadioButton: true
    },
    {
      id: 'has_appointment',
      title: 'Has appointment',
      isRadioButton: true
    },
    {
      id: 'valid_coe_year',
      title: 'Valid DCE on exercise',
      valueIsObject: true,
      isRadioButton: true,
      options: [...yearDCEFilteringOptions]
    }
  ]

  const onSearch = search => {
    dispatch(setFiltersList({ ...beneficiariesFiltersList, page: 1, search: search || undefined }))
  }

  const onClearFilters = value => {
    setClearFilters(value)
    dispatch(setFiltersList(INIT_STATE.beneficiariesFiltersList))
  }

  const onApplyFilters = checkedList => {
    dispatch(setFiltersList({ ...checkedList, page: 1 }))
  }

  return (
    <Widget styleName='jr-card-profile'>
      <FiltersHeader
        onToggleDrawer={setToggleDrawer}
        filters={filters}
        selectedFilters={beneficiariesFiltersList}
        checkedList={beneficiariesFiltersList}
        setFiltersList={setFiltersList}
        searchString={beneficiariesFiltersList?.search ?? ''}
        onSearch={onSearch}
      />
      <FiltersSidebar
        toggleDrawer={setToggleDrawer}
        opened={toggleDrawer}
        filters={filters}
        initialList={beneficiariesFiltersList}
        filtersTitle='Filters'
        deleteTitle='Clear filters'
        submitTitle='Apply filters'
        activateFilters
        clearFilters={clearFilters}
        onDelete={onClearFilters}
        onSubmit={onApplyFilters}
      />
      <DataTableNew
        id='beneficiaries'
        columnData={columnData}
        tableData={beneficiaries}
        initialPagination={defaultPagination}
        setFiltersList={filters => dispatch(setFiltersList(filters))}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        noSearchResultsText={<IntlMessages id='beneficiaries.noBeneficiaries' />}
        rowClickAction={openBeneficiary}
        selectedFilters={beneficiariesFiltersList}
        search={beneficiariesFiltersList?.search}
        count={count}
        loading={loading}
      />
    </Widget>
  )
}

export default BeneficiariesList
