import {
  CLEAR_USER_DETAIL,
  CLEAR_USERS_DATA, LOAD_USER_DETAIL,
  LOAD_USER_DETAIL_SUCCESS,
  LOAD_USERS_DATA,
  LOAD_USERS_DATA_FAIL,
  LOAD_USERS_DATA_SUCCESS,
  USERS_FILTERS_LIST
} from '../constants/ActionTypes'

 export const INIT_STATE = {
  loading: false,
  detail: null,
  list: null,
  count: 0,
  usersFiltersList: {
    search: undefined,
    organization_id: undefined,
    users_role_name: undefined
  }
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
  case LOAD_USERS_DATA: {
    return {
      ...state,
      loading: true
    }
  }
  case LOAD_USERS_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      list: action.payload?.data?.results,
      count: action.payload?.data?.count
    }
  }
  case LOAD_USERS_DATA_FAIL: {
    return {
      ...state,
      loading: false,
      list: null
    }
  }
  case LOAD_USER_DETAIL: {
    return {
      ...state,
      loading: true
    }
  }
  case LOAD_USER_DETAIL_SUCCESS: {
    return {
      ...state,
      loading: false,
      detail: action.payload?.data
    }
  }
  case CLEAR_USER_DETAIL: {
    return {
      ...state,
      loading: false,
      detail: null
    }
  }
  case CLEAR_USERS_DATA: {
    return {
      ...state,
      list: null,
      count: 0
    }
  }
  case USERS_FILTERS_LIST: {
    return {
      ...state,
      usersFiltersList: action.payload
    }
  }
  default:
    return state
  }
}
