import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  makeStyles
} from '@material-ui/core'
import { recoverBeneficiarySecurityCode } from 'actions/Auth'
import {
  createInstantCall,
  loadBeneficiaryDetail,
  loadEnrolmentDetailV2,
  loadUserApprovedEnrolments,
  callEventRequest
} from 'actions/Beneficiaries'
import { getVideoCallUrl } from 'actions/SupportSchedule'
import {
  CLEAR_APPROVED_ENROLMENTS,
  CLEAR_BENEFICIARY_DETAIL,
  CLEAR_ENROLMENT_DETAIL,
  CLEAR_VIDEO_CALL_URL,
  CREATE_INSTANT_CALL_SUCCESS,
  RECOVER_BENEFICIARY_SECURITY_CODE_FAIL,
  RECOVER_BENEFICIARY_SECURITY_CODE_SUCCESS
} from 'constants/ActionTypes'
import { absolutePath, ROUTES } from 'constants/Routes'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { NotificationManager } from 'react-notifications'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import {
  approvedEnrolmentFromListSelector,
  approvedEnrolmentsSelector,
  beneficiaryDetailSelector,
  beneficiaryProfileLoadingSelector,
  enrolmentDetailSelector,
  videoCallTokenRequestInProgress,
  VideoCallUrlSelector
} from 'reducers/selectors'
import { getErrorMessage } from '../../../util'
import IntlMessages from 'util/IntlMessages'
import ProfileHeader from './ProfileHeader'
import RecoverSecurityCodeDialog from './ProfileHeader/RecoverSecurityCodeDialog'
import ProfileInfo from './ProfileInfo'
import { ProfileActions } from './ProfileActions'
import { ProfileContent } from './ProfileContent'

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  item: {
    height: 'calc(100vh - 123px)',
    overflow: 'hidden'
  },
  cardContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)'
  },
  userHeader: {
  },
  userInfo: {
    height: 'calc(100% - 298px)'
  }
}))

const BeneficiaryDetail = () => {
  // #region Hooks
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const intl = useIntl()
  const { id: beneficiaryId } = useParams()

  const beneficiary = useSelector(beneficiaryDetailSelector)
  const loadingVideoCallToken = useSelector(videoCallTokenRequestInProgress)
  const videoCallUrl = useSelector(VideoCallUrlSelector)
  const approvedEnrolments = useSelector(approvedEnrolmentsSelector)
  const approvedEnrolment = useSelector(approvedEnrolmentFromListSelector)
  const enrolmentDetail = useSelector(enrolmentDetailSelector)
  const loading = useSelector(beneficiaryProfileLoadingSelector)

  const [recoverSecurityCodeDialogOpen, setRecoverSecurityCodeDialogOpen] = useState(false)
  // #endregion

  // #region Load beneficiary
  useEffect(() => {
    if (!beneficiary) {
      dispatch(loadBeneficiaryDetail({ id: beneficiaryId }))
    }
  }, [beneficiary])
  // #endregion

  // #region Load approved enrolments
  useEffect(() => {
    if (!approvedEnrolments && beneficiary) {
      dispatch(loadUserApprovedEnrolments({ userId: beneficiaryId }))
    }
  }, [Boolean(beneficiary)])
  // #endregion

  // #region Load approved enrolment detail: needed to get the profile picture
  useEffect(() => {
    if (!enrolmentDetail && approvedEnrolment) {
      dispatch(loadEnrolmentDetailV2({ id: approvedEnrolment?.id }))
    }
  }, [approvedEnrolment])
  // #endregion

  // #region Clear on leave
  const clear = () => {
    dispatch({ type: CLEAR_BENEFICIARY_DETAIL })
    dispatch({ type: CLEAR_APPROVED_ENROLMENTS })
    dispatch({ type: CLEAR_ENROLMENT_DETAIL })
    dispatch({ type: CLEAR_VIDEO_CALL_URL })
  }

  useEffect(() => clear, [])
  // #endregion

  // #region Load operator jitsi token
  useEffect(() => {
    if (!videoCallUrl) {
      dispatch(getVideoCallUrl(beneficiaryId))
    }
  }, [dispatch, videoCallUrl])
  // #endregion

  // #region Video call
  const call = async () => {
    if (beneficiary && videoCallUrl) {
      dispatch(callEventRequest({ beneficiaryId }))
      const result = await dispatch(createInstantCall({ userId: beneficiaryId }))
      if (result.type === CREATE_INSTANT_CALL_SUCCESS) {
        window.open(videoCallUrl, '_blank')
      } else {
        const errors = result.error?.response?.data
        let message = intl.formatMessage({ id: 'pages.profile.callNow.error' })
        if (errors) {
          message = `${message}: ${getErrorMessage(errors)}`
        }
        NotificationManager.error(message)
      }
    } else {
      const message = intl.formatMessage({ id: 'pages.supportSchedule.appointments.joinError' })
      NotificationManager.error(message)
    }
  }
  // #endregion

  // #region Edit user info
  const openEditBeneficiary = () => {
    history.push(absolutePath(`${ROUTES.BENEFICIARY}/${beneficiaryId}/update`))
  }
  // #endregion

  // #region Recover security code
  const recoverSecurityCode = async () => {
    if (beneficiary) {
      const result = await dispatch(recoverBeneficiarySecurityCode({ unjspfUuid: beneficiary.unjspf_uuid, email: beneficiary.email }))
      if (result.type === RECOVER_BENEFICIARY_SECURITY_CODE_SUCCESS) {
        NotificationManager.success(<IntlMessages id='recoverBeneficiarySecurityCode.requestSuccess' values={{ email: beneficiary.email }} />)
      } else if (result.type === RECOVER_BENEFICIARY_SECURITY_CODE_FAIL) {
        let message = intl.formatMessage({ id: 'recoverBeneficiarySecurityCode.requestFail' })
        message = `${message}: ${intl.formatMessage({ id: 'recoverBeneficiarySecurityCode.requestFail.emailInstructions' })}`
        NotificationManager.error(message)
      }
    }
  }
  // #endregion

  return (
    <div className='flex-row'>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      {beneficiary && approvedEnrolments && (
        <Grid container spacing={3} justifyContent='space-between'>
          <Grid item xs={12} md={5} className={classes.item}>
            {/* Card header */}
            <Box className={classes.cardContainer}>
              <Box className={classes.userHeader}>
                <ProfileHeader
                  {...{
                    beneficiary,
                    call,
                    loadingVideoCallToken
                  }}
                  enrolment={enrolmentDetail}
                />
              </Box>
              <Box className={classes.userInfo}>
                <ProfileInfo {...{ beneficiary, enrolmentDetail }} />
              </Box>
              <ProfileActions
                edit={openEditBeneficiary}
                recoverSecurityCode={() => setRecoverSecurityCodeDialogOpen(true)}
              />
              {/* Recover security code dialog */}
              <RecoverSecurityCodeDialog
                id='recover-beneficiary-security-code-dialog'
                beneficiary={beneficiary}
                recoverSecurityCode={recoverSecurityCode}
                open={recoverSecurityCodeDialogOpen}
                handleClose={() => setRecoverSecurityCodeDialogOpen(false)}
              />
            </Box>
          </Grid>
          <Grid item xs className={classes.item}>
            {/* Card body */}
            <Box className={classes.cardContainer}>
              <ProfileContent {...{ beneficiary }} />
            </Box>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export { BeneficiaryDetail }
