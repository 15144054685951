import React, { useState } from 'react'
import EnrolmentSupportStatusPill from './EnrolmentSupportStatusPill'
import EnrolmentSupportStatusDot from './EnrolmentSupportStatusDot'

const EnrolmentSupportStatus = ({ status }) => {
  const [hover, setHover] = useState(false)

  const toggleHover = (value) => {
    setHover(value)
  }

  return <div
    className='d-flex flex-row justify-content-start'
    onMouseEnter={() => toggleHover(true)}
    onMouseLeave={() => toggleHover(false)}
         >
    {
      hover ? <EnrolmentSupportStatusPill status={status} />
        : <EnrolmentSupportStatusDot status={status} />
    }
  </div>
}

export default EnrolmentSupportStatus
