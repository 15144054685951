import {
  APPROVE_ENROLMENT,
  CREATE_INVITATION,
  DELETE_INVITATION,
  LOAD_APP_INSTALLS,
  LOAD_APPROVED_ENROLMENTS,
  LOAD_BENEFICIARIES,
  LOAD_BENEFICIARY_DETAIL,
  LOAD_CERTIFICATES,
  LOAD_ENROLMENT_DETAIL,
  LOAD_ENROLMENTS,
  LOAD_EXISTENCE_VALIDATIONS,
  LOAD_INVITATIONS,
  REJECT_ENROLMENT,
  REVOKE_APP_INSTALL,
  UPDATE_BENEFICIARY,
  UPDATE_ENROLMENT,
  VALIDATE_DCOE,
  CREATE_INSTANT_CALL,
  BULK_ASSIGN_ENROLMENTS,
  BOOK_APPOINTMENT,
  BENEFICIARIES_FILTERS_LIST,
  CALL_EVENTS_REQUEST,
  LOAD_CALL_EVENTS
} from '../constants/ActionTypes'
import { APP_INSTALL_STATUS, CALL_EVENT_TYPE, CSRF_TOKEN_COOKIE_NAME, ENROLMENT_STATUS } from '../constants/common'
import moment from 'moment'
import csrfHeader from '../util/csrfHeader'
import getCookie from '../util/getCookie'
import { axiosGetParamsArraySerializer } from '../util'

// eslint-disable-next-line camelcase
export const loadBeneficiaries = ({
  page,
  size,
  ordering,
  search,
  groups__name,
  organization_id,
  track,
  valid_coe_year,
  workflow_status,
  no_enrolments_requested,
  has_model,
  has_appointment,
  invitation_redeemed,
  can_submit_DCE,
  opt_out,
  on_hold,
  allowed_to_enroll,
  communication_exhausted,
  reported_country
}) => ({
  type: LOAD_BENEFICIARIES,
  payload: {
    request: {
      url: 'unjspfadmin/manager/user',
      method: 'GET',
      params: {
        page,
        size,
        ordering,
        search,
        groups__name,
        organization_id,
        track,
        valid_coe: valid_coe_year?.valid,
        coe_year: valid_coe_year?.year,
        workflow_status,
        has_model,
        has_appointment,
        invitation_redeemed,
        on_hold,
        allowed_to_enroll,
        dce: can_submit_DCE,
        opt_out,
        communication_exhausted,
        reported_country,
        ...(no_enrolments_requested ? { no_on_boardings_requested: no_enrolments_requested } : {})
      },
      paramsSerializer: axiosGetParamsArraySerializer
    }
  }
})

// eslint-disable-next-line camelcase
export const loadEnrolments = ({
  page,
  size,
  ordering,
  search,
  workflow_status,
  assign_me,
  assigned_operator_id,
  status,
  agent,
  organization_id,
  reported_country,
  year,
  has_model,
  has_appointment,
  on_hold,
  allowed_to_enroll,
  can_submit_DCE,
  opt_out,
  communication_exhausted,
  invitation_redeemed,
  valid_coe_year,
  userId = null
}) => {
  const params = {
    page,
    size,
    ordering,
    search,
    status,
    assign_me,
    assigned_operator_id,
    workflow_status,
    agent,
    organization_id,
    reported_country,
    year,
    has_model,
    has_appointment,
    on_hold,
    allowed_to_enroll,
    dce: can_submit_DCE,
    opt_out,
    communication_exhausted,
    invitation_redeemed,
    valid_coe: valid_coe_year?.valid,
    coe_year: valid_coe_year?.year,
    ...(userId ? { user__id: userId } : {})
  }

  return {
    type: LOAD_ENROLMENTS,
    payload: {
      request: {
        url: 'unjspfadmin/v2/manager/onboarding',
        method: 'GET',
        params,
        paramsSerializer: axiosGetParamsArraySerializer
      }
    }
  }
}

export const loadEnrolmentDetailV2 = ({ id }) => ({
  type: LOAD_ENROLMENT_DETAIL,
  payload: {
    request: {
      url: `unjspfadmin/v2/manager/onboarding/${id}`,
      method: 'GET'
    }
  }
})

export const loadBeneficiaryDetail = ({ id }) => ({
  type: LOAD_BENEFICIARY_DETAIL,
  payload: {
    request: {
      url: `unjspfadmin/manager/user/${id}`,
      method: 'GET'
    }
  }
})

export const manageEnrolment = ({ status, enrolmentId, reasons }) => {
  const data = (status === ENROLMENT_STATUS.REJECTED) ? { status, rejected_reasons: reasons } : { status }
  return {
    type: status === ENROLMENT_STATUS.APPROVED ? APPROVE_ENROLMENT : REJECT_ENROLMENT,
    payload: {
      request: {
        url: `unjspfadmin/v2/manager/onboarding/${enrolmentId}`,
        method: 'PATCH',
        data
      }
    }
  }
}

export const bulkAssignEnrolments = ({ on_boarding_ids, assigned_agent }) => {
  const data = { on_boarding_ids, assigned_agent }
  return {
    type: BULK_ASSIGN_ENROLMENTS,
    payload: {
      request: {
        url: 'unjspfadmin/v2/manager/onboardings_assign_agent',
        method: 'PATCH',
        data
      }
    }
  }
}

export const updateEnrolmentNoteAndPhoneNr = (enrolmentId, data) => {
  return {
    type: UPDATE_ENROLMENT,
    payload: {
      request: {
        url: `unjspfadmin/v2/manager/onboarding/${enrolmentId}`,
        method: 'PATCH',
        data
      }
    }
  }
}

export const bookAppointment = ({ enrolment, selectedSlot, selectedAgent }) => {
  const data = {
      operator_id: selectedAgent.id,
      on_boarding_id: enrolment?.id,
      start_datetime: selectedSlot.start_datetime,
      end_datetime: selectedSlot.end_datetime
  }

  return {
    type: BOOK_APPOINTMENT,
    payload: {
      request: {
        url: 'unjspfadmin/v2/manager/book_appointment',
        method: 'POST',
        data
      }
    }
  }
}

export const updateBeneficiary = ({ beneficiary }) => {
  const beneficiaryUpdate = {
    ...beneficiary,
    ...{
      retirement_date: moment(beneficiary.retirement_date).format('YYYY-MM-DD'),
      organization_id: beneficiary?.organization?.id
    }
  }
  delete beneficiaryUpdate.id
  delete beneficiaryUpdate.organization
  delete beneficiaryUpdate.date_joined

  return {
    type: UPDATE_BENEFICIARY,
    payload: {
      request: {
        url: `unjspfadmin/manager/user/${beneficiary.id}`,
        method: 'PATCH',
        data: { ...beneficiaryUpdate }
      }
    }
  }
}

// Beneficiary actions
export const setFiltersList = (filtersList) => {
  return {
    type: BENEFICIARIES_FILTERS_LIST,
    payload: filtersList
  }
}

export const loadUserApprovedEnrolments = ({ userId }) => ({
  type: LOAD_APPROVED_ENROLMENTS,
  payload: {
    request: {
      url: 'unjspfadmin/v2/manager/onboarding',
      method: 'GET',
      params: {
        ordering: '-created',
        user__id: userId,
        status: ENROLMENT_STATUS.APPROVED
      }
    }
  }
})

export const loadUserExistenceValidations = ({ userId }) => ({
  type: LOAD_EXISTENCE_VALIDATIONS,
  payload: {
    request: {
      url: 'unjspfadmin/manager/liveness_confirmation',
      method: 'GET',
      params: {
        ordering: '-created',
        user__id: userId
      }
    }
  }
})

export const loadUserCertificatesOfEntitlement = ({ userId, page = 1, size = 5 }) => ({
  type: LOAD_CERTIFICATES,
  payload: {
    request: {
      url: 'unjspfadmin/manager/certificate_of_entitlement',
      method: 'GET',
      params: {
        ordering: '-expiration_date',
        page,
        size,
        user__id: userId
      }
    }
  }
})

export const loadUserInvitations = ({ userId, page, size, ordering, search }) => ({
  type: LOAD_INVITATIONS,
  payload: {
    request: {
      url: 'unjspfadmin/manager/invitation',
      method: 'GET',
      params: {
        user__id: userId,
        page,
        size,
        ordering,
        search
      }
    }
  }
})

export const createUserInvitation = ({ userId }) => ({
  type: CREATE_INVITATION,
  payload: {
    request: {
      url: 'unjspfadmin/manager/invitation',
      method: 'POST',
      data: {
        user_id: userId
      }
    }
  }
})

export const createInstantCall = ({ userId }) => ({
  type: CREATE_INSTANT_CALL,
  payload: {
    request: {
      url: 'unjspfadmin/manager/calls',
      method: 'POST',
      data: {
        user_id: userId
      }
    }
  }
})

export const callEventRequest = ({ beneficiaryId }) => ({
  type: CALL_EVENTS_REQUEST,
  payload: {
    request: {
      url: 'call_events/manager/events',
      method: 'POST',
      data: {
        event_type: CALL_EVENT_TYPE.REQUEST,
        beneficiary_id: beneficiaryId
      }
    }
  }
})

export const loadCallEvents = ({ beneficiaryId }) => ({
  type: LOAD_CALL_EVENTS,
  payload: {
    request: {
      url: 'call_events/manager/events',
      method: 'GET',
      params: {
        beneficiary_id: beneficiaryId
      }
    }
  }
})

export const deleteUserInvitation = ({ id }) => ({
  type: DELETE_INVITATION,
  payload: {
    request: {
      url: `unjspfadmin/manager/invitation/${id}`,
      method: 'DELETE'
    }
  }
})

export const loadUserAppInstalls = ({ userId, page, size, ordering }) => ({
  type: LOAD_APP_INSTALLS,
  payload: {
    request: {
      url: 'unjspfadmin/manager/app_installation',
      method: 'GET',
      params: {
        user__id: userId,
        page,
        size,
        ordering
      }
    }
  }
})

export const revokeAppInstall = ({ id }) => ({
  type: REVOKE_APP_INSTALL,
  payload: {
    request: {
      url: `unjspfadmin/manager/app_installation/${id}`,
      method: 'PATCH',
      data: {
        status: APP_INSTALL_STATUS.REVOKED
      }
    }
  }
})

export const validateDCoE = ({ userId }) => {
  const csrf = csrfHeader(getCookie(CSRF_TOKEN_COOKIE_NAME))

  const headers = {
    ...csrf
  }

  return {
    type: VALIDATE_DCOE,
    payload: {
      request: {
        url: `unjspfadmin/manager/coe_validation/${userId}`,
        method: 'GET',
        headers
      }
    }
  }
}
