import React from 'react'
import IntlMessages from './IntlMessages'

export function capitalizeFirst (string) {
  return string && (string.charAt(0).toUpperCase() + string.slice(1))
}

export const getErrorMessage = (data, showPropsNames = false) => {
  if (data) {
    if (typeof (data) !== 'object') {
      return data
    } else {
      return showPropsNames
        ? Object.keys(data).map((key, index) => {
            return `${index ? '\n' : ''}${key}: ${data[key]}`
          })
        : Object.values(data).map(errors => {
            return Array.isArray(errors) ? errors.join(', ') : errors
          })
    }
  } else {
    return ''
  }
}

export const getGenderDisplay = (string) => {
  let result = null
  if (string) {
    const localeString = `pages.profile.gender.${string}`
    result = (<IntlMessages id={localeString} />)
  }
  return result
}

export const getParticipationStatusDisplay = (string) => {
  let result = null
  if (string) {
    const localeString = `pages.profile.participation.${string}`
    result = (<IntlMessages id={localeString} />)
  }
  return result
}

const createParamsString = ({ key, values }) => values.map((value) => `${key}=${value}`).join('&')

export const axiosGetParamsArraySerializer = (params) => {
  const paramsArray = Object.entries(params)
  const response = paramsArray.map(([key, value]) => {
    if (Array.isArray(value)) {
      return createParamsString({ key, values: value })
    }
    if (value !== undefined && value !== null) { return `${key}=${value}` }
  }).filter(value => value).join('&')
  return response
}

export const ISO_WEEK_DAY_SATURDAY = 6
export const ISO_WEEK_DAY_SUNDAY = 7
