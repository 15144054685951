import { Box, Button, makeStyles } from '@material-ui/core'
import { CreateOutlined, LockOutlined } from '@material-ui/icons'
import React from 'react'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    position: 'absolute',
    bottom: 0,
    backgroundColor: 'white',
    width: '100%',
    padding: '30px'
  },
  button: {
    padding: '12px 24px',
    textTransform: 'none',
    '&:not(:first-child)': {
      marginLeft: '10px'
    }
  }
})

const ProfileActions = ({ edit, recoverSecurityCode }) => {
  const classes = useStyles()

  const actions = [
    { name: 'edit', label: 'Edit user info', icon: <CreateOutlined />, onClick: edit },
    { name: 'resetPinCode', label: 'Reset PIN Code', icon: <LockOutlined />, onClick: recoverSecurityCode }
  ]

  return (
    <Box className={classes.container}>
      {actions.map(action => (
        <Button
          key={action.name}
          variant='outlined'
          color='primary'
          endIcon={action.icon}
          onClick={() => action.onClick()}
          className={classes.button}
          fullWidth
          disableElevation
          disableRipple
        >
          {action.label}
        </Button>
      ))}
    </Box>
  )
}

export { ProfileActions }
