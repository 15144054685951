import { Link } from '@material-ui/core'
import React from 'react'
import IntlMessages from 'util/IntlMessages'

const LinkButton = ({ label, click, ...props }) => {
  return (
    <Link href='#' onClick={click} {...props}>
      <IntlMessages id={label} />
    </Link>
  )
}

export { LinkButton }
