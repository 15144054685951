import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import React, { cloneElement } from 'react'
import IntlMessages from 'util/IntlMessages'

const useStyles = makeStyles(theme => ({
  container: {
    padding: '30px 5px'
  },
  gridContainer: {
    '&:not(:last-child)': {
      marginBottom: '10px'
    }
  },
  gridItem: {
    display: 'flex'
  },
  icon: {
    marginRight: '12px'
  },
  label: {
    color: theme.palette.text.secondary
  }
}))

const UserInfoList = ({ userData }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      {userData.map(field => {
        const keyField = field.name ?? field.id
        const icon = field.icon
        const label = field.label ?? field.title
        const value = field.value ?? field.description
        const component = field.component

        return (
          <Grid container spacing={2} key={keyField} className={classes.gridContainer}>
            <Grid item xs={12} sm={5} md={12} lg={5} className={classes.gridItem}>
              {cloneElement(icon, {
                color: 'primary',
                className: classes.icon
              })}
              <Typography className={classes.label}>
                <IntlMessages id={label} />
              </Typography>
            </Grid>
            <Grid item xs>
              {component || <Typography>{value}</Typography>}
            </Grid>
          </Grid>
        )
      })}
    </Box>
  )
}

export { UserInfoList }
