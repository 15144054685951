import React, { useState } from 'react'
import { Grid, IconButton, InputAdornment, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Close, Search } from '@material-ui/icons'
import IntlMessages from 'util/IntlMessages'
import cl from 'classnames'
import { debounce } from 'util/debounce'

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%'
  },
  inputLabel: {
    '&:not(.MuiInputLabel-shrink)': {
      transform: 'translate(0, 16px) scale(1)'
    }
  },
  inputFormControl: {
    marginTop: '8px !important'
  }
}))

const SearchBox = ({
  id,
  label,
  value,
  search,
  autoCompleteProps,
  delay,
  ...props
}) => {
  const classes = useStyles()
  const [internalValue, setInternalValue] = useState(value)

  const getAutocompleteProps = () => {
    if (autoCompleteProps) {
      const { InputProps, InputLabelProps } = autoCompleteProps
      return {
        ...autoCompleteProps,
        InputProps: {
          ...InputProps,
          endAdornment: getEndAdornment(),
          className: cl(InputProps.className, classes.inputFormControl)
        },
        InputLabelProps: {
          ...InputLabelProps,
          className: cl(InputLabelProps.className, classes.inputLabel)
        }
      }
    }

    return {}
  }

  const getEndAdornment = () => {
    return (
      <InputAdornment position='end'>
        {value ? (
          <IconButton aria-label='clear' onClick={clearValue}>
            <Close />
          </IconButton>
        ) : (
          <></>
        )}
      </InputAdornment>
    )
  }

  const clearValue = () => {
    setInternalValue('')
    search && search('')
  }

  const handleChange = event => {
    const newValue = event.target.value
    setInternalValue(newValue)
    callSearch(newValue)
  }

  const callSearch = debounce(value => {
    !!search && search(value)
  }, delay || 0)

  return (
    <Grid
      container
      spacing={1}
      alignItems='flex-end'
    >
      <Grid item>
        <Search />
      </Grid>
      <Grid item xs>
        <TextField
          id={id ?? 'search'}
          label={<IntlMessages id={label ?? 'pages.schedule.search'} />}
          value={internalValue}
          className={classes.input}
          onChange={handleChange}
          InputProps={{
            endAdornment: getEndAdornment(),
            classes: { formControl: classes.inputFormControl }
          }}
          InputLabelProps={{ className: classes.inputLabel }}
          {...getAutocompleteProps()}
          {...props}
        />
      </Grid>
    </Grid>
  )
}

export { SearchBox }
