import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER_SUCCESS,
  RECOVER_PASSWORD_FAIL,
  SET_PASSWORD_FAIL,
  SIGNIN_USER,
  SIGNIN_USER_FAIL,
  SIGNOUT_USER,
  TOKEN_REFRESH,
  TOKEN_REFRESH_SUCCESS,
  TOKEN_REFRESH_FAIL
} from '../constants/ActionTypes'

const INIT_STATE = {
  loader: false,
  alertMessage: '',
  successMessage: '',
  showMessage: false,
  initURL: '',
  userToken: null,
  userName: null
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
  case SIGNIN_USER: {
    return {
      ...state,
      loader: false,
      userName: action.payload?.request?.data?.username
    }
  }
  case TOKEN_REFRESH_SUCCESS:
  case SIGNIN_USER_SUCCESS: {
    return {
      ...state,
      loader: false,
      userToken: action.payload.data?.access,
      refreshToken: action.payload.data?.refresh
    }
  }
  case TOKEN_REFRESH: {
    return {
      ...state,
      loader: true
    }
  }
  case TOKEN_REFRESH_FAIL: {
    return {
      ...state,
      loader: false,
      userToken: null,
      refreshToken: null,
      alertMessage: 'signIn.sessionExpired'
    }
  }
  case SIGNIN_USER_FAIL: {
    return {
      ...state,
      loader: false,
      errors: action.error?.response?.data
    }
  }
  case RECOVER_PASSWORD_FAIL: {
    return {
      ...state,
      loader: false,
      errors: action.error?.response?.data
    }
  }
  case SET_PASSWORD_FAIL: {
    return {
      ...state,
      loader: false,
      errors: action.error?.response?.data
    }
  }
  case INIT_URL: {
    return {
      ...state,
      initURL: action.payload
    }
  }
  case SIGNOUT_USER: {
    return {
      ...state,
      ...INIT_STATE
    }
  }
  case SHOW_MESSAGE: {
    return {
      ...state,
      alertMessage: action.payload,
      showMessage: true,
      loader: false
    }
  }
  case HIDE_MESSAGE: {
    return {
      ...state,
      alertMessage: '',
      showMessage: false,
      loader: false
    }
  }
  case ON_SHOW_LOADER: {
    return {
      ...state,
      loader: true
    }
  }
  case ON_HIDE_LOADER: {
    return {
      ...state,
      loader: false
    }
  }
  default:
    return state
  }
}
