import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Settings from './Settings'
import Enrolment from './Enrolment'
import Auth from './Auth'
import User from './User'
import { persistReducer } from 'redux-persist'
import * as localforage from 'localforage'
import Beneficiaries from './Beneficiaries'
import Common from './Common'
import Users from './Users'
import SupportSchedule from './SupportSchedule'

const userPersistConfig = {
  key: 'user',
  storage: localforage,
  whitelist: ['data']
}

const authPersistConfig = {
  key: 'auth',
  storage: localforage,
  whitelist: ['userName', 'userToken', 'refreshToken']
}

export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  user: persistReducer(userPersistConfig, User),
  auth: persistReducer(authPersistConfig, Auth),
  users: Users,
  beneficiaries: Beneficiaries,
  supportSchedule: SupportSchedule,
  common: Common,
  enrolment: Enrolment
})
