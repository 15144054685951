import { ENROLMENT_FILTERS_LIST, ENROLMENT_TOGGLE_ASSIGNED, ENROLMENT_TOGGLE_ATTENTION } from '../constants/ActionTypes'

export const INIT_STATE = {
  enrolmentFiltersList: { workflow_status: undefined, year: undefined, has_appointment: undefined },
  enrolmentToggleAttention: { workflow_status: undefined },
  enrolmentToggleAssigned: { assign_me: undefined }
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
  case ENROLMENT_FILTERS_LIST: {
    return {
      ...state,
      enrolmentFiltersList: action.payload
    }
  }
  case ENROLMENT_TOGGLE_ATTENTION: {
    return {
      ...state,
      enrolmentToggleAttention: action.payload
    }
  }
  case ENROLMENT_TOGGLE_ASSIGNED: {
    return {
      ...state,
      enrolmentToggleAssigned: action.payload
    }
  }
  default:
    return state
  }
}
