import { Box } from '@material-ui/core'
import BeneficiariesList from 'components/Beneficiary/BeneficiariesList'
import { BeneficiaryDetail } from 'components/Beneficiary/BeneficiaryDetail'
import ContainerHeader from 'components/generic/template/ContainerHeader'
import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import IntlMessages from 'util/IntlMessages'
import asyncComponent from '../../../util/asyncComponent'

const Beneficiary = () => {
  const match = useRouteMatch()
  const urlMatch = window.location.href.split('beneficiary')
  const pagination = {
    ordering: '-created',
    size: 10
  }

  return (
    <Box className='app-wrapper'>
      {urlMatch[1] !== '' ? (
        <ContainerHeader
          match={match}
          title={<IntlMessages id='pages.beneficiaryDetail' />}
        />
      ) : (
        <ContainerHeader
          match={match}
          title={<IntlMessages id='pages.beneficiaries' />}
        />
      )}
      <Switch>
        <Route
          path={`${match.path}/:id/update`}
          component={asyncComponent(() => import('../../../components/Beneficiary/BeneficiaryUpdate'))}
        />
        <Route path={`${match.path}/:id`}>
          <BeneficiaryDetail />
        </Route>
        <Route render={() => <BeneficiariesList defaultPagination={pagination} />} />
      </Switch>
    </Box>
  )
}

Beneficiary.propTypes = {}

export default Beneficiary
