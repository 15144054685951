import { Box, makeStyles, Typography } from '@material-ui/core'
import { Language, ScheduleOutlined } from '@material-ui/icons'
import { loadUsersData } from 'actions/User'
import { Dropdown } from 'components/generic/Dropdown/Dropdown.component'
import { DatePicker } from 'components/generic/Pickers'
import { AgentsList } from 'components/Schedule/AgentsList'
import { SelectionMode } from 'components/Schedule/AgentsList/AgentsList.component'
import { DATE_FORMAT, TIME_FORMAT } from 'constants/common'
import { ROLES } from 'constants/User'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listOperatorsAvailabilityV2 } from '../../../../../actions/SupportSchedule'
import {
  availabilityRequestInProgress,
  availabilitySlotsSelectorV2,
  getUserInfo,
  operatorsListForSelectSelector,
  usersLoadingSelector
} from '../../../../../reducers/selectors'
import IntlMessages from '../../../../../util/IntlMessages'

const useStyles = makeStyles(theme => ({
  timezoneContainer: {
    display: 'flex',
    backgroundColor: '#F2F4F7',
    padding: '0.75rem',
    borderRadius: '4px'
  },
  timezoneTitle: {
    color: '#7E7E7E',
    paddingLeft: '12px'
  },
  timezone: {
    paddingLeft: '6px'
  },
  sectionContainer: {
    marginTop: '30px'
  },
  sectionTitle: {
    fontSize: '1.125rem',
    marginBottom: '10px'
  },
  picker: {
    width: '100%',
    margin: '10px 0'
  }
}))

const EnrolmentAppointment = ({ enrolment, setAppointment }) => {
  // #region Hooks
  const classes = useStyles()
  const dispatch = useDispatch()
  const ref = useRef()

  // Dates
  const todaysDate = new Date()
  const defaultStartTime = moment(todaysDate).valueOf()
  const defaultEndTime = moment(todaysDate).add(1, 'M').valueOf()
  const [startTime, setStartTime] = useState(defaultStartTime)
  const [endTime, setEndTime] = useState(defaultEndTime)

  // Availabilities and operators
  const availabilitySlots = useSelector(availabilitySlotsSelectorV2)
  const loadingAvailabilities = useSelector(availabilityRequestInProgress)
  const operators = useSelector(operatorsListForSelectSelector)
  const loadingOperators = useSelector(usersLoadingSelector)
  const [timeSlots, setTimeSlots] = useState([])
  const [operatorsList, setOperatorsList] = useState(operators)

  // Appointment, user and agent
  const userTimezone = enrolment?.user?.profile?.timezone
  const assignedAgent = enrolment?.assigned_agent
  const { slot } = enrolment?.appointment
  const hasAppointment = !!slot?.id

  const { start_datetime, end_datetime } = slot
  const appointmentSlot = { start_datetime, end_datetime }
  const startDate = moment(start_datetime).tz(userTimezone).format(DATE_FORMAT)
  const startTimeStr = start_datetime ? moment(start_datetime).tz(userTimezone).format(TIME_FORMAT) : ''

  const [selectedDate, setSelectedDate] = useState(start_datetime)
  const [selectedSlot, setSelectedSlot] = useState(hasAppointment ? appointmentSlot : null)
  const [selectedAgent, setSelectedAgent] = useState(getUserInfo(assignedAgent))
  // TO BE REMOVED
  const [isContentLoading, setIsContentLoading] = useState(false)
  // #endregion

  // #region Load availabilities
  const fetchAvailabilities = () => {
    dispatch(
      listOperatorsAvailabilityV2({
        operatorsIds: operators?.map(operator => operator.id),
        startTime,
        endTime
      })
    )
  }

  useEffect(() => {
    fetchAvailabilities()
  }, [operators, startTime, endTime])
  // #endregion

  // #region Load operators
  const fetchOperators = () => {
    if (!operators && !loadingOperators) {
      dispatch(
        loadUsersData({
          groups__name__in: [ROLES.OPERATOR, ROLES.TASK_FORCE].join(','),
          size: 1000
        })
      )
    }
  }

  useEffect(() => {
    fetchOperators()
  }, [])
  // #endregion

  // #region Manage slots and agents
  const setTimeSlotsAndAgents = () => {
    const slots = []
    const operatorIdsBySlots = []

    if (availabilitySlots && selectedDate) {
      const day = moment(selectedDate).format(DATE_FORMAT)

      for (const date in availabilitySlots) {
        // Iterate over all days because there can be slots from one day that
        // due to user timezone are coming from day before or day after
        availabilitySlots[date].forEach(slot => {
          const { start_datetime, end_datetime, operator_id } = slot
          const slotStartTz = moment(start_datetime).tz(userTimezone)
          const slotStartDateStr = moment(slotStartTz).format(DATE_FORMAT)

          if (slotStartDateStr === day) {
            const slotStartTimeStr = moment(slotStartTz).format(TIME_FORMAT)

            // Add slot if it belongs to the selected agent
            // or if there is no selected agent
            if (
              !selectedAgent ||
              (selectedAgent && operator_id?.id === selectedAgent.id)
            ) {
              slots.push({
                label: slotStartTimeStr,
                value: { start_datetime, end_datetime }
              })

              // Add agent if it has this time slot or if no time slot is selected
              if (
                !selectedSlot ||
                selectedSlot.start_datetime === start_datetime
              ) {
                operatorIdsBySlots.push(operator_id?.id)
              }
            }
          }
        })
      }
    }

    // Add appointment slot to slots list and agent to operators list
    if (hasAppointment && assignedAgent && assignedAgent.id === selectedAgent?.id) {
      slots.push({ label: startTimeStr, value: appointmentSlot })
      operatorIdsBySlots.push(assignedAgent?.id)
    }

    // Set time slots
    setTimeSlots([...new Set(slots)].sort((a, b) => a.label.localeCompare(b.label)))

    // Set available operators for this slots list
    if (operators) {
      const operatorsList = [...new Set(operatorIdsBySlots)]
      setOperatorsList(selectedDate ? operators.filter(op => operatorsList.includes(op.id)) : operators)
    }
  }

  useEffect(() => {
    const {
      // availabilitySlots: prevAvailabilitySlots,
      selectedDate: prevSelectedDate,
      // selectedSlot: prevSelectedSlot,
      selectedAgent: prevSelectedAgent
    } = ref?.current ?? {}

    // Update time slots every time selected date or selected agent changes
    setTimeSlotsAndAgents()

    // If agent removed
    if (!!prevSelectedAgent && !selectedAgent) {
      setSelectedDate(null)
      setSelectedSlot(null)
    }
    // If date removed
    if (!!prevSelectedDate && !selectedDate) {
      setSelectedSlot(null)
    }

    // Save current values
    ref.current = { availabilitySlots, selectedDate, selectedSlot, selectedAgent }
  }, [availabilitySlots, selectedDate, selectedSlot, selectedAgent])

  useEffect(() => {
    // Emit values to parent
    setAppointment({
      selectedDate,
      selectedSlot,
      selectedAgent,
      enrolment
    })
  }, [selectedDate, selectedSlot, selectedAgent])
  // #endregion

  // #region Handle datepicker
  const availableDaysByAgent = useMemo(() => {
    // Filter days by selected agent if there is one
    let slots = []
    if (availabilitySlots) {
      slots = Object.keys(availabilitySlots).filter(
        slot =>
          !selectedAgent ||
          (selectedAgent &&
            availabilitySlots[slot].filter(
              s => s.operator_id?.id === selectedAgent.id
            ).length)
      )
    }
    // If start date of appointment has no availabilities
    if (
      hasAppointment &&
      !slots.includes(startDate) &&
      assignedAgent &&
      assignedAgent.id === selectedAgent?.id
    ) {
      slots.push(startDate)
    }
    return slots
  }, [availabilitySlots, selectedAgent])

  const disabledDays = date => {
    return !availableDaysByAgent.includes(date.format(DATE_FORMAT))
  }

  const onMonthChange = date => {
    const startTime = moment(date).valueOf()
    const endTime = moment(date).add(1, 'M').valueOf()
    setStartTime(startTime)
    setEndTime(endTime)
  }

  const renderDatePicker = useMemo(() => {
    return (
      <DatePicker
        value={selectedDate ?? null}
        placeholder='Select a date slot'
        onChange={setSelectedDate}
        onMonthChange={onMonthChange}
        shouldDisableDate={disabledDays}
        loading={loadingAvailabilities}
        className={classes.picker}
        clearButton
        autoOk
      />
    )
  }, [availableDaysByAgent, loadingAvailabilities, selectedDate])
  // #endregion

  return (
    !isContentLoading && (
      <Box className={classes.sectionContainer}>
        {/* Beneficiary timezone */}
        <Box className={classes.timezoneContainer}>
          <Language color='primary' />
          <Typography className={classes.timezoneTitle}>
            <IntlMessages id='pages.enrolmentDetail.editAppointment.beneficiaryTimezone' />
          </Typography>
          <Typography className={classes.timezone}>{userTimezone}</Typography>
        </Box>
        {/* Date and time */}
        <Box className={classes.sectionContainer}>
          <Typography className={classes.sectionTitle}>
            <IntlMessages id='pages.enrolmentDetail.editAppointment.dateTime' />
          </Typography>
          {renderDatePicker}
          <Dropdown
            value={selectedSlot ?? null}
            options={timeSlots}
            placeholder='Select a time slot'
            onChange={setSelectedSlot}
            disabled={!selectedDate}
            loading={loadingAvailabilities}
            leftIcon={<ScheduleOutlined />}
            maxListHeight='300px'
            className={classes.picker}
            hideArrow
            clearButton
          />
        </Box>
        {/* Agents */}
        <Box className={classes.sectionContainer}>
          <Typography className={classes.sectionTitle}>
            <IntlMessages id='pages.enrolmentDetail.editAppointment.agent' />
          </Typography>
          <AgentsList
            agents={operatorsList}
            agentsChanged={setSelectedAgent}
            selectionMode={SelectionMode.Single}
            selectedAgent={selectedAgent}
            selectionRequired
          />
        </Box>
      </Box>
    )
  )
}

EnrolmentAppointment.propTypes = {
  enrolment: PropTypes.object,
  setAppointment: PropTypes.func
}

export default EnrolmentAppointment
