import React from 'react'
import { Box, Button, Typography, makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  item: {
    marginBottom: '50px'
  },
  title: {
    color: '#202020 !important',
    fontWeight: 700,
    lineHeight: '140%',
    marginBottom: '8px'
  },
  reload: {
    textTransform: 'none',
    fontSize: '16px'
  }
}))

const Maintenance = () => {
  const styles = useStyles();
  const history = useHistory();
  return (
    <Box className='bg-white d-flex flex-column w-100 align-items-center justify-content-center text-center'>
      <img src={require('assets/images/unjspf_logo.png')} style={{ maxWidth: '240px' }} className={styles.item} />
      <img src={require('assets/images/maintenance.svg')} className={styles.item} />
      <Box className={styles.item}>
        <Typography variant='h5' className={styles.title}>Site under maintenance</Typography>
        <Typography>We’ll be back soon, please check again later.</Typography>
      </Box>
      <Button
        variant='contained'
        color='primary'
        className={styles.reload}
        onClick={() => history.goBack()}
        disableElevation
      >
        Reload
      </Button>
    </Box>
  )
}

export default Maintenance
